<template>
  <b-container>
    <div id="page-container">
      <div id="page-content">
        <div class="content-card">
          <b-row class="pb-2" align-v="start">
            <b-col>
              <h1>{{ title }}</h1>
            </b-col>
          </b-row>

          <slot></slot>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
