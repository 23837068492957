<template>
  <div v-if="codigo">
    <b-row>
      <b-col>
        <span v-if="trazabilidad.fechaGanada">
          <b-badge variant="success">Ganada el <br />{{ trazabilidad.fechaGanada }}</b-badge>
        </span>
        <span v-if="caducada">
          <b-badge variant="danger" small> Caducada </b-badge>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>Código</h5>
        <span>
          <span class="vam mr-2">{{ codigo }}</span>
          <b-badge variant="info" small> versión {{ version }} </b-badge>
        </span>
      </b-col>
    </b-row>
    <b-row v-if="contrato">
      <b-col>
        <span>
          <b-badge variant="warning" class="vam p-2 mt-2 text-uppercase">Con contrato</b-badge>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    version: null,
    codigo: null,
    trazabilidad: Object,
    caducada: null,
    contrato: null,
  },
};
</script>
