<template>
  <b-row>
    <b-col>
      <b-alert variant="info" show>
        <b>Productividad</b>
        <div class="big-number">{{ ratioProductividad }}%</div>
      </b-alert>
    </b-col>
    <b-col>
      <b-alert variant="info" show>
        <b>Promedio</b>
        <div class="big-number">{{ Math.ceil(diasPromedio) }} días</div>
      </b-alert>
    </b-col>
    <b-col>
      <b-alert variant="info" show>
        <b>Ganadas</b>
        <div class="big-number">{{ ganadas }}</div>
      </b-alert>
    </b-col>
    <b-col>
      <b-alert variant="info" show>
        <b>Perdidas</b>
        <div class="big-number">{{ perdidas }}</div>
      </b-alert>
    </b-col>
    <b-col>
      <b-alert variant="success" show>
        <b>Pendientes</b>
        <div class="big-number">{{ pendientes }}</div>
      </b-alert>
    </b-col>
    <b-col>
      <b-alert variant="info" show>
        <b>Total Ofertas</b>
        <div class="big-number">{{ ganadas + pendientes + perdidas }}</div>
      </b-alert>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    ganadas: Number,
    perdidas: Number,
    pendientes: Number,
    ratioProductividad: Number,
    diasPromedio: Number,
  },
};
</script>
<style>
.big-number {
  display: block;
  font-size: 24px;
  font-weight: bold;
}
</style>
