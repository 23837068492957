<template>
  <page-edicion title="Editar pais">
    <formulario :pais="pais" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/paises.js";

export default {
  components: { Formulario },
  data() {
    return {
      pais: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.pais = response.pais;
      });
    },
    actualizar() {
      api.actualizar(this.pais).then(() => {
        this.$emit("userMessage", "Se ha actualizado el país");
        this.$router.push({ path: "/paises" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
