<template>
  <page-edicion title="Editar campaña">
    <formulario :campania="campania" :descuentos="descuentos" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/campanias.js";

export default {
  components: { Formulario },
  data() {
    return {
      campania: {},
      descuentos: [],
    };
  },
  methods: {
    async cargar(id) {
      let datosCampania = await api.cargar(id);
      this.campania = datosCampania.campania;
      this.descuentos = datosCampania.descuentos;
    },
    async actualizar() {
      await api.actualizar(this.campania, this.descuentos);
      this.$emit("userMessage", "Se ha actualizado la campaña");
      this.$router.push({ path: "/campanias" });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
