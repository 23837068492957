<template>
  <b-form @submit.prevent="submit" enctype="multipart/form-data">
    <b-row>
      <b-col lg="12"> <h3 class="form-h3">Datos de cliente</h3></b-col>
    </b-row>
    <b-row>
      <b-col lg="4" md="12" sm="12">
        <b-form-group label="Nombre*">
          <b-form-input required type="text" v-model="cliente.nombre" placeholder="Nombre del cliente"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <b-form-group label="Razón Social*">
          <b-form-input type="text" v-model="cliente.razonSocial" placeholder="Razón Social"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <campo-imagen title="Logotipo" v-model="imagenes.logo"></campo-imagen>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" md="12" sm="12">
        <b-form-group label="Sector*">
          <b-form-select required v-model="cliente.clienteSectorId">
            <b-form-select-option v-for="sector in sectores" :key="sector.id" :value="sector.id">
              {{ sector.nombreSector }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <b-form-group label="Código de cliente*">
          <b-form-input type="text" v-model="cliente.codigoCliente" placeholder="Código del cliente"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <b-form-group label="Identificador fiscal*">
          <b-form-input
            type="text"
            v-model="cliente.identificadorFiscal"
            placeholder="Identificador fiscal"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12"> <h3 class="form-h3">Representante Legal</h3></b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Nombre*">
          <b-form-input
            type="text"
            v-model="cliente.representanteLegal"
            placeholder="Nombre del representante legal"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Identificador fiscal*">
          <b-form-input
            type="text"
            v-model="cliente.identificadorRepresentanteLegal"
            placeholder="Identificador fiscal del representante legal"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12"> <h3 class="form-h3">Localización</h3></b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="País*">
          <combo required :url="urlAutocompletarPais" v-model="cliente.paisId" :options="opcionesPais"></combo>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Ciudad*">
          <b-form-input type="text" v-model="cliente.ciudad" placeholder="Ciudad"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Domicilio*">
          <b-form-input type="text" v-model="cliente.domicilio" placeholder="Domicilio"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Código Postal*">
          <b-form-input type="text" v-model="cliente.codigoPostal" placeholder="Código Postal"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Teléfono*">
          <b-form-input type="text" v-model="cliente.telefono" placeholder="Teléfono"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Email*">
          <b-form-input type="email" v-model="cliente.email" placeholder="Email"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12"> <h3 class="form-h3">Contactos</h3></b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Contacto Administración*">
          <b-form-select required v-model="cliente.contactoAdministracionId">
            <b-form-select-option v-for="contacto in contactosSelect" :key="contacto.id" :value="contacto.id">
              {{ contacto.contacto }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Contacto Proyecto*">
          <b-form-select required v-model="cliente.contactoProyectoId">
            <b-form-select-option v-for="contacto in contactosSelect" :key="contacto.id" :value="contacto.id">
              {{ contacto.contacto }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group>
      <tabla-contactos :contactos="contactos" :clienteId="cliente.id"></tabla-contactos>
    </b-form-group>

    <botonera-edicion url-cancelar="/clientes" />
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import TablaContactos from "./TablaContactos.vue";
import apiSector from "@/services/sectores.js";

export default {
  props: {
    cliente: Object,
    imagenes: Object,
    pais: Object,
    contactos: Array,
  },
  data() {
    return {
      sectores: [],
      contactosSelect: [],
    };
  },
  components: {
    TablaContactos,
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    urlAutocompletarPais() {
      if (this.esAdmin) {
        return "paises/autocompletar";
      } else {
        return "paises/autocompletarPropios";
      }
    },
    opcionesPais() {
      if (!this.pais) {
        return [];
      }
      return [{ id: this.pais.id, text: this.pais.nombre }];
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    async listarSectores() {
      this.sectores = await apiSector.listar();
    },
  },
  async mounted() {
    await Promise.all([this.listarSectores()]);
  },
  watch: {
    contactos: function () {
      return (this.contactosSelect = this.contactos);
    },
  },
};
</script>
