<template>
  <page-edicion title="Editar duración de contrato">
    <formulario :duracionContrato="duracionContrato" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/duracionesContrato.js";

export default {
  components: { Formulario },
  data() {
    return {
      duracionContrato: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.duracionContrato = response.duracionContrato;
      });
    },
    actualizar() {
      api.actualizar(this.duracionContrato).then(() => {
        this.$emit("userMessage", "Se ha actualizado el registro");
        this.$router.push({ path: "/duracionesContrato" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
