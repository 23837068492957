import Listar from "@/views/tarifas/Listar.vue";
import Crear from "@/views/tarifas/Crear.vue";
import Editar from "@/views/tarifas/Editar.vue";

export default [
  {
    path: "/tarifas",
    component: Listar,
  },
  {
    path: "/tarifas/crear",
    component: Crear,
  },
  {
    path: "/tarifas/editar/:id",
    component: Editar,
  },
];
