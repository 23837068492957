<template>
  <page-edicion title="Editar forma de pago">
    <formulario :formaPago="formaPago" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/formasPago.js";

export default {
  components: { Formulario },
  data() {
    return {
      formaPago: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.formaPago = response.formaPago;
      });
    },
    actualizar() {
      api.actualizar(this.formaPago).then(() => {
        this.$emit("userMessage", "Se ha actualizado el registro");
        this.$router.push({ path: "/formasPago" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
