<template>
  <page-edicion :title="nombreModulo" v-if="this.ready">
    <formulario-planes-precios
      :divisas="divisas"
      :planes="planes"
      :moduloPlanPreciosNormalizados="preciosNormalizados"
      :modulo="modulo"
      :tarifas="tarifas"
      :moduloTarifasNormalizados="preciosTarifasNormalizados"
      @submit="actualizar"
    ></formulario-planes-precios>
  </page-edicion>
</template>

<script>
import FormularioPlanesPrecios from "./FormularioPlanesPrecios.vue";
import * as apiModulos from "@/services/modulos.js";
import apiPlanes from "@/services/planes.js";
import apiDivisas from "@/services/divisas.js";
import apiTarifas from "@/services/tarifas.js";
import * as TipoModulo from "@/enums/TipoModulo";

function completarPreciosTarifasNormalizados(id, tarifas, planes, divisas, modulosTarifaPlanPrecios) {
  for (var tarifa of tarifas) {
    if (!modulosTarifaPlanPrecios.hasOwnProperty(tarifa.id)) {
      modulosTarifaPlanPrecios[tarifa.id] = {};
    }
    for (var plan of planes) {
      if (!modulosTarifaPlanPrecios[tarifa.id][plan.id]) {
        modulosTarifaPlanPrecios[tarifa.id][plan.id] = {};
      }
      for (var divisa of divisas) {
        if (!modulosTarifaPlanPrecios[tarifa.id][plan.id][divisa.id]) {
          modulosTarifaPlanPrecios[tarifa.id][plan.id][divisa.id] = {
            moduloId: id,
            precio: 0,
          };
        }
      }
    }
  }
  return modulosTarifaPlanPrecios;
}

function completarPreciosNormalizados(id, planes, divisas, modulosPlanPrecios) {
  for (var plan of planes) {
    if (!modulosPlanPrecios.hasOwnProperty(plan.id)) {
      modulosPlanPrecios[plan.id] = {};
    }
    for (var divisa of divisas) {
      if (!modulosPlanPrecios[plan.id][divisa.id]) {
        modulosPlanPrecios[plan.id][divisa.id] = {
          moduloId: id,
          precioCuota: 0,
          precioSuscripcion: 0,
          precioHoraCapacitacion: 0,
          precioHoraConsultoria: 0,
          precioServicio: 0,
        };
      }
    }
  }
  return modulosPlanPrecios;
}
export default {
  components: { FormularioPlanesPrecios },
  data() {
    return {
      ready: false,
      moduloId: 0,
      modulo: {},
      planes: [],
      divisas: [],
      precios: [],
      preciosNormalizados: {},
      tarifas: [],
      preciosTarifas: [],
      preciosTarifasNormalizados: {},
    };
  },
  computed: {
    nombreModulo() {
      if (this.modulo.tipo == TipoModulo.SERVICIO) {
        return `Servicio: ${this.modulo.nombre}`;
      } else {
        return `Módulo: ${this.modulo.nombre}`;
      }
    },
  },
  methods: {
    async cargar() {
      this.modulo = await apiModulos.cargar(this.moduloId);
      this.precios = await apiModulos.cargarPrecios(this.moduloId);
      this.preciosTarifas = await apiModulos.cargarPreciosTarifas(this.moduloId);
    },
    async actualizar() {
      await apiModulos.actualizarPrecios(this.moduloId, this.preciosNormalizados);
      await apiModulos.actualizarTarifaPrecios(this.moduloId, this.preciosTarifasNormalizados);
      this.$router.push({ path: "/modulos" });
    },
    async listarPlanes() {
      this.planes = await apiPlanes.listar();
    },
    async listarDivisas() {
      this.divisas = await apiDivisas.listar();
    },
    async listarTarifas() {
      this.tarifas = await apiTarifas.listarActivas();
    },
  },
  async mounted() {
    this.moduloId = this.$route.params.id;
    await Promise.all([this.cargar(), this.listarTarifas(), this.listarPlanes(), this.listarDivisas()]);
    this.preciosNormalizados = completarPreciosNormalizados(this.moduloId, this.planes, this.divisas, this.precios);
    this.preciosTarifasNormalizados = completarPreciosTarifasNormalizados(
      this.moduloId,
      this.tarifas,
      this.planes,
      this.divisas,
      this.preciosTarifas
    );
    this.ready = true;
  },
};
</script>
