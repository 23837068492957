<template>
  <b-form @submit.prevent="submit">
    <b-tabs small card>
      <b-tab title="General">
        <b-form-group label="Nombre">
          <b-form-input
            required
            type="text"
            v-model="plantillaContrato.nombre"
            placeholder="Nombre de la plantilla"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Unidades de negocio">
          <combo-multiple
            :value="unidadesNegocioIdCombo"
            :options="opcionesUnidadesNegocio"
            url="unidadNegocio/autocompletar"
            @input="selectUnidadesNegocio"
          ></combo-multiple>
        </b-form-group>

        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="plantillaContrato.estado"> Activa </b-form-checkbox>
        </b-form-group>
      </b-tab>
      <b-tab title="Contrato">
        <b-form-group label="Formato del contrato a generar">
          <wysiwyg
            id="editorTextoContrato"
            placeholder="Escriba aquí"
            v-model="plantillaContrato.textoContrato"
            :options="opcionesEditor"
          ></wysiwyg>
        </b-form-group>
      </b-tab>
      <b-tab title="Borrador">
        <b-form-group label="Formato del contrato en borrador">
          <wysiwyg
            id="editorTextoBorrador"
            placeholder="Escriba aquí"
            v-model="plantillaContrato.textoBorrador"
            :options="opcionesEditor"
          ></wysiwyg>
        </b-form-group>
      </b-tab>
    </b-tabs>

    <botonera-edicion url-cancelar="/plantillasContrato" />
  </b-form>
</template>
<script>
import apiUnidadesNegocio from "@/services/unidadesNegocio.js";
import EditorShortcodes from "@/components/editor/ModuloShortcodesContrato.vue";
export default {
  props: {
    plantillaContrato: Object,
    unidadesNegocioId: Array,
    opcionesUnidadesNegocio: Array,
  },
  computed: {},
  data() {
    return {
      listaUnidadesNegocio: [],
      unidadesNegocioIdCombo: this.unidadesNegocioId ? this.unidadesNegocioId : [],
      unidadesNegocioSeleccionadas: [],
      opcionesEditor: {
        customModules: [EditorShortcodes],
      },
    };
  },
  methods: {
    listarUnidadesNegocio() {
      return apiUnidadesNegocio.listar(this.$route.query).then((resultado) => {
        this.listaUnidadesNegocio = resultado.unidadesNegocio;
      });
    },
    selectUnidadesNegocio(unidadesNegocioCombo) {
      this.unidadesNegocioSeleccionadas = [];
      var un = {};
      for (un in unidadesNegocioCombo) {
        this.unidadesNegocioSeleccionadas.push({
          plantillaContratoId: this.plantillaContrato.id,
          unidadNegocioId: unidadesNegocioCombo[un],
        });
      }
    },
    submit() {
      this.$emit("submit", this.unidadesNegocioSeleccionadas);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listarUnidadesNegocio();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
