<template>
  <div>
    <table class="table TablaModulosEdicionPrecios">
      <thead valign="middle">
        <th>&nbsp;</th>
        <th :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
          {{ divisa.abreviatura }}
        </th>
      </thead>
      <tbody>
        <tr v-if="mostrarCuota">
          <td>Cuota</td>
          <td :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
            <b-form-input
              v-if="moduloPlanPreciosNormalizados[planId]"
              type="number"
              v-model="moduloPlanPreciosNormalizados[planId][divisa.id].precioCuota"
              placeholder="Precio Cuota"
            ></b-form-input>
          </td>
        </tr>
        <tr v-if="mostrarSuscripcion">
          <td>Suscripción</td>
          <td :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
            <b-form-input
              v-if="moduloPlanPreciosNormalizados[planId]"
              type="number"
              v-model="moduloPlanPreciosNormalizados[planId][divisa.id].precioSuscripcion"
              placeholder="Precio Suscripción"
            ></b-form-input>
          </td>
        </tr>
        <tr v-if="mostrarServicio">
          <td>Servicio</td>
          <td :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
            <b-form-input
              v-if="moduloPlanPreciosNormalizados[planId]"
              type="number"
              v-model="moduloPlanPreciosNormalizados[planId][divisa.id].precioServicio"
              placeholder="Precio Servicio"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td :colspan="columnasDivisas">
            <h2 class="pt-3">Tarifas</h2>
          </td>
        </tr>
        <tr valign="middle" class="labelDivisas">
          <td>&nbsp;</td>
          <td :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
            {{ divisa.abreviatura }}
          </td>
        </tr>
        <tr v-for="tarifa in tarifas" :key="tarifa.id">
          <td>{{ tarifa.nombre }}</td>
          <td :key="divisa.id" v-for="divisa in divisas" :title="divisa.nombre">
            <b-form-input
              v-if="moduloTarifasNormalizados[tarifa.id]"
              type="number"
              v-model="moduloTarifasNormalizados[tarifa.id][planId][divisa.id].precio"
              placeholder="Precio Tarifa"
            ></b-form-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as TipoModulo from "@/enums/TipoModulo";

export default {
  props: {
    moduloPlanPreciosNormalizados: Object,
    planId: Number,
    modulo: Object,
    divisas: Array,
    planes: Array,
    tarifas: Array,
    moduloTarifasNormalizados: Object,
  },
  computed: {
    mostrarCuota() {
      return this.modulo.tipo != TipoModulo.SERVICIO;
    },
    mostrarSuscripcion() {
      return this.modulo.tipo != TipoModulo.SERVICIO;
    },
    mostrarServicio() {
      return this.modulo.tipo == TipoModulo.SERVICIO;
    },
    columnasDivisas() {
      return this.divisas.length + 1;
    },
  },
};
</script>
