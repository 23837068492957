<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="descripcion" style="max-width: 40%" />
      <th-ordenado class="sm-hidden" titulo="Campaña" campo="campania" v-show="colVisible('campania')" />
      <th-ordenado class="sm-hidden" titulo="Días" campo="diasGestion" v-show="colVisible('numDias')">
        <v-icon name="calendar" width="16" title="Días en gestión desde la oferta original" v-b-tooltip.hover />
      </th-ordenado>
      <th-ordenado class="sm-hidden text-center" titulo="Estado" campo="estado" v-show="colVisible('estado')" />
      <th-ordenado class="sm-hidden text-center" titulo="Embudo" campo="pipelineName" v-show="colVisible('pipe')" />
      <th-ordenado class="sm-hidden text-center" titulo="Etapa" campo="stageName" v-show="colVisible('pipe')" />
      <th-ordenado
        class="sm-hidden text-center"
        width="80"
        titulo="Ver."
        campo="numeroVersion"
        v-show="colVisible('version')"
      />
      <th-ordenado titulo="F. Oferta" campo="fecha" v-show="colVisible('fecha')" />
      <th-ordenado titulo="FP/Cierre" campo="fechaPrevistaCierre" v-show="colVisible('fechaPrevistaCierre')" />
      <th-ordenado class="sm-hidden" titulo="Propietario" campo="comercial" v-show="colVisible('propietario')" />
      <th-ordenado class="sm-hidden" width="90" titulo="Plan" campo="plan" v-show="colVisible('plan')" />
      <th class="sm-hidden" width="120" title="Información adicional" v-show="colVisible('info')">Info.</th>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <tr
        v-for="oferta in ofertas"
        v-bind:key="oferta.id"
        :class="{ resaltada: ultimaOfertaModificada == oferta.id }"
        role="button"
      >
        <!-- Nombre  -->
        <td @click="editarOferta(oferta.id)">
          <div class="desc-oferta" :title="oferta.descripcion">
            {{ recortar(oferta.descripcion) }}
          </div>
          <small class="desc-oferta text-secondary" title="oferta.cliente"> ({{ recortar(oferta.cliente) }}) </small>
        </td>
        <!-- Campaña -->
        <td @click="editarOferta(oferta.id)" class="sm-hidden" v-show="colVisible('campania')">
          <div class="text-info">{{ oferta.campania }}</div>
          <div>{{ oferta.pais }}</div>
        </td>
        <!-- Días en gestión -->
        <td
          class="sm-hidden"
          :class="colorParaDiasGestion(oferta.diasGestion)"
          @click="editarOferta(oferta.id)"
          v-show="colVisible('numDias')"
        >
          {{ oferta.diasGestion || "-" }}
        </td>
        <!-- Estado oferta -->
        <td class="sm-hidden" @click="editarOferta(oferta.id)" v-show="colVisible('estado')">
          <b-badge :variant="varianteParaEstado(oferta)">
            {{ oferta.estado }}<br />
            {{ fechaRelevante(oferta) }}
          </b-badge>
          <span v-if="oferta.estado == 'Caducada'" class="estado-caducada">({{ oferta.estadoOriginal }})</span>
        </td>
        <!-- Embudo -->
        <td class="sm-hidden text-center" v-show="colVisible('pipe')">{{ limpiarEmbudo(oferta.pipelineName) }}</td>
        <!-- Etapa -->
        <td class="sm-hidden text-center" v-show="colVisible('pipe')">
          {{ oferta.stageName }}<br />
          <b-badge :variant="varianteParaEstadoPipe(oferta.dealStatus)" pill>{{ oferta.dealStatus }}</b-badge>
        </td>
        <!-- Versión -->
        <td class="sm-hidden text-center" @click="editarOferta(oferta.id)" v-show="colVisible('version')">
          {{ oferta.numeroVersion }}
        </td>
        <!-- Fecha de oferta -->
        <td class="text-center" @click="editarOferta(oferta.id)" v-show="colVisible('fecha')">
          {{ formatFecha(oferta.fecha) }}
        </td>
        <!-- Fecha prevista de cierre -->
        <td class="sm-hidden text-center" @click="editarOferta(oferta.id)" v-show="colVisible('fechaPrevistaCierre')">
          {{ formatFecha(oferta.fechaPrevistaCierre) }}
        </td>
        <!-- Propietario -->
        <td class="sm-hidden" @click="editarOferta(oferta.id)" v-show="colVisible('propietario')">
          {{ oferta.comercial }}
        </td>
        <!-- plan -->
        <td class="sm-hidden text-center" @click="editarOferta(oferta.id)" v-show="colVisible('plan')">
          {{ oferta.plan }} - {{ oferta.divisa }}
        </td>
        <!-- Información adicional -->
        <td class="sm-hidden text-center" v-show="colVisible('info')">
          {{ nombreTipoVenta(oferta.tipoVenta) }}
          <b-badge>
            <a :href="oferta.pipedrive" class="text-white" title="Abrir en pipedrive" target="_blank">{{
              oferta.origen
            }}</a>
          </b-badge>
        </td>
        <td nowrap="nowrap" class="td-acciones">
          <slot name="botonera" v-bind:oferta="oferta">
            <botonera-acciones-tabla-ofertas :oferta="oferta" @ofertaCambiada="ofertaCambiada" />
          </slot>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="!ofertas.length">
      <sin-registros :count="ofertas.length" :colspan="10"></sin-registros>
    </tfoot>
  </table>
</template>
<script>
import { mapState } from "vuex";
import BotoneraAccionesTablaOfertas from "./BotoneraAccionesTablaOfertas.vue";
import { formatDateTabla, formatDateSinZona } from "@/services/date";
import { variantePorEstado as varianteParaEstadoPipe } from "@/services/pipedrive";

import * as TipoVenta from "@/enums/TipoVenta";
export default {
  props: {
    ofertas: Array,
    orden: String,
    filtros: Object,
    query: Object,
    columnasVisibles: {
      type: Array,
      default() {
        return ["campania", "numDias", "estado", "pipe", "version", "fecha", "propietario", "plan", "info"];
      },
    },
  },
  components: {
    BotoneraAccionesTablaOfertas,
  },
  computed: {
    ...mapState(["ultimaOfertaModificada"]),
  },
  methods: {
    colVisible(name) {
      return this.columnasVisibles.includes(name);
    },
    varianteParaEstadoPipe(estado) {
      return varianteParaEstadoPipe(estado);
    },
    limpiarEmbudo(embudo) {
      return embudo.replace(/^[\*\s]+/g, "");
    },
    colorParaDiasGestion(dias) {
      if (dias >= 30) {
        return "texto-dias-negativo";
      } else {
        return "texto-dias-positivo";
      }
    },
    recortar(texto) {
      let chars = 100;
      texto = texto.replace(/_/g, " ");
      if (texto.length > chars) {
        return texto.substring(0, chars) + "...";
      } else {
        return texto;
      }
    },
    fechaRelevante(oferta) {
      return formatDateTabla(oferta.fechaRelevante);
    },
    formatFecha(fecha) {
      if (!fecha) {
        return "-";
      }
      let fechaLimpia = formatDateSinZona(fecha);
      return formatDateTabla(fechaLimpia);
    },
    calculaDias(fecha, estado, ganadaEl, perdidaEl) {
      let origen = new Date(formatDateSinZona(fecha));
      let fin = new Date(Date.now());

      if ((estado == "Ganada" && ganadaEl == "") || (estado == "Perdida" && perdidaEl == "")) {
        return "-";
      }

      if (estado == "Ganada" && ganadaEl != "") {
        fin = new Date(formatDateSinZona(ganadaEl));
      }

      if (estado == "Perdida" && perdidaEl != "") {
        fin = new Date(formatDateSinZona(perdidaEl));
      }

      let resta = fin.getTime() - origen.getTime();
      return Math.round(resta / (1000 * 60 * 60 * 24));
    },
    ofertaCambiada(id) {
      this.$emit("ofertaCambiada", id);
    },
    varianteParaEstado(oferta) {
      switch (oferta.estado) {
        case "Ganada":
          return "success";
        case "Perdida":
          return "danger";
        case "Entregada":
          return "warning";
        case "Visada":
          return "info";
        case "Caducada":
          return "dark";
      }
      return "";
    },
    editarOferta(ofertaId) {
      this.$router.push("/ofertas/editar/" + ofertaId);
    },
    nombreTipoVenta(tipo) {
      return TipoVenta.nombreCorto(tipo);
    },
  },
  data() {
    return {
      searchOfertas: "",
      sortKey: "name",
      reverse: false,
    };
  },
};
</script>
<style>
@media (max-width: 1576px) {
  .desc-oferta {
    font-size: 0.8em;
    max-width: 100px;
    max-height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.estado-caducada {
  font-size: 0.7em;
  display: block;
}
</style>
