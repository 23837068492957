<template>
  <b-form @submit.prevent="$emit('submit')" enctype="multipart/form-data">
    <h2 class="pb-1">Precios por Plan y Divisa</h2>

    <b-tabs content-class="mt-3">
      <b-tab :key="plan.id" v-for="(plan, idx) in planes" :title="plan.nombre" :active="idx == 0">
        <formulario-planes-precios-tabla
          :planId="plan.id"
          :divisas="divisas"
          :planes="planes"
          :tarifas="tarifas"
          :modulo="modulo"
          :moduloPlanPreciosNormalizados="moduloPlanPreciosNormalizados"
          :moduloTarifasNormalizados="moduloTarifasNormalizados"
        ></formulario-planes-precios-tabla>
      </b-tab>
    </b-tabs>

    <botonera-edicion url-cancelar="/modulos" />
  </b-form>
</template>

<script>
import FormularioPlanesPreciosTabla from "./FormularioPlanesPreciosTabla.vue";

export default {
  props: {
    moduloPlanPreciosNormalizados: Object,
    modulo: Object,
    divisas: Array,
    planes: Array,
    tarifas: Array,
    moduloTarifasNormalizados: Object,
  },
  components: {
    FormularioPlanesPreciosTabla,
  },
  data() {
    return {
      divisaIdSeleccionada: null,
    };
  },
};
</script>
