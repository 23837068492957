import api from "./backend";

/**
 * Realiza el login
 */
async function login(usuario, clave) {
  const response = await api.post("acceso/entrar", { usuario, clave });
  return response.data;
}

/**
 * Recupera la sesión si el usuario estaba activo
 */
async function recover() {
  const response = await api.get("acceso/recuperar");
  return response.data;
}

/**
 * Realiza el logout
 */
async function logout() {
  await api.get("acceso/salir");
}

export default { login, recover, logout };
