<template>
  <page-edicion title="Crear cliente">
    <formulario :cliente="cliente" :imagenes="imagenes" :contactos="contactos" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/clientes.js";

export default {
  components: { Formulario },
  data() {
    return {
      cliente: api.crear(),
      imagenes: {},
      contactos: [],
    };
  },
  methods: {
    insertar() {
      var data = {
        cliente: this.cliente,
        imagenes: this.imagenes,
        contactos: this.contactos,
      };
      api.insertar(data).then(() => {
        this.$router.push({ path: "/clientes" });
      });
    },
  },
};
</script>
