<template>
  <page-edicion title="Crear duración de contrato">
    <formulario :duracionContrato="duracionContrato" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/duracionesContrato.js";

export default {
  components: { Formulario },
  data() {
    return {
      duracionContrato: api.crear(),
    };
  },
  methods: {
    insertar() {
      api.insertar(this.duracionContrato).then(() => {
        this.$emit("userMessage", "Se ha creado el registro");
        this.$router.push({ path: "/duracionesContrato" });
      });
    },
  },
};
</script>
