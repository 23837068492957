<template>
  <page-listado title="Contratos" :fluid="true">
    <!-- <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/contratos/crear" v-if="esAdmin">
        <v-icon name="plus" width="16"></v-icon>
        Crear contrato
      </b-button>
    </template> -->
    <template slot="filters">
      <filtros-contratos @filtrar="filtrar" :filtros="$route.query"></filtros-contratos>
    </template>
    <tabla :contratosClientes="resultado.contratosClientes" @eliminar="eliminar" @renovar="renovar"></tabla>
    <b-row class="mt-4">
      <b-col>
        <paginacion
          :page-count="parseInt(resultado.numeroPaginas)"
          :page="parseInt(resultado.pagina)"
          :totalItems="parseInt(resultado.totalItems)"
        />
      </b-col>
    </b-row>
  </page-listado>
</template>

<script>
import { mapState } from "vuex";
import apiContratosCliente from "@/services/contratosCliente.js";
import Tabla from "./TablaContratosCliente.vue";
import FiltrosContratos from "./FiltrosContratos.vue";
import EstadoContrato from "@/enums/EstadoContrato";

export default {
  components: { Tabla, FiltrosContratos },
  data() {
    return {
      resultado: {
        contratosClientes: [],
        numeroPaginas: 0,
        pagina: 1,
      },
    };
  },
  methods: {
    async listar() {
      if (typeof this.$route.query["estados"] == "undefined") {
        this.$route.query["estados"] = [EstadoContrato.PENDIENTE, EstadoContrato.ENPROCESO, EstadoContrato.INICIAL];
      }
      this.resultado = await apiContratosCliente.listar(this.$route.query);
    },
    async eliminar(id) {
      await apiContratosCliente.eliminar(id);
      this.listar();
    },
    async renovar(id) {
      var data = await apiContratosCliente.renovar(id);
      var ofertaId = data.data;
      this.$router.push("/ofertas/editar/" + ofertaId);
    },
    filtrar(data) {
      this.$router
        .push({
          path: this.$route.path,
          query: data,
        })
        .catch((err) => err);
    },
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
