<template>
  <b-form @submit.prevent="buscar">
    <b-input-group>
      <b-form-input v-model="term" type="search" size="sm" placeholder="Búsqueda"> </b-form-input>
      <b-input-group-append>
        <b-button variant="primary" size="sm" type="submit">
          <v-icon variant="primary" name="search" width="16"></v-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <input type="submit" style="display: none" />
  </b-form>
</template>
<script>
export default {
  methods: {
    buscar() {
      this.$emit("buscar", this.term);
    },
  },
  data() {
    return { term: "" };
  },
};
</script>
