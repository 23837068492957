import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    estado: 1,
  };
}

function listar(params) {
  return listado("plantillasContrato/listar", params);
}

function cargar(id) {
  return api.get("plantillasContrato/cargar&id=" + id).then((response) => {
    var data = response.data;

    data.plantillaContrato.estado = Boolean(data.plantillaContrato.estado);
    return data;
  });
}

function insertar(plantillaContrato) {
  return api.post("plantillasContrato/insertar", {
    plantillaContrato: plantillaContrato.plantillaContrato,
    unidadesNegocio: plantillaContrato.unidadesNegocio,
  });
}

function actualizar(plantillaContrato) {
  return api.post("plantillasContrato/actualizar", {
    plantillaContrato: plantillaContrato.plantillaContrato,
    unidadesNegocio: plantillaContrato.unidadesNegocio,
  });
}

function eliminar(id) {
  return api.post("plantillasContrato/eliminar", { id });
}

export default { crear, listar, cargar, insertar, actualizar, eliminar };
