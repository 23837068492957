import Listar from "@/views/paises/Listar.vue";
import Crear from "@/views/paises/Crear.vue";
import Editar from "@/views/paises/Editar.vue";

export default [
  {
    path: "/paises",
    component: Listar,
  },
  {
    path: "/paises/crear",
    component: Crear,
  },
  {
    path: "/paises/editar/:id",
    component: Editar,
  },
];
