<template>
  <b-button-group size="sm">
    <b-dropdown size="sm" right text="" variant="primary" class="actionButton">
      <template slot="button-content">
        <v-icon variant="primary" name="menu" width="16" />
      </template>
      <b-dropdown-item
        :disabled="!puedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeConsultor&ofertaId=${oferta.id}`"
        ><v-icon variant="primary" name="printer" width="16" /> Informe Consultor</b-dropdown-item
      >
      <b-dropdown-item
        :disabled="!puedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeComercial&ofertaId=${oferta.id}`"
        ><v-icon variant="primary" name="printer" width="16" /> Informe Comercial</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item :disabled="!puedeVersionar" size="sm" @click="crearVersion"
        ><v-icon variant="primary" name="copy" width="16" /> Nueva Versión</b-dropdown-item
      >
      <b-dropdown-item :disabled="!puedeActivar" size="sm" @click="activarOferta"
        ><v-icon variant="primary" name="check" width="16" /> Activar</b-dropdown-item
      >
    </b-dropdown>
  </b-button-group>
</template>
<script>
import apiOfertas from "@/services/ofertas";
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    puedeActivar() {
      return this.oferta.ofertaActiva == 0;
    },
    puedeImprimir() {
      return true;
    },
    puedeVersionar() {
      return true;
    },
  },
  methods: {
    async crearVersion() {
      const oferta = await apiOfertas.crearVersion(this.oferta.id);
      this.$router.push({
        path: "/ofertas/editar/" + oferta.id,
      });
    },
    async activarOferta() {
      await apiOfertas.activarOferta(this.oferta.id);
      const message = `Ahora la oferta activada es la versión v${this.oferta.numeroVersion}`;
      this.$bvToast.toast(message, {
        title: "Atención",
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-center",
      });
      this.$emit("ofertaCambiada", this.oferta.id);
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_ENDPOINT,
    };
  },
};
</script>
