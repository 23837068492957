import api from "./backend";

function crear() {
  return {
    id: 0,
    carpetaId: null,
    nombre: "",
    enlace: "",
    creadoEl: "",
    usuarioId: "",
    tipo: null,
    estado: true,
  };
}

function listar() {
  return api.get("repositoriosComerciales/listar").then((response) => {
    var datos = response.data[0];
    actualizarEstado(datos);
    return response.data[0];
  });
}

function insertar(repositorioComercial) {
  return api.post("repositoriosComerciales/insertar", {
    repositorioComercial: repositorioComercial,
  });
}

function actualizar(repositorioComercial) {
  return api.post("repositoriosComerciales/actualizar", {
    repositoriocomercial: repositorioComercial,
  });
}

function eliminar(id) {
  return api.post("repositoriosComerciales/eliminar", { id });
}

function actualizarEstado(repositorioComercial) {
  repositorioComercial.datos.estado = Boolean(repositorioComercial.datos.estado);
  if (repositorioComercial.hijos !== undefined && repositorioComercial.hijos.length) {
    repositorioComercial.hijos.forEach((element) => {
      actualizarEstado(element);
    });
  }
}

export default { crear, listar, insertar, actualizar, eliminar };
