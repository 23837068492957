import Login from "@/views/Login.vue";

export default [
  {
    path: "/",
    redirect: "/ofertas",
  },
  {
    path: "/login",
    component: Login,
  },
];
