import api from "./backend";
import { OTRAS } from "@/enums/TipoCuotaFormaPago";
import { listado } from "./listado";

function crear() {
  return {
    id: 0,
    nombre: "",
    tipoCuota: OTRAS,
  };
}

function listar(params) {
  return listado("formasPago/listar", params);
}

function combo() {
  return listar().then((formasPago) => {
    return formasPago.map((fp) => {
      return {
        text: fp.nombre,
        value: fp.id,
      };
    });
  });
}

function cargar(id) {
  return api.get("formasPago/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(formaPago) {
  return api.post("formasPago/insertar", { formaPago });
}

function actualizar(formaPago) {
  return api.post("formasPago/actualizar", { formaPago });
}

function eliminar(id) {
  return api.post("formasPago/eliminar", { id });
}

export default { crear, listar, combo, cargar, insertar, actualizar, eliminar };
