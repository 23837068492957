import Listar from "@/views/ofertas/Listar.vue";
import Crear from "@/views/ofertas/Crear.vue";
import Editar from "@/views/ofertas/Editar.vue";
import EditarContenido from "@/views/ofertas/EditarContenido.vue";
import EditarContenidoModulos from "@/views/ofertas/EditarContenidoModulos.vue";
import ListarVersiones from "@/views/ofertas/ListarVersiones.vue";
import ListarOfertasCliente from "@/views/ofertas/ListarOfertasCliente.vue";
import EditarContrato from "@/views/ofertas/EditarContrato.vue";

export default [
  {
    path: "/ofertas",
    component: Listar,
  },
  {
    path: "/ofertas/versiones/:id",
    component: ListarVersiones,
  },
  {
    path: "/ofertas/crear",
    component: Crear,
  },
  {
    path: "/ofertas/editar/:id",
    component: Editar,
  },
  {
    path: "/ofertas/editarContenido/:id",
    component: EditarContenido,
  },
  {
    path: "/ofertas/editarContenidoModulos/:id",
    component: EditarContenidoModulos,
  },
  {
    path: "/ofertas/cliente/:id",
    component: ListarOfertasCliente,
  },
  {
    path: "/ofertas/editarContrato/:id",
    component: EditarContrato,
  },
];
