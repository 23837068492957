// Axios
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

const base_url = process.env.VUE_APP_ENDPOINT;

const api = axios.create({
  baseURL: base_url,
  withCredentials: true,
});

export default api;
