<template>
  <select2-multiple-control
    :value="value"
    :options="options"
    @change="update"
    @select="optionSelected"
    :required="required"
    :disabled="disabled"
    :settings="comboSettings"
  ></select2-multiple-control>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(value) {
      this.valuemut = value;
      this.$emit("input", this.valuemut);
    },
    optionSelected(lastSelected) {
      this.$emit("selected", lastSelected);
    },
  },
  data() {
    const comboSettings = {
      placeholder: this.placeholder,
      theme: "bootstrap",
      dropdownAutoWidth: true,
      width: "100%",
    };

    if (this.url != "") {
      comboSettings.ajax = {
        url: process.env.VUE_APP_ENDPOINT + this.url,
        xhrFields: {
          withCredentials: true,
        },
        dataType: "json",
        processResults: function (items) {
          if ("results" in items) {
            items = items.results;
          }
          const results = items.map(function (item) {
            if ("value" in item) {
              return { id: item.value, text: item.text };
            }
            return item;
          });
          return { results: results };
        },
        delay: 250,
      };
    }

    return {
      valuemut: this.value,
      comboSettings: comboSettings,
    };
  },
};
</script>
