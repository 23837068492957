import api from "./backend";
import { listado } from "./listado";
import TipoProyecto from "@/enums/TipoProyecto";
import TipoCuota from "@/enums/TipoCuota";
import * as TipoVenta from "@/enums/TipoVenta";
import { normalizarModulo } from "./modulos";

function crearOfertaBase() {
  return {
    id: null,
    clienteId: null,
    ofertaActivaId: null,
    creadoPor: null,
    creadoEl: null,
    unidadNegocioId: null,
  };
}

function crearOferta() {
  return {
    id: null,
    ofertaBaseId: null,
    usuarioId: null,
    campaniaId: null,
    descripcion: "",
    codigo: null,
    fecha: new Date(),
    fechaRelevante: null,
    numeroVersion: 1,
    paisId: null,
    divisaId: null,
    planId: null,
    origenOfertaId: null,
    complejidadProyectoId: parseInt(process.env.VUE_APP_COMPLEJIDAD_POR_DEFECTO_ID),
    contacto: "",
    cargoContacto: "",
    numeroUsuarios: 0,
    borrador: 1,
    visada: 0,
    entregada: 0,
    ganada: 0,
    perdida: 0,
    revisadoPor: null,
    creadoPor: null,
    creadoEl: null,
    origen: null,
    pipedrive: null,
    tipoVenta: null,
    fechaFirmaContrato: null,
    duracionContratoId: null,
    fechaPrevistaCierre: null,
    caducada: null,
    ofertaDescuentoFirma: crearDescuentoFirma(),
    calculada: true,
    tarifaId: null,
  };
}

function crearOfertaContenido() {
  return {
    ofertaId: null,
    texto: "",
    necesidades: "",
    tablaNecesidades: "",
    resumenEjecutivo: "",
    aclaraciones: "",
    anexo: "",
    logosClientes: "",
    beneficios: "",
    usuarios: "",
    planDeSoporte: "",
    requisitosMinimos: "",
  };
}

function crearOfertaContrato() {
  return {
    ofertaId: null,
    textoContrato: "",
    textoBorrador: "",
  };
}

function crearDescuentoFirma() {
  return {
    id: null,
    ofertaId: null,
    campaniaId: null,
    plazoDescuentoCampaniaId: null,
    dias: 0,
    descuento: 0,
  };
}

function normalizarOferta(data) {
  data.costeDeImplementacionCubierto = Boolean(data.costeDeImplementacionCubierto);
  data.precioModificado = Boolean(data.precioModificado);

  return data;
}

function normalizarOfertaBase(data) {
  if (data.unidadNegocioId == 0) {
    data.unidadNegocioId = null;
  }

  return data;
}

function normalizarOfertaModulo(data) {
  data.cubreCosteDeImplementacion = Boolean(data.cubreCosteDeImplementacion);
  data.precioModificado = Boolean(data.precioModificado);

  return data;
}

function crearOfertaFormaPago() {
  return {
    ofertaId: null,
    tipoProyecto: TipoProyecto.INVERSION,
    formaPagoSuscripcionId: process.env.VUE_APP_FORMA_PAGO_POR_DEFECTO_ID,
    formaPagoImplementacionId: process.env.VUE_APP_FORMA_PAGO_POR_DEFECTO_ID,
    tipoCuota: TipoCuota.ANUAL,
  };
}

function crearOfertaTipoDescuento() {
  return {
    ofertaId: null,
    descuentoSuscripcion: 0,
    descuentoCuota: 0,
    descuentoImplementacion: 0,
  };
}

function listar(params) {
  return listado("ofertas/listar", params);
}

function listarVersionesDe(de, params) {
  return listado("ofertas/listar", params, {
    versionesDe: de,
  });
}

function listarOfertasDeCliente(de, params) {
  return listado("ofertas/listar", params, {
    ofertaDeCliente: de,
  });
}

/**
 * Carga una oferta:
 * Recibe:
 *     data.ofertaBase,
 *     data.oferta,
 *     data.ofertaContenido,
 *     data.ofertaModulos,
 *     data.cliente,
 *     data.ofertaFormaPago,
 *     data.ofertaTipoDescuento,
 */
async function cargar(id) {
  const response = await api.get("ofertas/cargar&ofertaId=" + id);
  const data = response.data;

  data.oferta = normalizarOferta(data.oferta);
  data.ofertaBase = normalizarOfertaBase(data.ofertaBase);
  Object.values(data.modulos).forEach(normalizarModulo);
  data.ofertaModulos.forEach(normalizarOfertaModulo);
  data.esPartner = Boolean(data.esPartner);

  return data;
}

function moduloParaEstaOferta(params) {
  return api.get("ofertas/moduloParaEstaOferta", { params }).then((response) => {
    var moduloPlanHoras = response.data.moduloPlanHoras;
    var moduloPlanPrecios = response.data.moduloPlanPrecios;
    var preciosHora = response.data.preciosHora;
    var modulo = response.data.modulo;
    var moduloContenido = response.data.moduloContenido;
    var moduloTarifaPlanPrecios = response.data.moduloTarifaPlanPrecios;

    return {
      modulo: modulo,
      ofertaModulo: {
        moduloId: Number(modulo.id),
        nombre: modulo.nombre,
        cantidad: 1,
        numHorasConsultoria: Number(moduloPlanHoras.numHorasConsultoria),
        numHorasCapacitacion: Number(moduloPlanHoras.numHorasCapacitacion),
        precioSuscripcion: Number(moduloPlanPrecios.precioSuscripcion),
        permiteCambioPrecio: Boolean(modulo.permiteCambioPrecio),
        permiteCambioHoras: Boolean(modulo.permiteCambioHoras),
        precioCuota: Number(moduloPlanPrecios.precioCuota),
        precioHoraConsultoria: Number(preciosHora.precioHoraConsultoria),
        precioHoraCapacitacion: Number(preciosHora.precioHoraCapacitacion),
        precioServicio: Number(moduloPlanPrecios.precioServicio),
        cubreCosteDeImplementacion: Boolean(modulo.cubreCosteDeImplementacion),
        precioTarifa: Number(moduloTarifaPlanPrecios.precio),
        precioModificado: Boolean(modulo.precioModificado),
      },
      moduloContenido,
    };
  });
}

async function insertar({ ofertaBase, oferta, ofertaFormaPago, ofertaTipoDescuento, ofertaModulosAgregados }) {
  const r = await api.post("ofertas/insertar", {
    ofertaBase,
    oferta,
    ofertaFormaPago,
    ofertaTipoDescuento,
    ofertaModulosAgregados,
    //ofertaModulosLibres,
  });
  return r.data;
}

async function actualizar({
  ofertaBase,
  oferta,
  ofertaFormaPago,
  ofertaTipoDescuento,
  ofertaModulosAgregados,
  ofertaModulosActualizados,
  ofertaModulosEliminados,
}) {
  const r = await api.post("ofertas/actualizar", {
    ofertaBase,
    oferta,
    ofertaFormaPago,
    ofertaTipoDescuento,
    ofertaModulosAgregados,
    ofertaModulosActualizados,
    ofertaModulosEliminados,
    //ofertaModulosLibres,
  });
  return r.data;
}

async function cargarContenidoVersion(id) {
  const r = await api.get("ofertas/cargarContenidoVersion&ofertaId=" + id);
  return r.data.ofertaContenido;
}

async function guardarContenidoVersion(ofertaContenido) {
  const r = await api.post("ofertas/guardarContenidoVersion", {
    ofertaContenido: ofertaContenido,
  });
  return r.data;
}

async function cargarContratoVersion(id) {
  const r = await api.get("ofertas/cargarContratoVersion&ofertaId=" + id);
  return r.data.ofertaContrato;
}

async function guardarContratoVersion(ofertaContrato) {
  const r = await api.post("ofertas/guardarContratoVersion", {
    ofertaContrato: ofertaContrato,
  });
  return r.data;
}

async function cargarEstados() {
  const r = await api.post("ofertas/autocompletarEstados");
  return r.data;
}

function crearVersion(id) {
  return api.get("ofertas/crearVersion&ofertaId=" + id).then((r) => r.data);
}

function entregarVersion(id) {
  return api.get("ofertas/entregarVersion&ofertaId=" + id).then((r) => r.data);
}

function visarVersion(id) {
  return api.get("ofertas/visarVersion&ofertaId=" + id).then((r) => r.data);
}

function ganarVersion(id, ganadaEl, ordenCompra, cartaBienvenida) {
  return api.post("ofertas/ganarVersion", {
    ofertaId: id,
    ganadaEl: ganadaEl,
    ordenCompra: ordenCompra,
    cartaBienvenida: cartaBienvenida,
  });
}

function perderVersion(id) {
  return api.get("ofertas/perderVersion&ofertaId=" + id).then((r) => r.data);
}

function enviarVersionPapelera(id) {
  return api.get("ofertas/enviarVersionPapelera&ofertaId=" + id).then((r) => r.data);
}

function recuperarVersionDePapelera(id) {
  return api.get("ofertas/recuperarVersionDePapelera&ofertaId=" + id).then((r) => r.data);
}

function tieneContrato(id) {
  return api.get("ofertas/tieneContrato&ofertaId=" + id).then((r) => r.data);
}

function tieneUnidadNegocio(id) {
  return api.get("ofertas/tieneUnidadNegocio&ofertaBaseId=" + id).then((r) => r.data);
}

/**
 * Carga los módulos de una oferta incluyendo su contenido
 */
async function guardarContenidoModulos(ofertaId, ofertaModulosContenido) {
  return await api.post("ofertas/guardarContenidoModulos", {
    ofertaId,
    ofertaModulosContenido,
  });
}

export function calcularValoracion(params) {
  return api
    .post("ofertas/calcularValoracion", {
      formaPago: params.formaPago,
      tipoDescuento: params.tipoDescuento,
      complejidadProyectoId: params.complejidadProyectoId,
      ofertaModulos: params.ofertaModulos,
      modulos: params.modulos,
      costeDeImplementacionCubierto: params.costeDeImplementacionCubierto,
      conTarifa: params.conTarifa,
    })
    .then((response) => {
      var valoracion = response.data;

      return valoracion;
    });
}

export function activarOferta(ofertaId) {
  return api.post("ofertas/activarOferta", { ofertaId });
}

export default {
  crearOfertaBase,
  crearOferta,
  crearOfertaContenido,
  crearOfertaContrato,
  crearOfertaFormaPago,
  crearOfertaTipoDescuento,
  listar,
  listarVersionesDe,
  cargar,
  moduloParaEstaOferta,
  insertar,
  actualizar,
  cargarContenidoVersion,
  guardarContenidoVersion,
  cargarContratoVersion,
  guardarContratoVersion,
  crearVersion,
  entregarVersion,
  visarVersion,
  ganarVersion,
  perderVersion,
  enviarVersionPapelera,
  recuperarVersionDePapelera,
  guardarContenidoModulos,
  cargarEstados,
  calcularValoracion,
  listarOfertasDeCliente,
  crearDescuentoFirma,
  tieneContrato,
  tieneUnidadNegocio,
  activarOferta,
};
