import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    fechaInicio: "",
    fechaFin: "",
    orden: "",
    porDefecto: null,
    estado: null,
  };
}

function listar(params) {
  return listado("tarifas/listar", params);
}

function listarActivas(params) {
  return listado("tarifas/listarActivas", params);
}

function cargar(id) {
  return api.get("tarifas/cargar&id=" + id).then((response) => {
    var data = response.data;

    data.porDefecto = Boolean(data.porDefecto);
    data.estado = Boolean(data.estado);
    return data;
  });
}

function insertar(tarifa) {
  return api.post("tarifas/insertar", {
    tarifa: tarifa.tarifa,
    paises: tarifa.paises,
  });
}

function actualizar(tarifa) {
  return api.post("tarifas/actualizar", {
    tarifa: tarifa.tarifa,
    paises: tarifa.paises,
  });
}

function eliminar(id) {
  return api.post("tarifas/eliminar", { id });
}

export default { crear, listar, listarActivas, cargar, insertar, actualizar, eliminar };
