<template>
  <page-edicion title="Crear factor de complejidad">
    <formulario :complejidadProyecto="complejidadProyecto" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/complejidadesProyecto.js";

export default {
  components: { Formulario },
  data() {
    return {
      complejidadProyecto: {},
    };
  },
  methods: {
    insertar() {
      api.insertar(this.complejidadProyecto).then(() => {
        this.$emit("userMessage", "Se ha creado el registro");
        this.$router.push({ path: "/complejidadesProyecto" });
      });
    },
  },
};
</script>
