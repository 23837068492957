<template>
  <div>
    <b-row class="mb-0">
      <b-col lg="12">
        <h2 class="pb-1">Cliente: {{ cliente }}</h2>
      </b-col>
    </b-row>
    <b-row class="mb-1 border-bottom pb-1">
      <b-col md="4"><label>País</label></b-col>
      <b-col>
        {{ pais }}
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-1 pt-1 border-bottom pb-1">
      <b-col md="4"><label>Contacto</label></b-col>
      <b-col>
        {{ contacto }}
      </b-col>
      <b-col md="3">
        <b-button
          v-if="oferta.borrador"
          variant="primary"
          size="sm"
          v-b-modal.modal-contactoCliente
          v-show="contactos.length > 1"
        >
          Cambiar
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-1 pt-1 border-bottom pb-1">
      <b-col md="4"><label>Cargo del Contacto</label></b-col>
      <b-col>
        {{ cargoContacto }}
      </b-col>
    </b-row>
    <modal-contacto-cliente :contactos="contactos" @seleccionado="cambiarContacto" />
  </div>
</template>

<script>
import apiPaises from "@/services/paises.js";
import ModalContactoCliente from "./ModalContactoCliente.vue";

export default {
  props: {
    oferta: { type: Object, required: true },
    pais: { type: String, required: true },
    cliente: { type: String, required: true },
    contacto: { type: String, required: true },
    cargoContacto: { type: String, required: true },
    contactos: { type: Array, required: true },
  },
  components: {
    ModalContactoCliente,
  },
  methods: {
    cambiarContacto(contacto) {
      this.$emit("contactoCambiado", contacto);
    },
  },
  data() {
    return {};
  },
};
</script>
