<template>
  <div>
    <b-row class="mb-1">
      <b-col lg="12">
        <h5 v-if="!calculada || !conTarifa">Primer pago</h5>
        <h5 v-if="calculada && conTarifa">Servicios</h5>
        <div class="cont-precio">
          <span class="precio">{{ inversion | moneda(divisa) }} </span>
        </div>
        <h5>Cuota</h5>
        <div class="cont-precio">
          <span class="precio">{{ cuota | moneda(divisa) }} </span>
          <b-badge v-if="tipoCuota == 2"> Mensual </b-badge>
          <b-badge v-else-if="tipoCuota == 1"> 12 Meses </b-badge>
          <b-badge v-else-if="tipoCuota == 3"> 24 Meses </b-badge>
          <b-badge v-else-if="tipoCuota == 4"> 36 Meses </b-badge>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TipoProyecto from "@/enums/TipoProyecto";
import TipoCuota from "@/enums/TipoCuota";

export default {
  props: {
    cuota: Number,
    tipoCuota: Number,
    inversion: Number,
    divisa: {
      type: Object,
      required: true,
    },
    calculada: Boolean,
    conTarifa: Boolean,
  },
  filters: {
    moneda(value, divisa) {
      if (divisa.simbolo != undefined && divisa.abreviatura != undefined) {
        return parseFloat(value).toLocaleString("de-DE") + (divisa.simbolo || " " + divisa.abreviatura);
      } else {
        return parseFloat(value).toLocaleString("de-DE");
      }
    },
  },
  computed: {},
};
</script>
