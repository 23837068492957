<template>
  <b-modal id="modal-divisa" title="Elegir Divisa" @ok="aceptar" @shown="divisaSeleccionada = divisaId">
    <b-form-select v-model="divisaSeleccionada" class="mb-2">
      <b-form-select-option v-for="divisa in divisas" :key="divisa.id" :value="divisa.id">
        {{ divisa.nombre }} ({{ divisa.abreviatura }})
      </b-form-select-option>
    </b-form-select>
  </b-modal>
</template>

<script>
export default {
  props: {
    divisaId: Number,
    divisas: Array,
  },
  data() {
    return {
      divisaSeleccionada: this.divisaId,
    };
  },
  methods: {
    aceptar() {
      this.$emit("ok", this.divisaSeleccionada);
    },
  },
};
</script>
