<template>
  <page-listado title="Duraciones de Contrato" :fluid="true">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/duracionesContrato/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear duración de contrato
      </b-button>
    </template>
    <tabla :duracionesContrato="duracionesContrato"></tabla>
  </page-listado>
</template>

<script>
import api from "@/services/duracionesContrato.js";
import Tabla from "./TablaDuracionesContrato.vue";
export default {
  components: { Tabla },
  data() {
    return {
      duracionesContrato: [],
    };
  },
  methods: {
    async listar() {
      this.duracionesContrato = await api.listar(this.$route.query);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
