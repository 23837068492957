<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input
        required
        type="text"
        v-model="origenOferta.origen"
        placeholder="Nombre del origen de oferta"
      ></b-form-input>
    </b-form-group>
    <botonera-edicion url-cancelar="/origenOferta" />
  </b-form>
</template>
<script>
export default {
  props: {
    origenOferta: Object,
  },
};
</script>
