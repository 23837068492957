<template>
  <v-select2
    :id="id"
    :options="options"
    :value="value"
    @change="update"
    :required="required"
    :disabled="disabled"
    :settings="comboSettings"
  >
  </v-select2>
</template>
<script>
export default {
  props: {
    url: [String],
    value: [String, Number],
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    update(value) {
      this.valuemut = value;
      this.$emit("input", this.valuemut);
    },
    getUrl() {
      return process.env.VUE_APP_ENDPOINT + this.url;
    },
  },
  data() {
    const comboSettings = {
      placeholder: this.placeholder,
      theme: "bootstrap",
      language: "es",
      width: "100%",
      minimumResultsForSearch: 10,
    };

    if (this.url != "") {
      comboSettings.ajax = {
        url: this.getUrl,
        xhrFields: {
          withCredentials: true,
        },
        processResults: function (items) {
          if ("results" in items) {
            items = items.results;
          }

          const results = items.map(function (item) {
            if ("value" in item) {
              return { id: item.value, text: item.text };
            }
            return item;
          });
          return { results: results };
        },
        dataType: "json",
        delay: 250,
      };
    }

    return {
      valuemut: this.value,
      comboSettings: comboSettings,
    };
  },
};
</script>
