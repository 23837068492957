import api from "./backend";

function crear() {
  return {
    id: 0,
    sectorNombre: "",
  };
}

function listar() {
  return api.get("sector/listar").then((r) => r.data);
}

export default {
  crear,
  listar,
};
