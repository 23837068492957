<template>
  <b-form @submit.prevent="submit">
    <b-form-group label="Nombre">
      <b-form-input required type="text" v-model="tarifa.nombre" placeholder="Nombre de la tarifa"></b-form-input>
    </b-form-group>
    <b-row class="mb-3">
      <b-col lg="6">
        <b-form-group label="Fecha Inicio">
          <b-form-datepicker locale="es-ES" v-model="fechaInicio" :start-weekday="1"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Fecha Fin">
          <b-form-datepicker locale="es-ES" v-model="fechaFin" :start-weekday="1"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="4">
        <b-form-group label="Orden">
          <b-form-input required type="number" v-model="tarifa.orden" min="1" placeholder="Orden"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Por defecto">
          <b-form-checkbox switch size="lg" v-model="tarifa.porDefecto"> Tarifa por defecto </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="tarifa.estado"> Activa </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion url-cancelar="/tarifas" />
  </b-form>
</template>
<script>
import { formatDateForm, formatDateAddDaysForm, today } from "@/services/date";

export default {
  props: {
    tarifa: Object,
  },
  data() {
    return {};
  },
  computed: {
    fechaInicio: {
      get() {
        return formatDateForm(this.tarifa.fechaInicio);
      },
      set(value) {
        this.tarifa.fechaInicio = value;
      },
    },
    fechaFin: {
      get() {
        return formatDateForm(this.tarifa.fechaFin);
      },
      set(value) {
        this.tarifa.fechaFin = value;
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
  watch: {
    "$route.params": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },
};
</script>
