<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Origen" campo="origen" class="text-left"></th-ordenado>
      <th class="th-acciones text-right">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!origenOferta" :colspan="4"></sin-registros>
      <tr v-for="origen in origenOferta" v-bind:key="origen.id" role="button">
        <td @click="editarOrigenOferta(origen.id)">{{ origen.origen }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/origenOferta/editar/${origen.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', origen.id)">
              ¿ Desea eliminar el origen de oferta <b>{{ origen.origen }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    origenOferta: Array,
  },
  methods: {
    editarOrigenOferta(origenOfertaId) {
      this.$router.push("/origenOferta/editar/" + origenOfertaId);
    },
  },
};
</script>
