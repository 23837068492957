<template>
  <page-listado :fluid="true" title="Orígenes de oferta">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/origenOferta/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear origen
      </b-button>
    </template>
    <tabla :origenOferta="origenOferta" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import apiOrigenOferta from "@/services/origenOferta.js";
import Tabla from "./TablaOrigenOferta.vue";
export default {
  components: { Tabla },
  data() {
    return {
      origenOferta: [],
    };
  },
  methods: {
    async listar() {
      this.origenOferta = await apiOrigenOferta.listar(this.$route.query);
    },
    async eliminar(id) {
      await apiOrigenOferta.eliminar(id);
      await this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
