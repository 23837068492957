<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="descripcion"></th-ordenado>
      <th>País</th>
      <th align="center">Ver.</th>
      <th-ordenado titulo="Fecha" campo="fecha"></th-ordenado>
      <th>Propietario</th>
      <th>Plan</th>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros :count="ofertas.length" :colspan="8"></sin-registros>
      <tr v-for="oferta in ofertas" v-bind:key="oferta.id">
        <td>{{ oferta.descripcion }}</td>
        <td>{{ oferta.pais }}</td>
        <td align="center">{{ oferta.numeroVersion }}</td>
        <td>{{ formatFecha(oferta.fecha) }}</td>
        <td>{{ oferta.comercial }}</td>
        <td>{{ oferta.plan }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla-ofertas :oferta="oferta" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTablaOfertas from "./BotoneraAccionesTablaOfertas.vue";
import { formatDateTabla } from "@/services/date";
export default {
  props: {
    ofertas: Array,
    orden: String,
    filtros: Object,
    query: Object,
  },
  components: {
    BotoneraAccionesTablaOfertas,
  },
  methods: {
    formatFecha(fecha) {
      return formatDateTabla(fecha);
    },
    getEstado(oferta) {
      if (oferta.perdida) {
        return "Perdida";
      }
      if (oferta.ganada) {
        return "Ganada";
      }
      if (oferta.entregada) {
        return "Entregada";
      }
      if (oferta.visada) {
        return "Visada";
      }
      return "Borrador";
    },
  },
  data() {
    return {
      searchOfertas: "",
      sortKey: "name",
      reverse: false,
    };
  },
};
</script>
