<template>
  <page>
    <formulario-oferta titulo="Editar Oferta" :id="parseInt($route.params.id)" />
  </page>
</template>

<script>
import FormularioOferta from "./FormularioOferta/FormularioOferta.vue";

export default {
  components: { FormularioOferta },
};
</script>
