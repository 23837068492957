<template>
  <page-edicion title="Editar tarifa">
    <formulario :tarifa="tarifa" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/tarifas.js";

export default {
  components: { Formulario },
  data() {
    return {
      tarifa: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.tarifa = response;
      });
    },
    actualizar() {
      var tarifa = {
        tarifa: this.tarifa,
      };
      api.actualizar(tarifa).then(() => {
        this.$router.push({ path: "/tarifas" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
