import api from "./backend";

function useFilter(key, filtros, params) {
  if (key in params) {
    filtros[key] = params[key];
  }
}

function useArrayFilter(key, filtros, params) {
  if (key in params) {
    let items = [];
    if (Array.isArray(params[key])) {
      items = params[key];
    } else {
      items.push(params[key]);
    }
    filtros[key] = items;
  }
}

export function procesarFiltros(params) {
  const procesados = {};

  if ("filtros" in params) {
    for (const key in params.filtros) {
      useFilter(key, procesados, params);
    }
  }

  useFilter("busqueda", procesados, params);
  useArrayFilter("estados", procesados, params);
  useFilter("estado", procesados, params);
  useFilter("fecha", procesados, params);
  useFilter("fechaDesde", procesados, params);
  useFilter("fechaHasta", procesados, params);
  useFilter("fechaOfertaDesde", procesados, params);
  useFilter("fechaOfertaHasta", procesados, params);
  useFilter("fechaPrevistaCierreDesde", procesados, params);
  useFilter("fechaPrevistaCierreHasta", procesados, params);
  useFilter("fechaAlta", procesados, params);
  useFilter("fechaRenovacion", procesados, params);
  useFilter("vencimiento", procesados, params);
  useFilter("origen", procesados, params);
  useFilter("enPapelera", procesados, params);
  useArrayFilter("origenes", procesados, params);
  useArrayFilter("paises", procesados, params);
  useArrayFilter("clientes", procesados, params);
  useArrayFilter("campanias", procesados, params);
  useArrayFilter("usuarios", procesados, params);
  useArrayFilter("propietarios", procesados, params);
  useArrayFilter("tiposDeVenta", procesados, params);
  useArrayFilter("embudos", procesados, params);
  useArrayFilter("etapas", procesados, params);
  useArrayFilter("estadosPipedrive", procesados, params);

  return procesados;
}

export async function listado(endpoint, params, filtros) {
  params = params || {};
  filtros = filtros || {};

  const query = {
    pagina: 1,
    filtros: Object.assign(filtros, procesarFiltros(params)),
    orden: {},
  };

  if ("pagina" in params) {
    query.pagina = params.pagina;
  }

  if ("orden" in params) {
    query.orden = {
      [params.orden]: params.dir || "ASC",
    };
  }

  const response = await api.post(endpoint, query);

  return response.data;
}
