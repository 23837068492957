<!--

    Se opta por dar soporte de descuentos unicamente a la susc

-->
<template>
  <b-modal id="modal-descuentos" title="Descuentos de la oferta" @ok="aceptar">
    <b-form-group label="Descuento suscripción" label-cols-lg="6" v-if="mostrarDtoSuscripcion">
      <b-form-select v-model="descuentoSuscripcion" :options="comboDescuento"></b-form-select>
    </b-form-group>

    <b-form-group label-cols-lg="6" label="Descuento cuota" v-show="mostrarDtoCuota">
      <b-form-select v-model="descuentoCuota" :options="comboDescuento"></b-form-select>
    </b-form-group>

    <b-form-group label="Descuento implementación" label-cols-lg="6" v-show="mostrarDtoImplementacion">
      <b-form-select v-model="descuentoImplementacion" :options="comboDescuento"></b-form-select>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import UsoDescuento from "@/enums/UsoDescuento";

export default {
  props: {
    tiposDescuento: {
      type: Array,
      default: () => [],
    },
    ofertaTipoDescuento: {
      type: Object,
      default: () => {},
    },
    esSaas: Boolean,
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    comboDescuento() {
      return this.tiposDescuento.map((x) => {
        return {
          value: x.descuento,
          text: x.descripcion,
        };
      });
    },
    mostrarDtoSuscripcion() {
      return !this.esSaas;
    },
    mostrarDtoCuota() {
      return this.esSaas || this.esAdmin;
    },
    mostrarDtoImplementacion() {
      return !this.esSaas && this.esAdmin;
    },
    descuentoCuota: {
      get() {
        return this.ofertaTipoDescuento.descuentoCuota;
      },
      set(value) {
        this.ofertaTipoDescuento.descuentoCuota = value;
      },
    },
    descuentoSuscripcion: {
      get() {
        return this.ofertaTipoDescuento.descuentoSuscripcion;
      },
      set(value) {
        this.ofertaTipoDescuento.descuentoSuscripcion = value;
      },
    },
    descuentoImplementacion: {
      get() {
        return this.ofertaTipoDescuento.descuentoImplementacion;
      },
      set(value) {
        this.ofertaTipoDescuento.descuentoImplementacion = value;
      },
    },
  },
  methods: {
    aceptar() {
      this.$emit("ok", {
        descuentoCuota: this.descuentoCuota,
        descuentoSuscripcion: this.descuentoSuscripcion,
        descuentoImplementacion: this.descuentoImplementacion,
      });
    },
  },
};
</script>
