<template>
  <page-edicion title="Editar usuario">
    <p>Creado el {{ usuario.creadoEl | fecha }}</p>

    <formulario
      :usuario="usuario"
      :imagenes="imagenes"
      :paisesResponsable="paisesResponsable"
      :paisesComercial="paisesComercial"
      :unidadesNegocio="unidadesNegocio"
      :descuentos="descuentos"
      :opcionesPaisesResponsable="opcionesPaisesResponsable"
      :opcionesPaisesComercial="opcionesPaisesComercial"
      :opcionesUnidadesNegocio="opcionesUnidadesNegocio"
      :opcionesDescuentos="opcionesDescuentos"
      @submit="actualizar"
    >
    </formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/usuarios.js";
import apiPaises from "@/services/paises.js";
import apiUnidadesNegocio from "@/services/unidadesNegocio.js";
import apiDescuentos from "@/services/tipoDescuento.js";

export default {
  components: { Formulario },
  data() {
    return {
      usuario: {},
      imagenes: {},
      paisesResponsable: [],
      paisesComercial: [],
      opcionesPaisesResponsable: [],
      opcionesPaisesComercial: [],
      opcionesUnidadesNegocio: [],
      opcionesDescuentos: [],
      unidadesNegocio: [],
      descuentos: [],
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.usuario = response.usuario;
        this.imagenes = response.imagenes;
        this.paisesResponsable = response.paisesResponsable;
        this.paisesComercial = response.paisesComercial;
        this.unidadesNegocio = response.unidadesNegocio;
        this.descuentos = response.descuentos;

        for (var pr in this.paisesResponsable) {
          apiPaises.cargar(this.paisesResponsable[pr]).then((response) => {
            // array opciones nombre pais para opciones selected combo multiple
            this.opcionesPaisesResponsable.push({
              id: response.pais.id,
              text: response.pais.nombre,
            });
          });
        }
        for (var pc in this.paisesComercial) {
          apiPaises.cargar(this.paisesComercial[pc]).then((response) => {
            // array opciones nombre pais para opciones selected combo multiple
            this.opcionesPaisesComercial.push({
              id: response.pais.id,
              text: response.pais.nombre,
            });
          });
        }
        for (var un in this.unidadesNegocio) {
          apiUnidadesNegocio.cargar(this.unidadesNegocio[un]).then((response) => {
            // array opciones nombre pais para opciones selected combo multiple
            this.opcionesUnidadesNegocio.push({
              id: response.unidadNegocio.id,
              text: response.unidadNegocio.nombreComercial,
            });
          });
        }
        for (var dto in this.descuentos) {
          apiDescuentos.cargar(this.descuentos[dto]).then((response) => {
            // array opciones nombre pais para opciones selected combo multiple
            this.opcionesDescuentos.push({
              id: response.id,
              text: response.descripcion,
            });
          });
        }
      });
    },
    actualizar() {
      var usuarioCompleto = {
        usuario: this.usuario,
        imagenes: this.imagenes,
        paisesResponsable: this.paisesResponsable,
        paisesComercial: this.paisesComercial,
        unidadesNegocio: this.unidadesNegocio,
        descuentos: this.descuentos,
      };

      api.actualizar(usuarioCompleto).then(() => {
        this.$emit("userMessage", "Se ha actualizado el usuario");
        this.$router.push({ path: "/usuarios" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
