import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: null,
    clienteId: null,
    ofertaId: null,
    codigoOferta: "",
    ofertaRenovacionId: null,
    codigoOfertaRenovacion: "",
    contratoOrigenId: null,
    creadoEl: "",
    usuarioId: null,
    fechaRenovacion: "",
    importeRenovacion: 0,
    importeRenovacionInicial: 0,
    planId: null,
    paisId: null,
    divisaId: null,
    unidadNegocioId: null,
    fechaAlta: "",
    campaniaId: null,
    numeroSerie: "",
    estado: null,
  };
}

function listar(params) {
  if (typeof params.estados == "string") {
    params.estados = [params.estados];
  }
  return listado("contratosClientes/listar", params);
}

function listarContratosDeCliente(clienteId, params) {
  return listado("contratosClientes/listar", params, {
    contratosDeCliente: clienteId,
  });
}

function listarPropios(params) {
  return listado("contratosClientes/listarPropios", params);
}

function cargar(id) {
  return api.get("contratosClientes/cargar&id=" + id).then((response) => {
    var data = response.data;

    return data;
  });
}

function insertar(contratoCliente) {
  return api.post("contratosClientes/insertar", {
    contratoCliente: contratoCliente.contratoCliente,
  });
}

function actualizar(contratoCliente) {
  return api.post("contratosClientes/actualizar", {
    contratoCliente: contratoCliente.contratoCliente,
    eliminarAnexos: contratoCliente.eliminarAnexos,
    ficherosAnexos: contratoCliente.ficherosAnexos,
  });
}

function eliminar(id) {
  return api.post("contratosClientes/eliminar", { id });
}

function renovar(id) {
  return api.post("contratosClientes/renovar", { id });
}

function descargarAnexo(anexo) {
  return api.post("contratosClientes/descargarAnexo", {
    contratoClienteId: anexo.contratoClienteId,
    contratoClienteAnexoId: anexo.contratoClienteAnexoId,
  });
}

export default {
  crear,
  listar,
  listarPropios,
  cargar,
  insertar,
  actualizar,
  eliminar,
  renovar,
  listarContratosDeCliente,
  descargarAnexo,
};
