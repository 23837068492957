import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
  };
}

function listar(params) {
  return listado("divisas/listar", params);
}

function cargar(id) {
  return api.get("divisas/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(divisa) {
  return api.post("divisas/insertar", {
    divisa: divisa.divisa,
    paises: divisa.paises,
  });
}

function actualizar(divisa) {
  return api.post("divisas/actualizar", {
    divisa: divisa.divisa,
    paises: divisa.paises,
  });
}

function eliminar(id) {
  return api.post("divisas/eliminar", { id });
}

export default { crear, listar, cargar, insertar, actualizar, eliminar };
