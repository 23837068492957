<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Descripción">
      <b-form-input
        required
        type="text"
        v-model="complejidadProyecto.descripcion"
        placeholder="Descripción"
      ></b-form-input>
    </b-form-group>
    <b-row>
      <b-col md="2">
        <b-form-group label="Factor de corrección">
          <b-form-input
            required
            min="1"
            max="100"
            type="number"
            v-model="complejidadProyecto.factorCorreccion"
            maxlength="6"
            placeholder="1"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion url-cancelar="/complejidadesProyecto" />
  </b-form>
</template>
<script>
export default {
  props: {
    complejidadProyecto: Object,
  },
};
</script>
