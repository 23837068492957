import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    contacto: "",
    contactoCargo: "",
    logo: "",
    paisId: null,
    numeroOfertas: 0,
  };
}

async function listar(params) {
  return await listado("clientes/listar", params);
}

function cargar(id, conImagen = true) {
  return api.get("clientes/cargar&id=" + id + "&conImagen=" + conImagen).then((r) => r.data);
}

function insertar({ cliente, imagenes, contactos }) {
  return api.post("clientes/insertar", { cliente, imagenes, contactos }).then((r) => r.data);
}

function actualizar({ cliente, imagenes, contactos }) {
  return api.post("clientes/actualizar", { cliente, imagenes, contactos }).then((r) => r.data);
}

function eliminar(id) {
  return api.post("clientes/eliminar", { id });
}

function obtenerDivisa(id) {
  return api.post("clientes/obtenerDivisa", { id });
}

function comprobarClienteCompleto(ofertaBaseId) {
  return api.post("clientes/comprobarClienteCompleto", { ofertaBaseId });
}

export default {
  crear,
  listar,
  cargar,
  insertar,
  actualizar,
  eliminar,
  obtenerDivisa,
  comprobarClienteCompleto,
};
