import Ofertas from "@/views/estadisticas/Ofertas.vue";
import OfertasEntregadas from "@/views/estadisticas/OfertasEntregadas.vue";
import Modulos from "@/views/estadisticas/Modulos.vue";

export default [
  {
    path: "/estadisticas/ofertas",
    component: Ofertas,
  },
  {
    path: "/estadisticas/ofertasEntregadas",
    component: OfertasEntregadas,
  },
  {
    path: "/estadisticas/modulos",
    component: Modulos,
  },
];
