import session from "@/services/session.js";

const state = {
  usuarioId: null,
  nombre: "",
  esAdmin: null,
  esPartner: null,
  esResponsable: null,
  csrfToken: null,
  mostrarBannerDePruebas: null,
};

const mutations = {
  login(state, payload) {
    state.usuarioId = payload.usuarioId;
    state.nombre = payload.nombre;
    state.esAdmin = payload.esAdmin;
    state.esPartner = payload.esPartner;
    state.esResponsable = payload.esResponsable;
    state.csrfToken = payload.csrfToken;
    state.mostrarBannerDePruebas = payload.mostrarBannerDePruebas;
  },
  logout(state) {
    state.usuarioId = null;
    state.nombre = "";
    state.esAdmin = false;
    state.esPartner = false;
    state.esResponsable = false;
    state.csrfToken = null;
    state.mostrarBannerDePruebas = null;
  },
};

const actions = {
  async login({ commit }, [email, clave]) {
    const currentSession = await session.login(email, clave);
    commit("login", currentSession);
  },
  async logout({ commit }) {
    await session.logout();
    commit("logout");
  },
  async recover({ commit }) {
    const currentSession = await session.recover();
    commit("login", currentSession);
    return true;
  },
};

const getters = {
  loggedIn: (state) => {
    return state.usuarioId > 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
