import api from "./backend";

function crear() {
  return {
    id: 0,
    descripcion: "",
    uso: "1",
    descuento: "",
    dtoUsuario: "",
    estado: null,
  };
}

function listar() {
  return api.get("tiposDescuento/listar").then((r) => r.data);
}

function listarActivos() {
  return api.get("tiposDescuento/listarActivos").then((r) => r.data);
}

function listarPropios() {
  return api.get("tiposDescuento/listarPropios").then((r) => r.data);
}

function cargar(id) {
  return api.get("tiposDescuento/cargar&id=" + id).then((response) => {
    var data = response.data.tipoDescuento;

    data.dtoUsuario = Boolean(data.dtoUsuario);
    data.estado = Boolean(data.estado);
    return data;
  });
}

function insertar(descuento) {
  return api.post("tiposDescuento/insertar", {
    tipoDescuento: descuento.descuento,
  });
}

function actualizar(descuento) {
  return api.post("tiposDescuento/actualizar", {
    tipoDescuento: descuento.descuento,
  });
}

function eliminar(id) {
  return api.post("tiposDescuento/eliminar", { id });
}

export default { crear, listar, listarActivos, listarPropios, cargar, insertar, actualizar, eliminar };
