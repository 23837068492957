<template>
  <page>
    <b-form @submit.prevent="guardar" class="formularioContrato container">
      <div class="content-card">
        <h1>Editar contrato de la Oferta</h1>

        <b-tabs content-class="mt-3" small-card>
          <b-tab title="Contrato" active>
            <b-form-group label="Formato del contrato a generar">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContrato.textoContrato" :options="opcionesEditor" />
            </b-form-group>
          </b-tab>
          <b-tab title="Borrador">
            <b-form-group label="Formato del contrato en borrador">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContrato.textoBorrador" :options="opcionesEditor" />
            </b-form-group>
          </b-tab>
        </b-tabs>

        <botonera-edicion url-cancelar="/ofertas" />
      </div>
    </b-form>
  </page>
</template>

<script>
import apiOfertas from "@/services/ofertas.js";
import EditorShortcodes from "@/components/editor/ModuloShortcodesContrato.vue";

export default {
  data() {
    return {
      ofertaContrato: apiOfertas.crearOfertaContrato(),
      opcionesEditor: {
        customModules: [EditorShortcodes],
        forcePlainTextOnPaste: true,
      },
    };
  },
  methods: {
    async guardar() {
      await apiOfertas.guardarContratoVersion(this.ofertaContrato);
      this.$router.push("/ofertas");
    },
  },
  async mounted() {
    this.ofertaContrato = await apiOfertas.cargarContratoVersion(this.$route.params.id);
  },
};
</script>
