<template>
  <page-edicion title="Editar Unidad de Negocio">
    <formulario
      :unidadNegocio="unidadNegocio"
      @submit="actualizar"
      :paisesId="paisesId"
      :tarifa="tarifa"
      :opcionesPaises="opcionesPaises"
      :opcionesTarifas="opcionesTarifas"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/unidadesNegocio.js";
import apiPaises from "@/services/paises.js";
import apiTarifas from "@/services/tarifas.js";

export default {
  components: { Formulario },
  data() {
    return {
      unidadNegocio: {},
      paises: [],
      paisesId: [],
      opcionesPaises: [],
      tarifas: [],
      tarifa: apiTarifas.crear(),
      opcionesTarifas: [],
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.unidadNegocio = response.unidadNegocio;
        this.paises = response.paises;
        var p = {};
        for (p in this.paises) {
          apiPaises.cargar(this.paises[p].paisId).then((response) => {
            this.paisesId.push(response.pais.id); //array id paises para v-model
            //array opciones nombre pais para opciones selected combo multiple
            this.opcionesPaises.push({
              id: response.pais.id,
              text: response.pais.nombre,
            });
          });
        }
        apiTarifas.cargar(this.unidadNegocio.tarifaId).then((response) => {
          this.tarifa = response;
          //array opciones nombre tarifa para opciones selected combo
          this.opcionesTarifas.push({
            id: response.id,
            text: response.nombre,
          });
        });
      });
    },
    actualizar(paisesSeleccionados) {
      if (paisesSeleccionados.length > 0) {
        this.paises = paisesSeleccionados;
      }

      var unidadNegocio = {
        unidadNegocio: this.unidadNegocio,
        paises: this.paises,
      };
      api.actualizar(unidadNegocio).then(() => {
        this.$router.push({ path: "/unidadesNegocio" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
