<template>
  <page-edicion title="Crear país">
    <formulario :pais="pais" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiPaises from "@/services/paises.js";

export default {
  components: { Formulario },
  data() {
    return {
      pais: apiPaises.crear(),
    };
  },
  methods: {
    insertar() {
      apiPaises.insertar(this.pais).then(() => {
        this.$root.$emit("userMessage", "Se ha actualizado el país");
        this.$router.push({ path: "/paises" });
      });
    },
  },
};
</script>
