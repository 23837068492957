import Listar from "@/views/contratosCliente/Listar.vue";
import Crear from "@/views/contratosCliente/Crear.vue";
import Editar from "@/views/contratosCliente/Editar.vue";
import ListarContratosCliente from "@/views/contratosCliente/ListarContratosCliente.vue";

export default [
  {
    path: "/contratos",
    component: Listar,
  },
  {
    path: "/contratos/crear",
    component: Crear,
  },
  {
    path: "/contratos/editar/:id",
    component: Editar,
  },
  {
    path: "/contratos/cliente/:id",
    component: ListarContratosCliente,
  },
];
