<template>
  <b-modal id="modal-formaPago" title="Forma de Pago" @ok="aceptar">
    <b-form ref="frm" @submit.stop.prevent="aceptar">
      <b-form-group label="Tipo de proyecto" v-if="!calculada">
        <b-form-select required v-model="tipoProyectoElegido" :options="opcionesTipoProyecto"> </b-form-select>
      </b-form-group>

      <b-form-group :label="etiquetaParaFormaPago">
        <b-form-select required v-model="formaPagoElegida" :options="combo"> </b-form-select>
      </b-form-group>

      <b-form-group label="Forma de Pago de la Cuota">
        <b-form-select required v-model="tipoCuotaElegida">
          <b-form-select-option disabled selected value="">
            Seleccione la Forma de Pago de la Cuota
          </b-form-select-option>
          <b-form-select-option v-for="fp in opcionesTipoCuota" :key="fp.value" :value="fp.value">
            {{ fp.text }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import TipoProyecto from "@/enums/TipoProyecto";
import TipoCuota from "@/enums/TipoCuota";

export default {
  props: {
    ofertaFormaPago: Object,
    duracionFormaDePago: Number,
    calculada: Boolean,
  },
  data() {
    return {
      opcionesTipoProyecto: TipoProyecto.combo,
      opcionesTipoCuota: TipoCuota.combo.sort((a, b) => {
        if (a.value == 2) {
          return 1;
        } else {
          if (a.value > b.value) {
            return -1;
          } else {
            return 1;
          }
        }
      }),
    };
  },
  computed: {
    ...mapGetters("formaPago", ["combo"]),
    esSaas() {
      return this.tipoProyectoElegido == TipoProyecto.SAAS;
    },
    etiquetaParaFormaPago() {
      if (this.esSaas) {
        return "Servicios";
      } else {
        return "Suscripción e implementación";
      }
    },
    tipoProyectoElegido: {
      get() {
        return this.ofertaFormaPago.tipoProyecto;
      },
      set(value) {
        this.ofertaFormaPago.tipoProyecto = value;
      },
    },
    tipoCuotaElegida: {
      get() {
        return this.ofertaFormaPago.tipoCuota;
      },
      set(value) {
        this.ofertaFormaPago.tipoCuota = value;
      },
    },
    formaPagoElegida: {
      get() {
        if (this.ofertaFormaPago.tipoProyecto == TipoProyecto.SAAS) {
          return this.ofertaFormaPago.formaPagoImplementacionId;
        } else {
          return this.ofertaFormaPago.formaPagoSuscripcionId;
        }
      },
      set(value) {
        if (this.ofertaFormaPago.tipoProyecto == TipoProyecto.SAAS) {
          this.ofertaFormaPago.formaPagoImplementacionId = value;
        } else {
          this.ofertaFormaPago.formaPagoSuscripcionId = value;
        }
      },
    },
  },
  methods: {
    aceptar(event) {
      if (!this.$refs.frm.checkValidity()) {
        this.$refs.frm.reportValidity();
        event.preventDefault();
        return false;
      }

      this.$emit("ok", {
        tipoProyecto: this.tipoProyectoElegido,
        formaPagoSuscripcionId: this.formaPagoElegida,
        formaPagoImplementacionId: this.formaPagoElegida,
        tipoCuota: this.tipoCuotaElegida,
      });
    },
  },
  watch: {
    duracionFormaDePago: function () {
      let opciones = TipoCuota.duracionesContrato[this.duracionFormaDePago];
      const tiposCuota = TipoCuota.combo.map((x) => x);

      for (let index = tiposCuota.length - 1; index > -1; index--) {
        const opcion = tiposCuota[index];
        if (opciones !== undefined && !opciones.includes(parseInt(opcion.value))) {
          tiposCuota.splice(index, 1);
        }
      }

      this.opcionesTipoCuota = tiposCuota.sort((a, b) => {
        if (a.value == 2) {
          return 1;
        } else {
          if (a.value > b.value) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    },
  },
};
</script>
