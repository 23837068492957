<template>
  <div class="filtros">
    <b-form @submit.prevent="filtrar" inline>
      <b-container fluid class="p-0">
        <b-row>
          <b-col>
            <legend class="col bv-no-focus-ring col-form-label pl-0">Vencimiento</legend>
            <v-select2
              :options="opcionesVencimiento"
              :value="vencimiento"
              v-model="vencimiento"
              :settings="comboSettings"
            >
            </v-select2>
          </b-col>
          <b-col>
            <b-form-group label="Clientes" content-cols-sm="12">
              <combo-multiple
                class="input-mw filter"
                url="clientes/autocompletarPropios"
                @selected="actualizar($event, clientes)"
                :value="clientes"
                :options="opcionesClientes"
              ></combo-multiple>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Países" content-cols-sm="12">
              <combo-multiple
                class="input-mw filter"
                url="paises/autocompletarPropios"
                @selected="actualizar($event, paises)"
                :value="paises"
                :options="opcionesPaises"
              ></combo-multiple>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Campañas" content-cols-sm="12">
              <combo-multiple
                class="input-mw filter"
                url="campanias/autocompletar"
                @selected="actualizar($event, campanias)"
                :value="campanias"
                :options="opcionesCampanias"
              ></combo-multiple>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estados" content-cols-sm="12">
              <select2-multiple-control
                v-model="estados"
                :options="opcionesEstados"
                :settings="comboEstadosSettings"
              ></select2-multiple-control>
            </b-form-group>
          </b-col>
          <b-col v-if="this.esAdmin != 0">
            <b-form-group label="Propietarios" content-cols-sm="12">
              <combo-multiple
                class="input-mw filter"
                url="usuarios/autocompletar"
                @selected="actualizar($event, usuarios)"
                :value="usuarios"
                :options="opcionesUsuarios"
              ></combo-multiple>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Renovación" content-cols-sm="12">
              <date-picker
                class="filter"
                v-model="fechaRenovacion"
                format="MM-YYYY"
                type="month"
                placeholder="Elija fecha"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Alta" content-cols-sm="12">
              <date-picker
                class="filter"
                v-model="fechaAlta"
                format="YYYY"
                type="year"
                placeholder="Elija fecha"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { formatDateForm } from "@/services/date";
import apiPaises from "@/services/paises.js";
import apiClientes from "@/services/clientes.js";
import apiCampanias from "@/services/campanias.js";
import apiUsuarios from "@/services/usuarios.js";
import EstadoContrato from "@/enums/EstadoContrato";
import VencimientoContrato from "@/enums/VencimientoContrato";

export default {
  props: {
    filtros: Object,
  },
  data() {
    return {
      paises: [],
      clientes: [],
      campanias: [],
      usuarios: [],
      estados: "",
      fechaRenovacion: null,
      fechaAlta: null,
      busqueda: "",
      vencimiento: "",
      opcionesPaises: [],
      opcionesClientes: [],
      opcionesCampanias: [],
      opcionesUsuarios: [],
      comboSettings: {
        placeholder: "Elija vencimiento",
        theme: "bootstrap",
        language: "es",
        width: "100%",
        templateResult: this.formatVencimiento,
        minimumResultsForSearch: -1,
        escapeMarkup: function (markup) {
          return markup;
        },
        dropdownAutoWidth: true,
        allowClear: true,
      },
      comboEstadosSettings: {
        placeholder: "Elija estados",
        theme: "bootstrap",
        language: "es",
        width: "100%",
        minimumResultsForSearch: -1,
        escapeMarkup: function (markup) {
          return markup;
        },
        dropdownAutoWidth: true,
        allowClear: true,
      },
    };
  },
  computed: {
    ...mapState("session", ["esAdmin", "usuarioId"]),
    opcionesEstados() {
      return [
        { id: EstadoContrato.PENDIENTE, text: EstadoContrato.nombres[EstadoContrato.PENDIENTE] },
        { id: EstadoContrato.RENOVADO, text: EstadoContrato.nombres[EstadoContrato.RENOVADO] },
        { id: EstadoContrato.CANCELADO, text: EstadoContrato.nombres[EstadoContrato.CANCELADO] },
        { id: EstadoContrato.ENPROCESO, text: EstadoContrato.nombres[EstadoContrato.ENPROCESO] },
        { id: EstadoContrato.INICIAL, text: EstadoContrato.nombres[EstadoContrato.INICIAL] },
      ];
    },
    opcionesVencimiento() {
      return VencimientoContrato.combo;
    },
  },
  methods: {
    filtrar() {
      let fechaRenovacionFormateada;
      let fechaAltaFormateada;
      let usuariosFiltrados = [];

      if (
        this.fechaRenovacion == null ||
        (this.fechaRenovacion instanceof Date && isNaN(this.fechaRenovacion.valueOf()))
      ) {
        fechaRenovacionFormateada = "";
      } else {
        fechaRenovacionFormateada = formatDateForm(this.fechaRenovacion);
      }

      if (this.fechaAlta == null || (this.fechaAlta instanceof Date && isNaN(this.fechaAlta.valueOf()))) {
        fechaAltaFormateada = "";
      } else {
        fechaAltaFormateada = formatDateForm(this.fechaAlta);
      }

      if (!this.esAdmin) {
        usuariosFiltrados.push(this.usuarioId);
      } else {
        usuariosFiltrados = this.usuarios;
      }

      this.$emit("filtrar", {
        paises: this.paises,
        clientes: this.clientes,
        campanias: this.campanias,
        usuarios: usuariosFiltrados,
        estados: this.estados,
        fechaRenovacion: fechaRenovacionFormateada,
        fechaAlta: fechaAltaFormateada,
        busqueda: this.busqueda,
        vencimiento: this.vencimiento,
      });
    },
    actualizar(value, arr) {
      var indx = null;
      indx = this.obtenerIndex(value, arr);
      if (indx == null) {
        Vue.set(arr, arr.length, value.id);
      } else {
        Vue.delete(arr, indx);
      }
    },
    obtenerIndex(value, arrayElem) {
      for (var el in arrayElem) {
        var elem = arrayElem[el];
        if (elem == value.id) {
          return arrayElem.indexOf(elem);
        }
      }
    },
    cargarDatosFiltros() {
      this.paises =
        typeof this.filtros.paises != "undefined"
          ? this.filtros.paises instanceof Array
            ? this.filtros.paises
            : [parseInt(this.filtros.paises)]
          : [];
      for (var p in this.paises) {
        apiPaises.cargar(this.paises[p]).then((response) => {
          // array opciones nombre pais para opciones selected combo multiple
          this.opcionesPaises.push({
            id: response.pais.id,
            text: response.pais.nombre,
          });
        });
      }

      this.clientes =
        typeof this.filtros.clientes != "undefined"
          ? this.filtros.clientes instanceof Array
            ? this.filtros.clientes
            : [parseInt(this.filtros.clientes)]
          : [];
      for (var c in this.clientes) {
        apiClientes.cargar(this.clientes[c]).then((response) => {
          // array opciones nombre cliente para opciones selected combo multiple
          this.opcionesClientes.push({
            id: response.cliente.id,
            text: response.cliente.nombre,
          });
        });
      }

      this.campanias =
        typeof this.filtros.campanias != "undefined"
          ? this.filtros.campanias instanceof Array
            ? this.filtros.campanias
            : [parseInt(this.filtros.campanias)]
          : [];
      for (var c in this.campanias) {
        apiCampanias.cargar(this.campanias[c]).then((response) => {
          // array opciones nombre campania para opciones selected combo multiple
          this.opcionesCampanias.push({
            id: response.campania.id,
            text: response.campania.nombre,
          });
        });
      }

      this.usuarios = this.filtros.usuarios;
      this.usuarios =
        typeof this.filtros.usuarios != "undefined"
          ? this.filtros.usuarios instanceof Array
            ? this.filtros.usuarios
            : [parseInt(this.filtros.usuarios)]
          : [];
      if (this.esAdmin) {
        for (var c in this.usuarios) {
          apiUsuarios.cargar(this.usuarios[c]).then((response) => {
            // array opciones nombre usuario para opciones selected combo multiple
            this.opcionesUsuarios.push({
              id: response.usuario.id,
              text: response.usuario.nombre,
            });
          });
        }
      }
      this.fechaRenovacion = this.filtros.fechaRenovacion != "undefined" ? new Date(this.filtros.fechaRenovacion) : "";
      this.fechaAlta = this.filtros.fechaAlta != "undefined" ? new Date(this.filtros.fechaAlta) : "";
      this.busqueda = this.filtros.busqueda;
      this.vencimiento = this.filtros.vencimiento;
      this.estados = this.filtros.estados;
    },
    formatVencimiento(vencimiento) {
      if (!vencimiento.id) {
        return vencimiento.text;
      }
      var vencimiento =
        "<span><svg width='20' height='20'><rect x='0' y='0' rx='0' ry='0' width='20' height='20' style='fill: " +
        vencimiento.color +
        "; stroke: black; stroke-width: 1; opacity: 1'/></svg> " +
        vencimiento.text +
        "</span>";
      return vencimiento;
    },
  },
  mounted() {
    this.cargarDatosFiltros();
  },
  watch: {
    vencimiento: function () {
      this.filtrar();
    },
    clientes: function () {
      this.filtrar();
    },
    paises: function () {
      this.filtrar();
    },
    campanias: function () {
      this.filtrar();
    },
    estados: function () {
      this.filtrar();
    },
    usuarios: function () {
      this.filtrar();
    },
    fechaRenovacion: function () {
      this.filtrar();
    },
    fechaAlta: function () {
      this.filtrar();
    },
  },
};
</script>
