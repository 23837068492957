<template>
  <page-edicion title="Editar plantilla">
    <formulario
      :plantillaContrato="plantillaContrato"
      :unidadesNegocioId="unidadesNegocioId"
      :opcionesUnidadesNegocio="opcionesUnidadesNegocio"
      @submit="actualizar"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiUnidadesNegocio from "@/services/unidadesNegocio.js";
import api from "@/services/plantillasContrato.js";

export default {
  components: { Formulario },
  data() {
    return {
      plantillaContrato: {},
      unidadesNegocioId: [],
      opcionesUnidadesNegocio: [],
      unidadesNegocio: [],
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.plantillaContrato = response.plantillaContrato;
        this.unidadesNegocio = response.unidadesNegocio;
        var un = {};
        for (un in this.unidadesNegocio) {
          apiUnidadesNegocio.cargar(this.unidadesNegocio[un].unidadNegocioId).then((response) => {
            this.unidadesNegocioId.push(response.unidadNegocio.id); //array id unidadesNegocio para v-model
            //array opciones nombre unidad de Negocio para opciones selected combo multiple
            this.opcionesUnidadesNegocio.push({
              id: response.unidadNegocio.id,
              text: response.unidadNegocio.nombreComercial,
            });
          });
        }
      });
    },
    actualizar(unidadesNegocioSeleccionadas) {
      if (unidadesNegocioSeleccionadas.length > 0) {
        this.unidadesNegocio = unidadesNegocioSeleccionadas;
      }
      var plantilla = {
        plantillaContrato: this.plantillaContrato,
        unidadesNegocio: this.unidadesNegocio,
      };
      api.actualizar(plantilla).then(() => {
        this.$router.push({ path: "/plantillasContrato" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
