import { listado } from "./listado";
import api from "./backend";
import { param } from "jquery";

export async function modificadorPrecio(duracionContratoId, tipoCuota) {
  const response = await api.get(
    "modificadorPrecioModulo/modificadorPrecio&duracionContratoId=" + duracionContratoId + "&tipoCuota=" + tipoCuota
  );
  return response.data;
}

export function actualizarPrecioModulos(params) {
  return api
    .post("modificadorPrecioModulo/actualizarPreciosModulos", {
      divisaId: params.divisaId,
      planId: params.planId,
      duracionContratoId: params.duracionContratoId,
      tipoCuota: params.tipoCuota,
      ofertaModulos: params.ofertaModulos,
      modulos: params.modulos,
      ofertaId: params.ofertaId,
      paisId: params.paisId,
      descuentoFirma: params.descuentoFirma,
      tarifaId: params.tarifaId,
    })
    .then((response) => {
      var modulosActualizados = response.data;

      return modulosActualizados;
    });
}

export default {
  modificadorPrecio,
  actualizarPrecioModulos,
};
