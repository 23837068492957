<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input required type="text" v-model="pais.nombre" placeholder="Nombre del país"></b-form-input>
    </b-form-group>
    <b-form-group label="Código">
      <b-form-input
        required
        type="text"
        v-model="pais.alpha2"
        maxlength="2"
        placeholder="Código alpha2 del país"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Nombre de identificación tributaria">
      <b-form-input
        required
        type="text"
        v-model="pais.nombreIdentificacionTributaria"
        maxlength="20"
        placeholder="CIF/VAT/NIT/NITE/RUF..."
      ></b-form-input>
    </b-form-group>
    <botonera-edicion url-cancelar="/paises" />
  </b-form>
</template>
<script>
export default {
  props: {
    pais: Object,
  },
};
</script>
