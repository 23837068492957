<template>
  <page-edicion :title="`Módulo ${modulo.nombre}`" v-if="this.ready">
    <formulario-planes-horas
      :moduloPlanHorasNormalizados="horasNormalizadas"
      :planes="planes"
      @submit="actualizar"
    ></formulario-planes-horas>
  </page-edicion>
</template>

<script>
import FormularioPlanesHoras from "./FormularioPlanesHoras.vue";
import * as apiModulos from "@/services/modulos.js";
import apiPlanes from "@/services/planes.js";

function completarHorasNormalizados(id, planes, modulosPlanHoras) {
  for (var plan of planes) {
    if (!modulosPlanHoras[plan.id]) {
      modulosPlanHoras[plan.id] = {
        moduloId: id,
        numHorasCapacitacion: 0,
        numHorasConsultoria: 0,
      };
    }
  }
  return modulosPlanHoras;
}
export default {
  components: { FormularioPlanesHoras },
  data() {
    return {
      ready: false,
      moduloId: 0,
      modulo: {},
      planes: [],
      horas: [],
      horasNormalizadas: {},
    };
  },
  methods: {
    async cargar(moduloId) {
      this.modulo = await apiModulos.cargar(moduloId);
      this.horas = await apiModulos.cargarHoras(moduloId);
    },
    async actualizar() {
      await apiModulos.actualizarHoras(this.moduloId, this.horasNormalizadas);
      this.$router.push({ path: "/modulos" });
    },
    async listarPlanes() {
      this.planes = await apiPlanes.listar();
    },
  },
  async mounted() {
    this.moduloId = this.$route.params.id;
    await Promise.all([this.cargar(this.moduloId), this.listarPlanes()]);
    this.horasNormalizadas = completarHorasNormalizados(this.moduloId, this.planes, this.horas);
    this.ready = true;
  },
};
</script>
