import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
  };
}

function listar(params) {
  return listado("planes/listar", params);
}

function cargar(id) {
  return api.get("planes/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(plan) {
  return api.post("planes/insertar", { plan });
}

function actualizar(plan) {
  return api.post("planes/actualizar", { plan });
}

function eliminar(id) {
  return api.post("planes/eliminar", { id });
}

export default { crear, listar, cargar, insertar, actualizar, eliminar };
