import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    email: "",
    clave: "",
    telefono: "",
    movil: "",
    cargo: "",
    esAdmin: null,
    esPartner: null,
    esRevisor: null,
    bloqueado: null,
  };
}

function listar(params) {
  return listado("usuarios/listar", params);
}

function cargar(id) {
  return api.get("usuarios/cargar&id=" + id).then((response) => {
    var data = response.data;

    data.usuario.bloqueado = Boolean(data.usuario.bloqueado);
    data.usuario.esPartner = Boolean(data.usuario.esPartner);
    data.usuario.esAdmin = Boolean(data.usuario.esAdmin);
    data.usuario.esRevisor = Boolean(data.usuario.esRevisor);

    return data;
  });
}

function cargarPropio(id) {
  return api.get("usuarios/cargarPropio").then((response) => {
    var data = response.data;

    data.usuario.bloqueado = Boolean(data.usuario.bloqueado);
    data.usuario.esPartner = Boolean(data.usuario.esPartner);
    data.usuario.esAdmin = Boolean(data.usuario.esAdmin);
    data.usuario.esRevisor = Boolean(data.usuario.esRevisor);

    return data;
  });
}

function insertar({ usuario, imagenes, paisesResponsable, paisesComercial, unidadesNegocio, descuentos }) {
  return api.post("usuarios/insertar", {
    usuario,
    imagenes,
    paisesResponsable,
    paisesComercial,
    unidadesNegocio,
    descuentos,
  });
}

function actualizar({ usuario, imagenes, paisesResponsable, paisesComercial, unidadesNegocio, descuentos }) {
  return api.post("usuarios/actualizar", {
    usuario,
    imagenes,
    paisesResponsable,
    paisesComercial,
    unidadesNegocio,
    descuentos,
  });
}

function eliminar(id) {
  return api.post("usuarios/eliminar", { id });
}

export default {
  crear,
  listar,
  cargar,
  insertar,
  actualizar,
  eliminar,
  cargarPropio,
};
