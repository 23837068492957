<template>
  <page-listado title="Histórico Versiones" con-busqueda>
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/ofertas/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear oferta
      </b-button>
    </template>

    <tabla-ofertas :ofertas="resultado.ofertas">
      <template v-slot:botonera="{ oferta }">
        <botonera-version @ofertaCambiada="listar" :oferta="oferta"></botonera-version>
      </template>
    </tabla-ofertas>

    <paginacion :page-count="parseInt(resultado.numeroPaginas)" :page="parseInt(resultado.pagina)"></paginacion>
  </page-listado>
</template>

<script>
import apiOfertas from "@/services/ofertas.js";
import TablaOfertas from "./TablaOfertas.vue";
import BotoneraVersion from "./BotoneraVersion.vue";

export default {
  components: { TablaOfertas, BotoneraVersion },
  data() {
    return {
      resultado: {
        ofertas: [],
        numeroPaginas: 0,
        pagina: 1,
      },
      reloadListar: false,
    };
  },
  methods: {
    listar() {
      return apiOfertas.listarVersionesDe(this.$route.params.id, this.$route.query).then((resultado) => {
        this.resultado = resultado;
      });
    },
    mensaje(mensaje) {
      this.$emit("userMessage", mensaje);
      this.listar();
      //this.reloadListar = true;
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
