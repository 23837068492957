<template>
  <page-edicion title="Crear origen de oferta">
    <formulario :origenOferta="origenOferta" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiOrigenOferta from "@/services/origenOferta.js";

export default {
  components: { Formulario },
  data() {
    return {
      origenOferta: apiOrigenOferta.crear(),
    };
  },
  methods: {
    insertar() {
      apiOrigenOferta.insertar(this.origenOferta).then(() => {
        this.$emit("userMessage", "Se ha creado el registro de origen oferta");
        this.$router.push({ path: "/origenOferta" });
      });
    },
  },
};
</script>
