import api from "./backend";

export async function listarEditorShortcode(editor) {
  const response = await api.get("editorShortcode/listar&editor=" + editor);
  return response.data;
}

export async function listarEditorShortcodeAgrupado(editor) {
  const response = await api.get("editorShortcode/listarAgrupado&editor=" + editor);
  return response.data;
}

export default {
  listarEditorShortcode,
  listarEditorShortcodeAgrupado,
};
