<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado class="text-center" titulo="Saas forzado" campo="forzarProyectoSaas"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <tr v-for="campania in campanias" v-bind:key="campania.id" role="button">
        <td @click="editarCampania(campania.id)">{{ campania.nombre }}</td>
        <td @click="editarCampania(campania.id)" class="text-center">{{ campania.forzarProyectoSaas | sino }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/campanias/editar/${campania.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', campania.id)">
              ¿ Desea eliminar la campaña <b>{{ campania.nombre }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="!campanias.length">
      <sin-registros :colspan="4"></sin-registros>
    </tfoot>
  </table>
</template>
<script>
export default {
  props: {
    campanias: Array,
  },
  methods: {
    editarCampania(campaniaId) {
      this.$router.push("/campanias/editar/" + campaniaId);
    },
  },
};
</script>
