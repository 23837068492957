<template>
  <div>
    <b-row class="mb-0">
      <b-col lg="12">
        <h2 class="pb-1">Forma de pago</h2>
      </b-col>
    </b-row>
    <b-row class="mb-1 border-bottom pb-1" v-if="!oferta.calculada">
      <b-col md="4"><label>Tipo de proyecto</label></b-col>
      <b-col>
        {{ nombreTipoProyecto }}
      </b-col>
      <b-col md="3">
        <b-button
          class="pull-right bbutton-mini"
          v-if="oferta.borrador && !oferta.caducada"
          variant="primary"
          size="sm"
          v-b-modal.modal-formaPago
        >
          <v-icon variant="primary" name="edit" width="16" /> Editar
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-1 pt-1 border-bottom pb-1">
      <b-col md="4"><label>Suscripción y servicios</label></b-col>
      <b-col>
        {{ nombreFormaPago }}
      </b-col>
      <b-col md="3" v-if="oferta.calculada">
        <b-button
          class="pull-right bbutton-mini"
          v-if="oferta.borrador && !oferta.caducada"
          variant="primary"
          size="sm"
          v-b-modal.modal-formaPago
        >
          <v-icon variant="primary" name="edit" width="16" /> Editar
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-1 pt-1 border-bottom pb-1">
      <b-col md="4"><label>Cuota</label></b-col>
      <b-col>
        {{ nombreTipoCuota }}
      </b-col>
    </b-row>
    <modal-forma-pago
      :ofertaFormaPago="value"
      @ok="aceptar"
      :duracionFormaDePago="duracionFormaDePago"
      :calculada="oferta.calculada"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalFormaPago from "./ModalFormaPago.vue";
import TipoProyecto from "@/enums/TipoProyecto";
import TipoCuota from "@/enums/TipoCuota";

export default {
  props: {
    oferta: { type: Object, required: true },
    value: {
      type: Object,
      required: true,
    },
    duracionFormaDePago: Number,
  },
  components: {
    ModalFormaPago,
  },
  computed: {
    nombreTipoProyecto() {
      return TipoProyecto.nombres[this.value.tipoProyecto];
    },
    nombreTipoCuota() {
      return TipoCuota.nombres[this.value.tipoCuota];
    },
    nombreFormaPago() {
      var fp =
        this.value.tipoProyecto == TipoProyecto.SAAS
          ? this.value.formaPagoImplementacionId
          : this.value.formaPagoSuscripcionId;
      return this.$store.getters["formaPago/nombre"](fp);
    },
  },
  data() {
    return {};
  },
  methods: {
    aceptar(nueva) {
      this.$emit("input", nueva);
    },
  },
  watch: {
    duracionFormaDePago: function () {
      let opciones = TipoCuota.duracionesContrato[this.duracionFormaDePago];
      const tiposCuota = TipoCuota.combo.map((x) => x);

      for (let index = tiposCuota.length - 1; index > -1; index--) {
        const opcion = tiposCuota[index];
        if (opciones !== undefined && !opciones.includes(parseInt(opcion.value))) {
          tiposCuota.splice(index, 1);
        }
      }
      let existeCuotaEnDuracion = false;

      for (let index = 0; index < tiposCuota.length; index++) {
        if (tiposCuota[index].value == parseInt(this.value.tipoCuota)) {
          existeCuotaEnDuracion = true;
        }
      }
      if (!existeCuotaEnDuracion) {
        this.value.tipoCuota = 1;
      }
    },
  },
};
</script>
