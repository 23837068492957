<template>
  <page-listado title="Divisas" :fluid="true">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/divisas/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear divisa
      </b-button>
    </template>
    <tabla :divisas="divisas" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import apiDivisas from "@/services/divisas.js";
import Tabla from "./TablaDivisas.vue";

export default {
  components: { Tabla },
  data() {
    return {
      divisas: [],
    };
  },
  methods: {
    async listar() {
      this.divisas = await apiDivisas.listar(this.$route.query);
    },
    async eliminar(id) {
      await apiDivisas.eliminar(id);
      this.listar();
    },
  },
  mounted() {
    this.listar();
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
