<template>
  <div>
    <div class="busqueda">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Divisa" label-cols-lg="4">
            <b-form-select v-model="divisa" :options="divisas"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Pais" label-cols-lg="4">
            <b-form-select v-model="pais" :options="paises"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form @submit.prevent="$emit('submit')">
      <table class="listado table TablaModulosEdicionPrecios">
        <thead valign="middle" class="bg-primary text-white">
          <th-ordenado titulo="Divisa" campo="divisa">Divisa</th-ordenado>
          <th-ordenado titulo="País" campo="pais">País</th-ordenado>
          <th width="200">Precio H. Capacitación</th>
          <th width="200">Precio H. Consultoría</th>
        </thead>
        <tbody halign="middle">
          <tr v-for="(precioHora, idx) in preciosHoraFiltrados" v-bind:key="idx">
            <td align="left">{{ precioHora.divisa }}</td>
            <td align="left">{{ precioHora.pais }}</td>
            <td align="right">
              <b-form-input type="number" v-model="precioHora.precioHoraCapacitacion" placeholder="0"></b-form-input>
            </td>
            <td align="right">
              <b-form-input type="number" v-model="precioHora.precioHoraConsultoria" placeholder="0"></b-form-input>
            </td>
          </tr>
        </tbody>
      </table>
      <botonera-edicion :ocultarCancelar="ocultarCancelar" url-cancelar="/preciosHora" />
    </b-form>
  </div>
</template>

<script>
/**
 * Dado un conjunto de elementos repetidos, devuelve un combo
 * de elementos únicos listo para un <b-select>
 */
function makeCombo(elements) {
  return ["", ...new Set(elements)]
    .map((r) => {
      return {
        text: r,
        value: r,
      };
    })
    .sort();
}

export default {
  computed: {
    divisas() {
      return makeCombo(this.preciosHora.map((r) => r.divisa));
    },
    paises() {
      return makeCombo(this.preciosHora.map((r) => r.pais));
    },
    preciosHoraFiltrados() {
      return this.preciosHora.filter((p) => {
        if (this.divisa && this.divisa != p.divisa) {
          return false;
        }
        if (this.pais && this.pais != p.pais) {
          return false;
        }
        return true;
      });
    },
  },
  props: {
    preciosHora: Array,
  },
  data() {
    return {
      pais: null,
      divisa: null,
      ocultarCancelar: true,
    };
  },
};
</script>
