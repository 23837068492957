<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!plantillas" :colspan="2"></sin-registros>
      <tr v-for="plantilla in plantillas" v-bind:key="plantilla.id" role="button">
        <td @click="editarPlantillaContenido(plantilla.id)">{{ plantilla.nombre }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/plantillasContenido/editar/${plantilla.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    plantillas: Array,
  },
  methods: {
    editarPlantillaContenido(plantillaContenidoId) {
      this.$router.push("/plantillasContenido/editar/" + plantillaContenidoId);
    },
  },
};
</script>
