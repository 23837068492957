<template>
  <div>
    <b-row class="mb-2 border-bottom pb-1">
      <b-col md="4"><label>Divisa*</label></b-col>
      <b-col>
        <span v-if="divisa">
          {{ divisa.abreviatura }}
        </span>
      </b-col>
      <b-col md="3">
        <b-button
          variant="primary"
          class="pull-right bbutton-mini"
          size="sm"
          v-b-modal.modal-divisa
          v-if="oferta.borrador && Boolean(divisas) && !oferta.caducada"
        >
          <v-icon variant="primary" name="edit" width="16" /> Editar
        </b-button>
      </b-col>
    </b-row>
    <modal-divisa :divisaId="value" :divisas="divisas" @ok="cambiar" />
  </div>
</template>

<script>
import ModalDivisa from "./ModalDivisa.vue";

export default {
  props: {
    oferta: { type: Object, required: true },
    value: Number,
    divisas: Array,
  },
  components: { ModalDivisa },
  computed: {
    divisa() {
      return this.divisas.find((d) => d.id == this.value);
    },
  },
  methods: {
    cambiar(divisaId) {
      if (this.value != divisaId) {
        this.$emit("input", divisaId);
      }
    },
  },
};
</script>
