<template>
  <b-button-group size="sm">
    <b-button variant="primary" class="actionButton" :to="`/ofertas/editar/${oferta.id}`"
      ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span></b-button
    >
    <b-dropdown size="sm" right text="" variant="dark" class="actionButton">
      <template slot="button-content">
        <v-icon variant="primary" name="menu" width="16" />
      </template>
      <b-dropdown-item size="sm" :to="`/ofertas/editarContenido/${oferta.id}`"
        ><v-icon variant="primary" name="file-text" width="16" /> Editar Contenido</b-dropdown-item
      >
      <b-dropdown-item size="sm" :to="`/ofertas/editarContenidoModulos/${oferta.id}`"
        ><v-icon variant="primary" name="message-square" width="16" /> Editar Módulos</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>

      <b-dropdown-item
        :disabled="!puedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeConsultor&ofertaId=${oferta.id}`"
      >
        <v-icon variant="primary" name="printer" width="16" />
        Informe Consultor
      </b-dropdown-item>

      <b-dropdown-item
        :disabled="!puedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeComercial&ofertaId=${oferta.id}`"
      >
        <v-icon variant="primary" name="printer" width="16" /> Informe Comercial
      </b-dropdown-item>

      <b-dropdown-item
        :disabled="!puedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeComercial&ofertaId=${oferta.id}&extendido=true`"
      >
        <v-icon variant="primary" name="printer" width="16" /> Informe Com. Extendido
      </b-dropdown-item>

      <b-dropdown-item
        :disabled="!esGanada"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeAdministracion&ofertaId=${oferta.id}`"
      >
        <v-icon variant="primary" name="printer" width="16" />
        Informe Administración
      </b-dropdown-item>
      <b-dropdown-divider v-if="puedeVerContrato"></b-dropdown-divider>
      <b-dropdown-item v-if="puedeVerContrato" size="sm" :to="`/ofertas/editarContrato/${oferta.id}`"
        ><v-icon variant="primary" name="file-text" width="16" /> Editar Contrato</b-dropdown-item
      >
      <b-dropdown-item
        v-if="puedeVerContrato"
        :disabled="false"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeContrato&ofertaId=${oferta.id}&borrador=true`"
        ><v-icon variant="primary" name="printer" width="16" /> Imprimir Borrador Contrato</b-dropdown-item
      >
      <b-dropdown-item
        v-if="puedeVerContratoAdmin"
        :disabled="false"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeContrato&ofertaId=${oferta.id}`"
        ><v-icon variant="primary" name="printer" width="16" /> Imprimir Contrato</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item size="sm" :to="`/ofertas/versiones/${oferta.ofertaBaseId}`"
        ><v-icon variant="primary" name="list" width="16" /> Histórico de Versiones</b-dropdown-item
      >
      <b-dropdown-item :disabled="!puedeVersionar" size="sm" @click="crearVersion(oferta.id, oferta.descripcion)"
        ><v-icon variant="primary" name="copy" width="16" /> Nueva Versión</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item :disabled="!puedeVisar" size="sm" @click="visarVersion(oferta.id, oferta.descripcion)"
        ><v-icon variant="primary" name="check" width="16" />

        Visar</b-dropdown-item
      >
      <b-dropdown-item :disabled="!puedeEntregar" size="sm" @click="entregarVersion(oferta.id, oferta.descripcion)"
        ><v-icon variant="primary" name="send" width="16" /> Entregar al cliente</b-dropdown-item
      >
      <b-dropdown-item :disabled="!puedeGanar" size="sm" @click="ganarVersion(oferta.id, oferta.descripcion)"
        ><v-icon variant="primary" name="thumbs-up" width="16" />

        Ganar</b-dropdown-item
      >
      <b-dropdown-item :disabled="!puedePerder" size="sm" @click="perderVersion(oferta.id, oferta.descripcion)"
        ><v-icon variant="primary" name="thumbs-down" width="16" />

        Perder</b-dropdown-item
      >
      <b-dropdown-item
        :disabled="!puedeEnviarPapelera"
        size="sm"
        variant="outline-danger"
        @click="enviarVersionPapelera(oferta.id, oferta.descripcion)"
        ><v-icon variant="danger" name="trash-2" width="16" />

        Enviar a Papelera</b-dropdown-item
      >
      <b-dropdown-divider v-show="oferta.pipedrive"></b-dropdown-divider>
      <b-dropdown-item size="sm" target="_blank" v-show="oferta.pipedrive" :href="oferta.pipedrive"
        ><v-icon variant="primary" name="database" width="16" /> Abrir en Pipedrive</b-dropdown-item
      >
    </b-dropdown>
    <b-modal :id="`modalGanar-${oferta.id}`"
      >Los datos de cliente asociados a esta oferta no están completos. ¿Quiere acceder a la ficha y completarlos?
      <template slot="modal-header"><h3>No se puede ganar la oferta</h3></template>
      <template slot="modal-footer">
        <b-button @click="$bvModal.hide(`modalGanar-${oferta.clienteId}`)">Cancelar</b-button>
        <b-button variant="primary" @click="verCliente()">Ir al cliente</b-button></template
      ></b-modal
    >
    <b-modal :id="`modalFechaGanar-${oferta.id}`" hide-footer>
      <template slot="modal-header"><h3>Ganar oferta</h3></template>
      <b-form ref="form" @submit.prevent="submit(oferta.id, oferta.descripcion)">
        <b-row>
          <b-col>
            <b-form-group label="Indique fecha ganada">
              <b-form-datepicker
                locale="es-ES"
                required
                v-model="oferta.ganadaEl"
                :start-weekday="1"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="oferta.calculada">
          <b-col>
            <b-form-group label="Añadir Orden de compra">
              <b-form-file
                ref="file"
                :placeholder="placeholder"
                v-bind="$attrs"
                v-on="$listeners"
                accept=""
                :drop-placeholder="dropText"
                :browse-text="browseText"
                @input="actualizarOrdenCompra"
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="tieneUnidadNegocio">
          <b-col>
            <b-form-group>
              <b-form-checkbox switch size="lg" v-model="cartaBienvenida">
                <small class="mt-2">Enviar Carta de bienvenida</small>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <footer class="modal-footer">
          <b-button @click="$bvModal.hide(`modalFechaGanar-${oferta.id}`)">Cancelar</b-button>
          <b-button variant="primary" :disabled="oferta.ganadaEl == undefined" type="submit">Ganar</b-button>
        </footer>
      </b-form>
    </b-modal>
  </b-button-group>
</template>
<script>
import { mapState } from "vuex";
import apiClientes from "@/services/clientes";
import apiOfertas from "@/services/ofertas";
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Elija uno documento y arrástrelo aquí...",
    },
    browseText: {
      type: String,
      default: "Explorar",
    },
    dropText: {
      type: String,
      default: "Suelte aquí el documento...",
    },
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    puedeImprimir() {
      return true;
    },
    puedeVersionar() {
      return true;
    },
    puedeVisar() {
      return (
        !this.oferta.visada && !this.oferta.perdida && (!this.oferta.caducada || (this.oferta.caducada && this.esAdmin))
      );
    },
    puedeEntregar() {
      return (
        !this.oferta.perdida &&
        !this.oferta.entregada &&
        this.oferta.visada &&
        (!this.oferta.caducada || (this.oferta.caducada && this.esAdmin))
      );
    },
    puedeGanar() {
      return (
        !this.oferta.perdida &&
        this.oferta.entregada &&
        !this.oferta.ganada &&
        (!this.oferta.caducada || (this.oferta.caducada && this.esAdmin))
      );
    },
    esGanada() {
      return this.oferta.ganada;
    },
    puedePerder() {
      return (
        !this.oferta.ganada &&
        !this.oferta.perdida &&
        this.oferta.entregada &&
        (!this.oferta.caducada || (this.oferta.caducada && this.esAdmin))
      );
    },
    puedeEnviarPapelera() {
      return !this.oferta.entregada && !this.oferta.ganada;
    },
    puedeVerContrato() {
      return this.oferta.tieneContrato && this.oferta.ganada;
    },
    puedeVerContratoAdmin() {
      return this.esAdmin && this.oferta.tieneContrato && this.oferta.ganada;
    },
  },
  methods: {
    mostrarMensaje(msg) {
      this.$root.$emit("userMessage", msg);
    },
    async crearVersion(id) {
      var version = await apiOfertas.crearVersion(id);
      this.$router.push({
        path: "/ofertas/editar/" + version.id,
      });
      this.$root.$emit("userMessage", `Se ha creado la versión v${version.numeroVersion}`);
    },
    async entregarVersion(id, descripcion) {
      await apiOfertas.entregarVersion(id);
      this.mostrarMensaje(`Oferta '${descripcion}' entregada`);
      this.$emit("ofertaCambiada", id);
    },
    async visarVersion(id, descripcion) {
      await apiOfertas.visarVersion(id);
      this.mostrarMensaje(`Oferta '${descripcion}' visada`);
      this.$emit("ofertaCambiada", id);
    },
    async ganarVersion() {
      let clienteIncompleto = await apiClientes.comprobarClienteCompleto(this.oferta.ofertaBaseId);
      this.tieneUnidadNegocio = await apiOfertas.tieneUnidadNegocio(this.oferta.ofertaBaseId);

      if (clienteIncompleto.data == 0) {
        this.$bvModal.show("modalFechaGanar-" + this.oferta.id);
      } else {
        this.clienteIncompletoId = this.oferta.clienteId;
        this.$bvModal.show("modalGanar-" + this.oferta.id);
      }
    },
    async perderVersion(id, descripcion) {
      await apiOfertas.perderVersion(id);
      this.mostrarMensaje(`Oferta '${descripcion}' perdida`);
      this.$emit("ofertaCambiada", id);
    },
    async enviarVersionPapelera(id, descripcion) {
      await apiOfertas.enviarVersionPapelera(id);
      this.mostrarMensaje(`Oferta '${descripcion}' enviada a la Papelera`);
      this.$emit("ofertaCambiada", id);
    },
    verCliente() {
      this.$router.push("/clientes/editar/" + this.oferta.clienteId);
    },
    async submit(id, descripcion) {
      if (this.oferta.ganadaEl == "") return this.mostrarMensaje("Seleccione una fecha.");
      await apiOfertas.ganarVersion(id, this.oferta.ganadaEl, this.ordenCompra, this.cartaBienvenida);
      this.$bvModal.hide("modalFechaGanar-" + id);
      this.mostrarMensaje(`Oferta '${descripcion}' ganada`);
      this.$emit("ofertaCambiada", id);
    },
    actualizarOrdenCompra(fichero) {
      var name = "";
      name = fichero.name;
      this.enviarFichero(name, fichero);
    },
    enviarFichero(nombre, fichero) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.ordenCompra = {
          name: nombre,
          content: e.target.result,
        };
      };
      reader.readAsDataURL(fichero);
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_ENDPOINT,
      clienteIncompletoId: "",
      ordenCompra: {},
      cartaBienvenida: false,
      tieneUnidadNegocio: false,
    };
  },
};
</script>
