<template>
  <page-listado title="Plantillas" con-busqueda>
    <tabla-ofertas :ofertas="resultado.ofertas"> </tabla-ofertas>
    <paginacion :page-count="resultado.numeroPaginas" :page="resultado.pagina"></paginacion>
  </page-listado>
</template>

<script>
import api from "@/services/plantillas.js";
import TablaOfertas from "./TablaOfertas.vue";

export default {
  components: { TablaOfertas },
  data() {
    return {
      resultado: {
        ofertas: [],
        numeroPaginas: 0,
        pagina: 1,
      },
      reloadListar: false,
    };
  },
  methods: {
    listar() {
      var query = Object.assign({}, this.$route.query);
      query.plantilla = true;
      var route = {
        path: this.$route.path,
        query: query,
      };

      return api.listar(route.query).then((resultado) => {
        this.resultado = resultado;
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
