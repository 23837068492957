import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
    forzarProyectoSaas: false,
  };
}

function cargarPlazosDescuento() {
  return api.get("campanias/cargarPlazosDescuento").then((response) => {
    return response.data;
  });
}

function normalize(c) {
  c.forzarProyectoSaas = Boolean(c.forzarProyectoSaas);
  return c;
}

function listar(params) {
  return listado("campanias/listar", params);
}

function listarConDescuentosFirma(params) {
  return listado("campanias/listarConDescuentosFirma", params);
}

function cargar(id) {
  return api.get("campanias/cargar&id=" + id).then((response) => {
    return {
      campania: normalize(response.data.campania),
      descuentos: response.data.descuentos,
    };
  });
}

function insertar(campania, descuentos) {
  return api.post("campanias/insertar", { campania: campania, descuentos: descuentos });
}

function actualizar(campania, descuentos) {
  return api.post("campanias/actualizar", { campania: campania, descuentos: descuentos });
}

function eliminar(id) {
  return api.post("campanias/eliminar", { id });
}

export default {
  crear,
  cargarPlazosDescuento,
  listar,
  listarConDescuentosFirma,
  cargar,
  insertar,
  actualizar,
  eliminar,
};
