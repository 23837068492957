<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado titulo="Alpha2" campo="alpha2" class="text-center"></th-ordenado>
      <th-ordenado titulo="NIT" campo="nombreIdentificacionTributaria" class="text-center"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!paises" :colspan="4"></sin-registros>
      <tr v-for="pais in paises" v-bind:key="pais.id" role="button">
        <td @click="editarPais(pais.id)">{{ pais.nombre }}</td>
        <td @click="editarPais(pais.id)" class="text-center">{{ pais.alpha2 }}</td>
        <td @click="editarPais(pais.id)" class="text-center">
          {{ pais.nombreIdentificacionTributaria }}
        </td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/paises/editar/${pais.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', pais.id)">
              ¿ Desea eliminar el país
              <b>{{ pais.nombre }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    paises: Array,
  },
  methods: {
    editarPais(paisId) {
      this.$router.push("/paises/editar/" + paisId);
    },
  },
};
</script>
