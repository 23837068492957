<template>
  <div class="filtros" v-show="mostrarFiltros">
    <b-form @submit.prevent="filtrar" inline>
      <b-container fluid>
        <b-row>
          <b-col lg="3">
            <b-form-group label="Países" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.paises"
                :options="precarga.paises"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estados" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.estados"
                :options="precarga.estados"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Fecha estado" content-cols-sm="12">
              <date-picker
                class="filter"
                v-model="filtros.fecha"
                format="MM-YYYY"
                type="month"
                @input="filtrar"
                range
                placeholder="Elija fecha"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Búsqueda" content-cols-sm="12">
              <b-form-input
                @change="filtrar"
                class="input-mw filter"
                type="search"
                v-model="filtros.busqueda"
                placeholder="Búsqueda"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Segunda fila de filtros -->
        <b-row>
          <b-col lg="3">
            <b-form-group label="Campañas" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.campanias"
                :options="precarga.campanias"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Tipo de venta" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.tiposDeVenta"
                :options="precarga.tiposDeVenta"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Origen" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.origenes"
                :options="precarga.origenes"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Propietarios" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.propietarios"
                :options="precarga.propietarios"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3">
            <b-form-group label="Embudo pipedrive" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.embudos"
                :options="precarga.embudos"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Etapa pipedrive" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.etapas"
                :options="precarga.etapas"
              />
            </b-form-group>
          </b-col>

          <!-- El estado de pipedrive queda oculto de momento -->
          <!-- <b-col lg="3">
            <b-form-group label="Estado pipedrive" content-cols-sm="12">
              <combo-multiple
                @input="filtrar"
                class="input-mw filter"
                v-model="filtros.estadosPipedrive"
                :options="precarga.estadosPipedrive"
              />
            </b-form-group>
          </b-col> -->

          <!-- Fecha oferta -->
          <b-col lg="3">
            <b-form-group label="Fecha Oferta" content-cols-sm="12">
              <date-picker
                class="filter"
                v-model="filtros.fechaOferta"
                format="MM-YYYY"
                type="month"
                @input="filtrar"
                range
                placeholder="Elija fecha"
              />
            </b-form-group>
          </b-col>

          <!-- Fecha prevista de cierre -->
          <b-col lg="3">
            <b-form-group label="Fecha prevista de cierre" content-cols-sm="12">
              <date-picker
                class="filter"
                v-model="filtros.fechaPrevistaCierre"
                format="MM-YYYY"
                type="month"
                @input="filtrar"
                range
                placeholder="Elija fecha"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mt-4 alert alert-warning" v-show="mostrarFiltrosUsados">
          Filtrando por {{ filtrosUsados }} (<a @click="limpiarFiltros" href="javascript:;">Limpiar filtros</a>)
        </div>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { precargaCombos, comboUsauriosTrasBusqueda } from "@/services/ofertaPrecarga.js";
import { formatMonthRange } from "@/services/date";

function filtrosNuevos() {
  return {
    busqueda: "",
    fecha: "",
    fechaOferta: "",
    fechaPrevistaCierre: "",
    estados: [],
    campanias: [],
    origenes: [],
    paises: [],
    propietarios: [],
    tiposDeVenta: [],
    embudos: [],
    etapas: [],
    estadosPipedrive: [],
  };
}

export default {
  props: {
    filtrosIniciales: Object,
  },
  data() {
    return {
      precarga: {
        campanias: [],
        estados: [],
        origenes: [],
        paises: [],
        propietarios: [],
        tiposDeVenta: [],
        embudos: [],
        etapas: [],
        estadosPipedrive: [],
      },
      filtros: filtrosNuevos(),
      mostrarFiltros: true,
    };
  },
  computed: {
    ...mapState("session", ["esAdmin", "usuarioId"]),
    mostrarFiltrosUsados() {
      return this.filtrosUsados.length > 0;
    },
    filtrosUsados() {
      let usados = [];
      if (this.filtros.paises.length > 0) {
        usados.push("país");
      }
      if (this.filtros.fecha != "") {
        usados.push("fecha");
      }
      if (this.filtros.fechaOferta != "") {
        usados.push("fechaOferta");
      }
      if (this.filtros.fechaPrevistaCierre != "") {
        usados.push("fechaPrevistaCierre");
      }
      if (this.filtros.busqueda != "") {
        usados.push("búsqueda");
      }
      if (this.filtros.estados.length > 0) {
        usados.push("estado");
      }
      if (this.filtros.campanias.length > 0) {
        usados.push("campaña");
      }
      if (this.filtros.tiposDeVenta.length > 0) {
        usados.push("tipo de venta");
      }
      if (this.filtros.origenes.length > 0) {
        usados.push("origen");
      }
      if (this.filtros.propietarios.length > 0) {
        usados.push("propietario");
      }
      if (this.filtros.embudos.length > 0) {
        usados.push("embudo");
      }
      if (this.filtros.etapas.length > 0) {
        usados.push("etapa");
      }
      if (this.filtros.estadosPipedrive.length > 0) {
        usados.push("estado en pipedrive");
      }
      return usados.join(", ").replace(/,([^,]*)$/, " y$1");
    },
  },
  methods: {
    filtrar() {
      const params = {
        paises: this.filtros.paises,
        estados: this.filtros.estados,
        campanias: this.filtros.campanias,
        propietarios: this.filtros.propietarios,
        busqueda: this.filtros.busqueda,
        tiposDeVenta: this.filtros.tiposDeVenta,
        origenes: this.filtros.origenes,
        embudos: this.filtros.embudos,
        etapas: this.filtros.etapas,
        estadosPipedrive: this.filtros.estadosPipedrive,
      };

      const rango = formatMonthRange(this.filtros.fecha);
      if (rango) {
        params.fechaDesde = rango[0];
        params.fechaHasta = rango[1];
      }

      const rangoOferta = formatMonthRange(this.filtros.fechaOferta);
      if (rangoOferta) {
        params.fechaOfertaDesde = rangoOferta[0];
        params.fechaOfertaHasta = rangoOferta[1];
      }

      const rangoFechaPrevistaCierre = formatMonthRange(this.filtros.fechaPrevistaCierre);
      if (rangoFechaPrevistaCierre) {
        params.fechaPrevistaCierreDesde = rangoFechaPrevistaCierre[0];
        params.fechaPrevistaCierreHasta = rangoFechaPrevistaCierre[1];
      }

      this.$emit("filtrar", params);
    },
    limpiarFiltros() {
      this.filtros = filtrosNuevos();
      this.filtrar();
    },
    async actualizarComboUsauriosTrasBusqueda() {
      const paises = this.filtros.paises;
      const propietarios = await comboUsauriosTrasBusqueda(paises);
      this.$set(this.precarga, "propietarios", propietarios);
    },
    async precargaCombos() {
      const precarga = await precargaCombos();
      // combos multiples (select-2-multiple)
      this.$set(this.precarga, "paises", precarga.paises);
      this.$set(this.precarga, "propietarios", precarga.propietarios);
      this.$set(this.precarga, "tiposDeVenta", precarga.tiposDeVenta);
      this.$set(this.precarga, "origenes", precarga.origenes);
      this.$set(this.precarga, "embudos", precarga.embudos);
      this.$set(this.precarga, "etapas", precarga.etapas);
      this.$set(this.precarga, "estadosPipedrive", precarga.estadosPipedrive);
      // combos planos (b-form-select)
      this.$set(this.precarga, "campanias", precarga.campanias);
      this.$set(this.precarga, "estados", precarga.estados);
    },
    async bootstrap() {
      await this.precargaCombos();
      this.filtros.busqueda = this.filtrosIniciales.busqueda || "";
      this.filtros.estados = this.filtrosIniciales.estados || [];
      this.filtros.paises = this.filtrosIniciales.paises || [];
      this.filtros.campanias = this.filtrosIniciales.campanias || [];
      this.filtros.origenes = this.filtrosIniciales.origenes || [];
      this.filtros.propietarios = this.filtrosIniciales.propietarios || [];
      this.filtros.tiposDeVenta = this.filtrosIniciales.tiposDeVenta || [];
      this.filtros.embudos = this.filtrosIniciales.embudos || [];
      this.filtros.etapas = this.filtrosIniciales.etapas || [];
      this.filtros.estadosPipedrive = this.filtrosIniciales.estadosPipedrive || [];

      if (this.filtrosIniciales.fechaDesde && this.filtrosIniciales.fechaHasta) {
        const desde = new Date(this.filtrosIniciales.fechaDesde);
        const hasta = new Date(this.filtrosIniciales.fechaHasta);
        this.filtros.fecha = [desde, hasta];
      }

      if (this.filtrosIniciales.fechaOfertaDesde && this.filtrosIniciales.fechaOfertaHasta) {
        const desde = new Date(this.filtrosIniciales.fechaOfertaDesde);
        const hasta = new Date(this.filtrosIniciales.fechaOfertaHasta);
        this.filtros.fechaOferta = [desde, hasta];
      }

      if (this.filtrosIniciales.fechaPrevistaCierreDesde && this.filtrosIniciales.fechaPrevistaCierreHasta) {
        const desde = new Date(this.filtrosIniciales.fechaPrevistaCierreDesde);
        const hasta = new Date(this.filtrosIniciales.fechaPrevistaCierreHasta);
        this.filtros.fechaPrevistaCierre = [desde, hasta];
      }
    },
  },
  created() {
    this.bootstrap();
  },
};
</script>
