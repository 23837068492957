<template>
  <b-container :fluid="fluid">
    <div id="page-container">
      <div id="page-content">
        <div class="content-card">
          <b-row class="pb-2" align-v="start">
            <b-col>
              <h1>{{ title }}</h1>
            </b-col>
            <b-col style="text-align: right">
              <slot name="toolbar"></slot>
            </b-col>
            <b-col md="3" v-if="conBusqueda">
              <busqueda-rapida @buscar="buscar"></busqueda-rapida>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <slot name="filters"></slot>
            </b-col>
          </b-row>

          <slot></slot>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    fluid: {
      type: Boolean,
      default: true,
    },
    title: String,
    conBusqueda: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buscar(term) {
      var query = {};

      if (term != "") {
        query.busqueda = term;
      }

      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
  },
};
</script>
