<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input required type="text" v-model="plan.nombre" placeholder="Nombre del plan"></b-form-input>
    </b-form-group>
    <b-row>
      <b-col md="2">
        <b-form-group label="Máx. usuarios">
          <b-form-input
            required
            type="number"
            min="0"
            max="500000"
            v-model="plan.maximoNumeroUsuarios"
            maxlength="6"
            placeholder="Máximo número de usuarios"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion url-cancelar="/planes" />
  </b-form>
</template>
<script>
export default {
  props: {
    plan: Object,
  },
};
</script>
