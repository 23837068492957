<template>
  <b-form @submit.prevent="guardar" enctype="multipart/form-data" v-if="ready">
    <p>Creado el {{ modulo.creadoEl | fecha }}</p>

    <b-row class="mb-4">
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Nombre">
          <b-form-input required type="text" v-model="modulo.nombre" placeholder="Nombre del modulo"></b-form-input>
        </b-form-group>

        <campo-imagen v-model="imagenes.logo" title="Logotipo" />
        <campo-imagen v-model="imagenes.rutaCaptura" title="Captura del módulo" />
      </b-col>

      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Necesidad">
          <b-form-textarea v-model="moduloContenido.necesidad" placeholder="Necesidad" rows="4"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Solución">
          <b-form-textarea v-model="moduloContenido.solucion" placeholder="Solución" rows="4"></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col lg="12" md="12" sm="12">
        <b-form-group label="Descripción Corta">
          <wysiwyg id="editorDescCorta" placeholder="Escriba aquí" v-model="moduloContenido.descCorta"></wysiwyg>
        </b-form-group>

        <b-form-group label="Descripción Larga">
          <wysiwyg id="editorDescLarga" placeholder="Escriba aquí" v-model="moduloContenido.descLarga"></wysiwyg>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6" md="12">
        <b-form-group label="Campaña">
          <b-card bg-variant="warning">
            <b-form-checkbox v-model="modulo.visibleEnTodasCampanias" :value="false" :unchecked-value="true">
              Personalizar las campañas
            </b-form-checkbox>
            <div class="pl-4" v-if="!modulo.visibleEnTodasCampanias">
              <b-form-checkbox-group v-model="campanias" :options="campaniasDisponibles" />
            </div>
          </b-card>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12">
        <b-form-group label="Tipo" v-slot="{ ariaDescribedby }">
          <b-card bg-variant="light">
            <b-form-radio-group
              v-model="modulo.tipo"
              :options="tiposDeModulo"
              :aria-describedby="ariaDescribedby"
              stacked
            />
          </b-card>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Comportamientos">
      <b-card>
        <b-form-checkbox v-model="modulo.permiteCambioPrecio"> Permite editar precio en ofertas </b-form-checkbox>
        <b-form-checkbox v-model="modulo.permiteCambioHoras"> Permite editar horas en ofertas </b-form-checkbox>
        <hr />
        <b-form-checkbox v-model="modulo.cubreCosteDeImplementacion">
          Cubre el coste de implementación
          <v-icon name="help-circle" width="16" id="sobreCosteImplementacion" />
          <b-popover target="sobreCosteImplementacion" triggers="hover focus" title="Coste de implementación">
            <p>
              El <b>coste de implementación</b> es el importe que se imputa por las horas de consultoría y las horas de
              capacitación.
            </p>
            <p>
              <b>Si activamos</b> este checkbox, cuando se agregue este módulo o servicio a una oferta, esta dejará de
              imputar costes de implementación
            </p>
          </b-popover>
        </b-form-checkbox>
        <b-form-checkbox v-model="modulo.moduloRenovacion">
          Renovación de contratos
          <v-icon name="help-circle" width="16" id="moduloRenovacion" />
          <b-popover target="moduloRenovacion" triggers="hover focus" title="Renovación de contratos">
            <p>
              Al <b>renovar los contratos</b> se crea una nueva oferta en la que deben eliminarse módulos y servicios y
              añadir un nuevo módulo de renovación.
            </p>
            <p>
              <b>Si activamos</b> este checkbox, cuando se renueve un contrato se tomará este módulo como el módulo de
              la oferta de renovación.
            </p>
          </b-popover>
        </b-form-checkbox>
      </b-card>
    </b-form-group>

    <botonera-edicion url-cancelar="/modulos" />
  </b-form>
</template>

<script>
import * as apiModulos from "@/services/modulos.js";
import apiCampanias from "@/services/campanias.js";

export default {
  data() {
    return {
      ready: false,
      id: 0,
      modulo: Object,
      moduloContenido: Object,
      imagenes: Object,
      campanias: Array,
      campaniasDisponibles: [],
      tiposDeModulo: [
        { text: "Módulo base (Sólo el módulo base)", value: 1 },
        { text: "Módulo", value: 2 },
        { text: "Servicio", value: 3 },
      ],
    };
  },
  methods: {
    async cargar() {
      const payload = this.id ? await apiModulos.cargarCompleto(this.id) : apiModulos.crear();

      this.modulo = payload.modulo;
      this.moduloContenido = payload.moduloContenido;
      this.imagenes = payload.imagenes;
      this.campanias = payload.campanias;
    },
    async cargarCampanias() {
      const campanias = await apiCampanias.listar();
      this.campaniasDisponibles = campanias.map((c) => {
        return { value: c.id, text: c.nombre };
      });
    },
    async guardar() {
      const payload = {
        modulo: this.modulo,
        moduloContenido: this.moduloContenido,
        imagenes: this.imagenes,
        campanias: this.campanias,
      };

      if (this.id) {
        await apiModulos.actualizar(payload);
      } else {
        await apiModulos.insertar(payload);
      }

      this.$emit("guardado");
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    await this.cargar();
    await this.cargarCampanias();
    this.ready = true;
  },
};
</script>
