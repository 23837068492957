<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input
        required
        type="text"
        v-model="formaPago.nombre"
        placeholder="Nombre de la forma de pago"
      ></b-form-input>
    </b-form-group>
    <botonera-edicion url-cancelar="/formasPago" />
  </b-form>
</template>
<script>
export default {
  props: {
    formaPago: Object,
  },
};
</script>
