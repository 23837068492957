<template>
  <th class="sortable-column" @click="rotarOrden()" :title="help">
    <slot>{{ titulo }}</slot>
    <v-icon v-if="direccion == 'DESC'" variant="white" name="chevron-up" width="16" />
    <v-icon v-if="direccion == 'ASC'" variant="white" name="chevron-down" width="16" />
  </th>
</template>
<script>
export default {
  props: {
    titulo: String,
    campo: String,
  },
  data() {
    return {
      direccion: "",
    };
  },
  computed: {
    dir() {
      return this.$route.query.dir || "";
    },
    help() {
      var t = this.dir == "DESC" ? "descendente" : "ascendente";
      return `Ordena por ${this.titulo} ${t}`;
    },
  },
  methods: {
    rotarOrden() {
      var query = Object.assign({}, this.$route.query);
      query.orden = this.campo;
      query.dir = query.dir == "DESC" ? "ASC" : "DESC";
      this.direccion = query.dir;

      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
  },
};
</script>
