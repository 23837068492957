import { listado } from "./listado";
import api from "./backend";
import Vue from "vue";

function listar(params) {
  return listado("precioHoraDivisaPais/listar", params);
}

function actualizar(preciosHoraDivisaPais) {
  var preciosHora = [];
  for (var ph in preciosHoraDivisaPais) {
    var precio = {};
    precio.divisaId = preciosHoraDivisaPais[ph].divisaId;
    precio.paisId = preciosHoraDivisaPais[ph].paisId;
    precio.precioHoraCapacitacion = preciosHoraDivisaPais[ph].precioHoraCapacitacion;
    precio.precioHoraConsultoria = preciosHoraDivisaPais[ph].precioHoraConsultoria;
    Vue.set(preciosHora, preciosHora.length, precio);
  }
  return api.post("precioHoraDivisaPais/actualizar", { preciosHora });
}

export default { listar, actualizar };
