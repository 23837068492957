<template>
  <div>
    <b-row class="mb-3">
      <b-col lg="3">
        <b-form-checkbox v-model="estaAbierto"> Expandir / Contraer todo </b-form-checkbox>
      </b-col>
      <b-col lg="3" v-if="esAdmin">
        <b-form-checkbox v-model="modoEdicion"> Modo Edición </b-form-checkbox>
      </b-col>
    </b-row>
    <ul>
      <tree-item
        class="item"
        :item="treeData"
        @anadirCarpeta="anadirCarpeta"
        @anadirDocumento="anadirDocumento"
        @editarDocumento="editarDocumento"
        :estaAbierto="estaAbierto"
        :modoEdicion="modoEdicion"
        @borrarDocumento="borrarDocumento"
      ></tree-item>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatDateEs } from "@/services/date";
import apiRepositoriosComerciales from "@/services/repositoriosComerciales.js";

export default {
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  props: {
    repositoriosComerciales: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async anadirDocumento(documento) {
      documento.tipo = 1;
      await apiRepositoriosComerciales.insertar(documento);
      this.treeData = await apiRepositoriosComerciales.listar();
    },
    async anadirCarpeta(documento) {
      documento.tipo = 0;
      await apiRepositoriosComerciales.insertar(documento);
      this.treeData = await apiRepositoriosComerciales.listar();
    },
    async rellenarArbol() {
      this.treeData = await apiRepositoriosComerciales.listar();
    },
    async borrarDocumento(documento) {
      await apiRepositoriosComerciales.eliminar(documento.id);
      this.treeData = await apiRepositoriosComerciales.listar();
    },
    async editarDocumento(documento) {
      await apiRepositoriosComerciales.actualizar(documento);
      this.treeData = await apiRepositoriosComerciales.listar();
    },
  },
  data() {
    return {
      treeData: [],
      estaAbierto: true,
      modoEdicion: false,
    };
  },
  mounted() {
    this.rellenarArbol();
  },
};
</script>
