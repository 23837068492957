import Vue from "vue";
import Vuex from "vuex";
import session from "./session";
import formaPago from "./formaPago";

Vue.use(Vuex);

const modules = { session, formaPago };

const state = {
  usuario: "",
  centro: "",
  logo: "",
  aplicaciones: [],
  enlaces_externos: {},
  loading: false,
  loading_count: 0,
  ultimaOfertaModificada: null,
};

const mutations = {
  setLoading(state, loading) {
    if (loading) {
      state.loading_count++;
      state.loading = true;
    } else if (state.loading_count > 0) {
      setTimeout(() => {
        state.loading_count--;
        state.loading = state.loading_count > 0;
      }, 300);
    }
  },
  setUltimaOfertaModificada(state, id) {
    state.ultimaOfertaModificada = id;
  },
};

const actions = {
  setUltimaOfertaModificada({ commit }, id) {
    commit("setUltimaOfertaModificada", id);
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
});
