<template>
  <b-card class="mt-5" style="max-width: 340px; margin: auto; justify-self: center">
    <div class="brand-logo"></div>
    <h2>Inicio de sesión</h2>
    <b-form @submit.prevent="iniciarSesion">
      <b-form-group label="Email">
        <b-form-input
          required
          autofocus
          autocomplete="username"
          type="email"
          v-model="email"
          placeholder="Email del usuario"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Contraseña">
        <b-form-input
          v-model="clave"
          type="password"
          autocomplete="current-password"
          placeholder="Contraseña"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary" class="mt-2"> Acceder </b-button>
    </b-form>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      clave: "",
    };
  },
  methods: {
    async iniciarSesion() {
      await this.$store.dispatch("session/login", [this.email, this.clave]);
      this.$router.push("/");
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("session/recover");
      this.$router.push({ path: this.$route.query.redirect || "/" });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("No se puede recuperar la sesión. Lo que es normal si no hay sesión iniciada");
    }
  },
};
</script>
