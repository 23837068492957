<template>
  <b-form @submit.prevent="submit">
    <div class="content-card">
      <b-row class="pb-2" align-v="start">
        <b-col>
          <h1>{{ titulo }}</h1>
        </b-col>
        <b-col md="4" style="text-align: right">
          <b-button variant="outline-danger" size="sm" title="Volver" class="text-right mr-2" @click="volverAtras">
            <v-icon variant="danger" name="arrow-left-circle" width="16" />
            Volver
          </b-button>
          <b-button
            v-b-toggle.collapse-datos
            variant="outline-info"
            size="sm"
            title="Mostrar / Ocultar Datos de la Oferta"
            class="text-right"
          >
            Alternar cabecera
            <v-icon variant="primary" name="chevrons-down" width="16" />
          </b-button>
        </b-col>
      </b-row>
      <b-collapse visible id="collapse-datos">
        <b-row class="mb-3">
          <b-col lg="12">
            <h2 class="pb-1">Oferta</h2>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col lg="10">
            <b-row>
              <b-col lg="12">
                <formulario-oferta-datos
                  :ofertaBase="ofertaBase"
                  :oferta="oferta"
                  :ofertaPipedrive="ofertaPipedrive"
                  :cliente="cliente"
                  :unidadNegocio="unidadNegocio"
                  :fechaFirmaInicial="fechaFirmaInicial"
                  :trazabilidad="trazabilidad"
                  :campanias="campanias"
                  :ofertaDescuentosFirma="ofertaDescuentosFirma"
                  :tarifa="tarifa"
                  :usuario="usuario"
                  :esPartner="esOfertaPartner"
                  @clienteCambiado="cambiarCliente"
                  @unidadNegocioCambiada="cambiarUnidadNegocio"
                  @tarifaCambiada="cambiarTarifa"
                  @duracionFormaDePago="cambiarDuracionFormaDePago"
                  @actualizarOfertaDescuentosFirma="actualizarOfertaDescuentosFirma"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <detalle-cliente
                  :oferta="oferta"
                  :cliente="cliente.nombre"
                  :pais="clientePais.nombre"
                  :contacto="oferta.contacto"
                  :cargoContacto="oferta.cargoContacto"
                  :contactos="clienteContactos"
                  @contactoCambiado="cambiarContacto"
                />
                <detalle-divisa :oferta="oferta" v-model="oferta.divisaId" :divisas="divisas" />
              </b-col>
              <b-col md="6">
                <detalle-forma-pago
                  :oferta="oferta"
                  v-if="!esOfertaPartner || esAdmin"
                  v-model="ofertaFormaPago"
                  :duracionFormaDePago="duracionFP"
                />
                <detalle-complejidades
                  :oferta="oferta"
                  v-model="oferta.complejidadProyectoId"
                  :complejidades="complejidades"
                  v-if="!esOfertaPartner || esAdmin"
                />

                <detalle-descuentos
                  :oferta="oferta"
                  :esSaas="esSaas"
                  v-model="ofertaTipoDescuento"
                  :tiposDescuento="tiposDescuento"
                  v-if="(Boolean(tiposDescuento) && !esOfertaPartner) || esAdmin"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="2" md="12" sm="12" data-v-sticky-container>
            <div class="contenedor-valoracion" v-sticky="optionsSticky">
              <div>
                <detalle-oferta
                  :codigo="oferta.codigo"
                  :version="oferta.numeroVersion"
                  :trazabilidad="trazabilidad"
                  :caducada="oferta.caducada"
                  :contrato="tieneContrato"
                />

                <detalle-valoracion
                  :cuota="valoracion.cuota"
                  :tipoCuota="Number(valoracion.tipoCuota)"
                  :inversion="valoracion.inversion"
                  :divisa="divisa"
                  :calculada="esCalculada"
                  :conTarifa="esConTarifa"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-collapse>

      <div>
        <h2>Configuración de la oferta</h2>
        <b-tabs content-class="mt-3">
          <b-tab title="Módulos" active>
            <selector-modulos
              v-if="borrador"
              concepto="Módulos"
              :endpoint="endpointModulos"
              @input="agregarModulo"
              :disabled="deshabilitarComboModulos"
            />
            <tabla-modulos-oferta
              :borrador="borrador"
              :modulosOferta="soloModulos"
              :calculada="esCalculada"
              :conTarifa="esConTarifa"
              @actualizaModulos="calcularValoracion"
            />
          </b-tab>

          <b-tab title="Servicios">
            <selector-modulos
              v-if="borrador"
              concepto="Servicios"
              :endpoint="endpointServicios"
              @input="agregarModulo"
              :disabled="deshabilitarComboModulos"
            />
            <tabla-servicios-oferta
              :borrador="borrador"
              :modulosOferta="soloServicios"
              :calculada="esCalculada"
              :conTarifa="esConTarifa"
              @actualizaModulos="calcularValoracion"
            />
          </b-tab>
        </b-tabs>
      </div>

      <mensaje-alerta
        variant="danger"
        :texto="textoAlerta"
        :mostrarAlerta="mostrarAlerta"
        @ocultar="mostrarAlerta = false"
      />

      <div>
        <botonera-edicion url-cancelar="/ofertas">
          <b-button type="submit" name="aplicar" variant="success"> Aplicar </b-button>
        </botonera-edicion>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";

import apiClientes from "@/services/clientes";
import apiPaises from "@/services/paises";
import apiOfertas from "@/services/ofertas";
import apiUnidadNegocio from "@/services/unidadesNegocio";
import apiTarifas from "@/services/tarifas";
import apiComplejidades from "@/services/complejidadesProyecto";
import apiFormasDePago from "@/services/formasPago";
import apiTipoDescuento from "@/services/tipoDescuento";
import apiModificadorPrecioModulo from "@/services/modificadorPrecioModulo";
import apiCampanias from "@/services/campanias";
import apiUsuarios from "@/services/usuarios";
import * as TipoModulo from "@/enums/TipoModulo";
import TipoProyecto from "@/enums/TipoProyecto";
import { formatDateTabla, formatDateAddDaysForm } from "@/services/date";
import * as Plazos from "@/enums/Plazos";

import FormularioOfertaDatos from "./FormularioOfertaDatos";
import SelectorModulos from "./SelectorModulos";
import TablaModulosOferta from "./TablaModulosOferta";
import TablaServiciosOferta from "./TablaServiciosOferta";

import DetalleOferta from "./detalle/DetalleOferta";
import DetalleCliente from "./detalle/DetalleCliente";
import DetalleDivisa from "./detalle/DetalleDivisa";
import DetalleComplejidades from "./detalle/DetalleComplejidades";
import DetalleFormaPago from "./detalle/DetalleFormaPago";
import DetalleDescuentos from "./detalle/DetalleDescuentos";
import DetalleValoracion from "./detalle/DetalleValoracion";
import VueStickyDirective from "@renatodeleao/vue-sticky-directive";

import debounceService from "@/services/debounce";

export default {
  props: {
    titulo: String,
    id: Number,
  },
  components: {
    FormularioOfertaDatos,
    SelectorModulos,
    TablaModulosOferta,
    TablaServiciosOferta,
    DetalleOferta,
    DetalleCliente,
    DetalleDivisa,
    DetalleComplejidades,
    DetalleFormaPago,
    DetalleDescuentos,
    DetalleValoracion,
  },
  directives: {
    sticky: VueStickyDirective,
  },
  computed: {
    ...mapState("session", ["esPartner", "esAdmin", "usuarioId"]),
    borrador() {
      return Boolean(this.oferta.borrador && !this.oferta.caducada);
    },
    puedeAgregarModulos() {
      return this.oferta.divisaId > 0 && this.oferta.campaniaId > 0 && this.oferta.planId > 0;
    },
    divisa() {
      return this.divisas.find((x) => x.id == this.oferta.divisaId) || {};
    },
    puedeValorar() {
      return this.oferta.id && this.oferta.divisaId && this.divisa.id;
    },
    soloModulos() {
      return this.ofertaModulos.filter((x) => this.modulos[x.moduloId].tipo != TipoModulo.SERVICIO);
    },
    soloServicios() {
      return this.ofertaModulos.filter((x) => this.modulos[x.moduloId].tipo == TipoModulo.SERVICIO);
    },
    endpointModulos() {
      const modulos = this.soloModulos.map((m) => m.moduloId).join(",");
      const tipos = [TipoModulo.BASE, TipoModulo.MODULO].join(",");
      return this.buildEndpointParaModulos(modulos, tipos);
    },
    endpointServicios() {
      const modulos = this.soloServicios.map((m) => m.moduloId).join(",");
      const tipos = [TipoModulo.SERVICIO].join(",");
      return this.buildEndpointParaModulos(modulos, tipos);
    },
    esSaas() {
      return this.ofertaFormaPago.tipoProyecto == TipoProyecto.SAAS;
    },
    deshabilitarComboModulos() {
      let deshabilitar = true;
      if (
        this.oferta.divisaId != undefined &&
        this.oferta.divisaId > 0 &&
        this.oferta.planId != undefined &&
        this.oferta.planId > 0 &&
        this.oferta.campaniaId != undefined &&
        this.oferta.campaniaId > 0
      ) {
        deshabilitar = false;
      }

      return deshabilitar;
    },
    esCalculada() {
      return Boolean(this.oferta.calculada);
    },
    esConTarifa() {
      return Boolean(this.oferta.tarifaId) && !this.esOfertaPartner;
    },
    tituloPagina() {
      return this.cliente.nombre;
    },
  },
  methods: {
    async cargar(id) {
      var data = await apiOfertas.cargar(this.id);

      await this.cargarCliente(data.ofertaBase.clienteId);
      await this.cargarDivisas(this.cliente.paisId);
      if (data.ofertaBase.unidadNegocioId) {
        await this.cargarUnidadNegocio(data.ofertaBase.unidadNegocioId);
        await this.cargaTarifa(data.oferta.tarifaId);
      } else {
        await this.cargarUnidadesNegocio();
      }
      this.tieneContrato = await apiOfertas.tieneContrato(this.id);

      this.ofertaBase = data.ofertaBase;
      this.oferta = data.oferta;
      this.fechaFirmaInicial = data.oferta.fechaFirmaContrato;
      this.ofertaFormaPago = data.ofertaFormaPago;
      this.ofertaTipoDescuento = data.ofertaTipoDescuento;
      this.modulos = data.modulos;
      this.ofertaModulos = data.ofertaModulos.map((m) => {
        m.eliminado = false;
        m.agregado = false;

        if (this.modulos.hasOwnProperty(m.moduloId)) {
          const modulo = this.modulos[m.moduloId];
          m.permiteCambioPrecio = modulo.permiteCambioPrecio;
          m.permiteCambioHoras = modulo.permiteCambioHoras;
        }

        return m;
      });

      this.ofertaPipedrive = data.ofertaPipedrive;
      this.usuario = data.usuario;
      this.esOfertaPartner = data.esPartner;
      this.valoracion = data.valoracion;
      this.trazabilidad = data.trazabilidad;
      this.ofertaDescuentosFirma =
        data.oferta.ofertaDescuentoFirma.length > 0
          ? data.oferta.ofertaDescuentoFirma
          : this.campanias.filter((c) => c.id == this.oferta.campaniaId)[0].descuentos;
    },
    async cargarUsuario() {
      var user = await apiUsuarios.cargarPropio();
      this.usuario = user.usuario;
      this.oferta.usuarioId = this.usuarioId;
    },
    async cargarCliente(id) {
      const data = await apiClientes.cargar(id, false);

      this.clientePais = data.pais;
      this.clienteContactos = data.contactos;
      this.cliente = data.cliente;
      this.$emit("updateHead");
    },
    async cargarUnidadNegocio(id) {
      const data = await apiUnidadNegocio.cargar(id);

      this.unidadNegocio = data.unidadNegocio;
    },
    async cargaTarifa(id) {
      const data = await apiTarifas.cargar(id);

      this.tarifa = data;
    },
    async cargarUnidadesNegocio() {
      const unidadesNegocio = await apiUnidadNegocio.listarPropios();
      if (unidadesNegocio.items.length == 1 && !Boolean(this.esAdmin)) {
        await this.cargarUnidadNegocio(unidadesNegocio.items[0].id);
        this.cambiarUnidadNegocio(unidadesNegocio.items[0]);
      }
    },
    cambiarCliente({ cliente, contactos, pais }) {
      this.cliente = cliente;
      this.clienteContactos = contactos;
      this.clientePais = pais;

      if (this.cliente.clienteContactoPorDefectoId) {
        var contacto = contactos.find((c) => c.id == this.cliente.clienteContactoPorDefectoId);

        if (!contacto) {
          contacto = contactos[0];
        }
        this.oferta.contacto = contacto.contacto;
        this.oferta.cargoContacto = contacto.cargoContacto;
      }

      this.ofertaBase.clienteId = cliente.id;
      this.oferta.paisId = cliente.paisId;
      this.oferta.codigo = "";

      this.cargarDivisas(cliente.paisId);
    },
    async cambiarUnidadNegocio(unidadNegocio) {
      this.ofertaBase.unidadNegocioId = unidadNegocio.id;
      this.oferta.tarifaId = unidadNegocio.tarifaId;
      if (unidadNegocio.tarifaId == undefined) {
        var un = await apiUnidadNegocio.cargar(unidadNegocio.id);
        this.oferta.tarifaId = un.unidadNegocio.tarifaId;
        this.tarifa = await apiTarifas.cargar(un.unidadNegocio.tarifaId);
        return;
      }
      this.tarifa = await apiTarifas.cargar(unidadNegocio.tarifaId);
    },
    cambiarTarifa(tarifa) {
      this.oferta.tarifaId = tarifa.id;
    },
    cambiarDuracionFormaDePago(fp) {
      this.duracionFP = fp;
    },
    actualizarOfertaDescuentosFirma(descuentos) {
      this.ofertaDescuentosFirma = descuentos;
    },
    cambiarContacto({ contacto, cargoContacto }) {
      this.oferta.contacto = contacto;
      this.oferta.cargoContacto = cargoContacto;
    },
    async cargarDivisas(paisId) {
      this.divisas = await apiPaises.obtenerDivisa(paisId);

      if (this.divisas.length) {
        this.oferta.divisaId = this.divisas[0].id;
      }
    },
    async cargarComplejidades() {
      this.complejidades = await apiComplejidades.listar();
    },
    async cargarFormasDePago() {
      await this.$store.dispatch("formaPago/cargar");
    },
    async cargarTiposDescuento() {
      this.tiposDescuento = await apiTipoDescuento.listarPropios();
    },
    cambioDivisa(divisaId) {
      this.oferta.divisaId = divisaId;
    },
    async guardarOferta(ofertaCompleta) {
      if (this.id) {
        return await apiOfertas.actualizar(ofertaCompleta);
      } else {
        return await apiOfertas.insertar(ofertaCompleta);
      }
    },
    async submit(event) {
      const oferta = await this.guardarOferta({
        ofertaBase: this.ofertaBase,
        oferta: this.oferta,
        ofertaFormaPago: this.ofertaFormaPago,
        ofertaTipoDescuento: this.ofertaTipoDescuento,
        ofertaModulosAgregados: this.ofertaModulos.filter((m) => m.agregado && !m.eliminado),
        ofertaModulosActualizados: this.ofertaModulos.filter((m) => !m.agregado && !m.eliminado),
        ofertaModulosEliminados: this.ofertaModulos.filter((m) => m.eliminado && !m.agregado),
      });

      this.$store.dispatch("setUltimaOfertaModificada", oferta.id);

      const aplicando = event.submitter.name == "aplicar";

      if (aplicando) {
        if (this.id) {
          this.cargar(this.id);
          this.$bvToast.toast("Se ha actualizado la oferta", {
            title: "Atención",
            variant: "success",
          });
        } else {
          this.$router.push("/ofertas/editar/" + oferta.id);
        }
      } else {
        this.$router.push("/ofertas");
      }
    },
    async agregarModulo(moduloId) {
      let params = {};

      params.moduloId = moduloId;
      params.divisaId = this.oferta.divisaId;
      params.planId = this.oferta.planId;
      params.paisId = this.oferta.paisId;
      params.campaniaId = this.oferta.campaniaId;
      params.duracionContratoId = this.oferta.duracionContratoId;
      params.tipoCuota = this.ofertaFormaPago.tipoCuota;
      params.ofertaId = this.oferta.id;
      params.tarifaId = this.oferta.tarifaId ? this.oferta.tarifaId : 0;
      params.descuentoFirma = this.calcularDescuentoFirma();

      try {
        this.validarParams(params);
        let { modulo, ofertaModulo } = await apiOfertas.moduloParaEstaOferta(params);

        ofertaModulo.agregado = true;
        ofertaModulo.eliminado = false;

        if (!this.modulos.hasOwnProperty(modulo.id)) {
          this.modulos[modulo.id] = modulo;
        }

        this.ofertaModulos.push(ofertaModulo);
      } catch (err) {
        this.mostrarAlerta = true;
        this.textoAlerta = err;
        throw err;
      }
    },
    yaAgregado(moduloId) {
      return this.ofertaModulos.filter((m) => m.moduloId == moduloId).length > 0;
    },
    validarParams(params) {
      if (!params.moduloId) {
        throw "Debe indicar un módulo";
      }
      if (!params.divisaId) {
        throw "Debe seleccionar una divisa";
      }
      if (!params.planId) {
        throw "Debe seleccionar un plan";
      }
      if (!params.campaniaId) {
        throw "Debe elegir una campaña";
      }
      if (!params.duracionContratoId) {
        throw "Debe elegir una duración de contrato";
      }
      if (!params.tipoCuota) {
        throw "Debe elegir una forma de pago de la cuota";
      }
      if (this.yaAgregado(params.moduloId)) {
        throw "Ese módulo ya se encuentra en la oferta, aumente la cantidad o añada otro diferente";
      }
      if (params.ofertaId == null) {
        params.ofertaId = 0;
      }
    },
    buildEndpointParaModulos(modulos, tipos) {
      const params = new URLSearchParams({
        divisaId: this.oferta.divisaId,
        planId: this.oferta.planId,
        campaniaId: this.oferta.campaniaId,
        modulos: modulos,
        tipos: tipos,
      }).toString();

      return `moduloParaOferta/autocompletar&${params}`;
    },
    comprobarCambiosParmametros(parametros) {
      if (parametros.divisaId != this.oferta.divisaId) {
        return false;
      }
      if (parametros.planId != this.oferta.planId) {
        return false;
      }
      if (parametros.duracionContratoId != this.oferta.duracionContratoId) {
        return false;
      }
      if (parametros.tipoCuota != this.ofertaFormaPago.tipoCuota) {
        return false;
      }
      if (parametros.paisId != this.oferta.paisId) {
        return false;
      }
      if (parametros.tarifaId != this.oferta.tarifaId) {
        return false;
      }
      return true;
    },
    actualizarPrecioModulosDebounced() {
      if (!this.cargaInicial && this.modulosCorrectos() && this.borrador) {
        let params = {};
        params.divisaId = this.oferta.divisaId;
        params.planId = this.oferta.planId;
        params.duracionContratoId = this.oferta.duracionContratoId;
        params.tipoCuota = this.ofertaFormaPago.tipoCuota;
        params.ofertaModulos = this.ofertaModulos;
        params.modulos = this.modulos;
        params.ofertaId = this.oferta.id ? this.oferta.id : 0;
        params.paisId = this.oferta.paisId;
        params.descuentoFirma = this.calcularDescuentoFirma();
        params.tarifaId = this.oferta.tarifaId ? this.oferta.tarifaId : 0;
        apiModificadorPrecioModulo
          .actualizarPrecioModulos(params)
          .then((response) => {
            if (this.comprobarCambiosParmametros(params)) this.ofertaModulos = response;
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    },
    async calcularValoracionDebounced() {
      if (!this.cargaInicial && this.modulosCorrectos() && this.borrador) {
        let params = {};
        params.formaPago = this.ofertaFormaPago;
        params.tipoDescuento = this.ofertaTipoDescuento;
        params.complejidadProyectoId = this.oferta.complejidadProyectoId;
        params.ofertaModulos = this.ofertaModulos;
        params.modulos = this.modulos;
        params.costeDeImplementacionCubierto = this.algunCosteCubierto();
        params.conTarifa = this.oferta.calculada && Boolean(this.oferta.tarifaId) && !this.esOfertaPartner;
        this.valoracion = await apiOfertas.calcularValoracion(params);
      }
    },
    algunCosteCubierto() {
      let tieneCosteCubierto = false;
      for (let i = 0; i < this.ofertaModulos.length; i++) {
        if (!this.ofertaModulos[i].eliminado && this.ofertaModulos[i].cubreCosteDeImplementacion) {
          tieneCosteCubierto = true;
        }
      }

      return tieneCosteCubierto;
    },
    modulosCorrectos() {
      let filasCorrectas = true;
      if (
        this.ofertaModulos != undefined &&
        this.ofertaModulos.length > 0 &&
        this.oferta.divisaId != undefined &&
        this.oferta.planId != undefined
      ) {
        for (let i = 0; i < this.ofertaModulos.length; i++) {
          if (this.ofertaModulos[i].precioSuscripcion === "" || this.ofertaModulos[i].precioSuscripcion < 0) {
            this.ofertaModulos[i].precioSuscripcion = 0;
          }
          if (this.ofertaModulos[i].precioCuota === "" || this.ofertaModulos[i].precioCuota < 0) {
            this.ofertaModulos[i].precioCuota = 0;
          }
          if (this.ofertaModulos[i].numHorasCapacitacion === "" || this.ofertaModulos[i].numHorasCapacitacion < 0) {
            this.ofertaModulos[i].numHorasCapacitacion = 0;
          }
          if (this.ofertaModulos[i].numHorasConsultoria === "" || this.ofertaModulos[i].numHorasConsultoria < 0) {
            this.ofertaModulos[i].numHorasConsultoria = 0;
          }
          if (this.ofertaModulos[i].precioServicio === "" || this.ofertaModulos[i].precioServicio < 0) {
            this.ofertaModulos[i].precioServicio = 0;
          }
        }
      } else {
        filasCorrectas = false;
      }
      return filasCorrectas;
    },
    async cargarCampanias() {
      this.campanias = await apiCampanias.listarConDescuentosFirma();
    },
    calcularDescuentoFirma() {
      if (!this.oferta.calculada) return 0;
      var descuento = 0;
      var fechaOf = formatDateTabla(this.oferta.fecha).split("/");
      var fechaFir = formatDateTabla(this.oferta.fechaFirmaContrato).split("/");
      if (!this.oferta.fechaFirmaContrato) {
        fechaFir = formatDateTabla(formatDateAddDaysForm(this.oferta.fecha, Plazos.DIASPARAFIRMACONTRATO)).split("/");
      }
      var diferenciaFechas =
        new Date(fechaFir[2], fechaFir[1] - 1, fechaFir[0]).getTime() -
        new Date(fechaOf[2], fechaOf[1] - 1, fechaOf[0]).getTime();
      var diferenciaEnDias = Math.ceil(diferenciaFechas / (1000 * 3600 * 24));
      // Comprobamos si hemos cambiado de campania ni de fecha de firma u oferta que ya tenga la oferta para no modificar el valor del descuento si no es el caso
      if (
        this.oferta.ofertaDescuentoFirma.campaniaId != this.oferta.campaniaId ||
        this.oferta.ofertaDescuentoFirma.dias == 0 ||
        this.obtenerRangoDescuentoOferta(diferenciaEnDias) != this.oferta.ofertaDescuentoFirma.dias
      ) {
        // Se comprueban los días que hay desde la fecha de la oferta hasta la fecha de firma de contrato para coger el descuento que corresponda

        var descuentoEnRango = false;
        for (var i = 0; i < this.ofertaDescuentosFirma.length; i++) {
          if (diferenciaEnDias <= this.ofertaDescuentosFirma[i].dias && this.ofertaDescuentosFirma[i].descuento > 0) {
            descuentoEnRango = true;
            descuento = this.ofertaDescuentosFirma[i].descuento / 100;
            if (this.oferta.ofertaDescuentoFirma) {
            } else {
              this.oferta.ofertaDescuentoFirma = apiOfertas.crearDescuentoFirma();
              this.oferta.ofertaDescuentoFirma.id = 0;
            }
            this.oferta.ofertaDescuentoFirma.ofertaId = this.oferta.id;
            this.oferta.ofertaDescuentoFirma.campaniaId = this.oferta.campaniaId;
            this.oferta.ofertaDescuentoFirma.plazoDescuentoCampaniaId =
              this.ofertaDescuentosFirma[i].plazoDescuentoCampaniaId;
            this.oferta.ofertaDescuentoFirma.dias = this.ofertaDescuentosFirma[i].dias;
            this.oferta.ofertaDescuentoFirma.descuento = this.ofertaDescuentosFirma[i].descuento;
            break;
          }
        }
        if (!descuentoEnRango) {
          this.oferta.ofertaDescuentoFirma.ofertaId = this.oferta.id;
          this.oferta.ofertaDescuentoFirma.campaniaId = this.oferta.campaniaId;
          this.oferta.ofertaDescuentoFirma.plazoDescuentoCampaniaId = 0;
          this.oferta.ofertaDescuentoFirma.dias = 0;
          this.oferta.ofertaDescuentoFirma.descuento = 0;
        }
      } else {
        descuento = this.oferta.ofertaDescuentoFirma.descuento / 100;
      }
      return descuento;
    },
    obtenerRangoDescuentoOferta(diferenciaEnDias) {
      var rango = 0;
      for (var i = 0; i < this.ofertaDescuentosFirma.length; i++) {
        if (diferenciaEnDias <= this.ofertaDescuentosFirma[i].dias && this.ofertaDescuentosFirma[i].descuento > 0) {
          rango = this.ofertaDescuentosFirma[i].dias;
          break;
        }
      }
      return rango;
    },
    volverAtras() {
      if (!this.borrador || this.oferta.caducada) {
        window.history.back();
      } else {
        const t = this.textoConfirmacion || "¿Está seguro?";
        this.$bvModal
          .msgBoxConfirm(t, {
            okTitle: "Aceptar",
            cancelTitle: "Cancelar",
          })
          .then((ok) => {
            if (ok) {
              window.history.back();
            }
          });
      }
    },
  },
  created() {
    this.actualizarPrecioModulos = debounceService.debounce(() => {
      this.actualizarPrecioModulosDebounced();
    }, 300);

    this.calcularValoracion = debounceService.debounce(() => {
      this.calcularValoracionDebounced();
    }, 300);
  },
  data() {
    return {
      ofertaBase: apiOfertas.crearOfertaBase(),
      oferta: apiOfertas.crearOferta(),
      ofertaFormaPago: apiOfertas.crearOfertaFormaPago(),
      ofertaTipoDescuento: apiOfertas.crearOfertaTipoDescuento(),
      ofertaModulos: [],
      ofertaPipedrive: {},
      modulos: {},
      // Datos del cliente seleccionado
      cliente: apiClientes.crear(),
      clientePais: apiPaises.crear(),
      clienteContactos: [],
      // Al cargar el cliente recibo las divisas
      divisas: [],
      complejidades: [], // todas las complejidades de proyecto
      formasDePago: [], // todas las formas de pago
      tiposDescuento: [], // todos los tipos de descuento
      ofertaDescuentosFirma: [], // descuentos asociados a la campaña vinculados a la fecha de firma
      // Unidad de Negocio
      unidadNegocio: apiUnidadNegocio.crear(),
      // Valoracion
      valoracion: {
        tipoCuota: 1,
        cuota: 0,
        inversion: 0,
      },
      // Alerta (messagebox)
      textoAlerta: "",
      mostrarAlerta: false,
      fechaFirmaInicial: null,
      duracionFP: null,
      modificadorPrecio: null,
      cargaInicial: true,
      // Trazabilidad
      trazabilidad: {
        fechaOfertaInicial: "",
        fechaFirmaInicial: "",
        fechaGanada: "",
      },
      optionsSticky: {
        topSpacing: 20,
      },
      campanias: [],
      tarifa: apiTarifas.crear(),
      tieneContrato: false,
      usuario: apiUsuarios.crear(),
      esOfertaPartner: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.tituloPagina,
      };
    },
  },
  async mounted() {
    await Promise.all([
      this.cargarComplejidades(),
      this.cargarFormasDePago(),
      this.cargarTiposDescuento(),
      this.cargarCampanias(),
    ]);

    if (this.id) {
      await this.cargar(this.id);
    } else {
      await this.cargarUnidadesNegocio();
      await this.cargarUsuario();
      this.esOfertaPartner = Boolean(this.esPartner);
      if (!this.esOfertaPartner) this.ofertaFormaPago.tipoProyecto = TipoProyecto.SAAS;
    }
    this.cargaInicial = false;
  },
  watch: {
    "oferta.duracionContratoId": function () {
      this.actualizarPrecioModulos();
    },
    "ofertaFormaPago.tipoCuota": function () {
      this.actualizarPrecioModulos();
    },
    "oferta.planId": function () {
      this.actualizarPrecioModulos();
    },
    "oferta.fechaFirmaContrato": function () {
      this.actualizarPrecioModulos();
    },
    "oferta.fecha": function () {
      this.actualizarPrecioModulos();
    },
    "oferta.complejidadProyectoId": function () {
      this.actualizarPrecioModulos();
    },
    "ofertaFormaPago.tipoProyecto": function () {
      this.actualizarPrecioModulos();
    },
    "oferta.tarifaId": function () {
      this.actualizarPrecioModulos();
    },
    ofertaDescuentosFirma: function () {
      this.actualizarPrecioModulos();
    },
    ofertaTipoDescuento: function () {
      this.actualizarPrecioModulos();
    },
    ofertaModulos: function () {
      this.calcularValoracion();
    },
  },
};
</script>
