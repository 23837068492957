<template>
  <b-modal id="modal-complejidadProyecto" title="Complejidad del Proyecto" @ok="aceptar">
    <b-form-select v-model="complejidadSeleccionada">
      <b-form-select-option
        v-for="complejidadProyecto in complejidades"
        :value="complejidadProyecto.id"
        :key="complejidadProyecto.id"
      >
        {{ complejidadProyecto.descripcion }}
      </b-form-select-option>
    </b-form-select>
  </b-modal>
</template>

<script>
export default {
  props: {
    complejidadProyectoId: Number,
    complejidades: Array,
  },
  data() {
    return {
      complejidadSeleccionada: this.complejidadProyectoId,
    };
  },
  methods: {
    aceptar() {
      this.$emit("ok", this.complejidadSeleccionada);
    },
  },
};
</script>
