<template>
  <b-form @submit.prevent="submit" autocomplete="off">
    <input style="display: none" type="text" name="fakeusername" />
    <input style="display: none" type="password" name="fakepassword" />
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Nombre">
          <b-form-input
            :readOnly="usuario.bloqueado"
            required
            type="text"
            v-model="usuario.nombre"
            placeholder="Nombre del usuario"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email">
          <b-form-input
            :readOnly="usuario.bloqueado"
            required
            type="email"
            v-model="usuario.email"
            placeholder="Email del usuario"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Contraseña">
          <b-form-input
            :readOnly="usuario.bloqueado"
            v-model="usuario.claveNueva"
            type="password"
            autocomplete="new-password"
            placeholder="Contraseña"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group label="Teléfono">
          <b-form-input
            :readOnly="usuario.bloqueado"
            type="text"
            v-model="usuario.telefono"
            autocomplete="telefono"
            placeholder="Teléfono del usuario"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Móvil">
          <b-form-input
            :readOnly="usuario.bloqueado"
            type="text"
            v-model="usuario.movil"
            autocomplete="movil"
            placeholder="Móvil del usuario"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Cargo">
          <b-form-input
            :readOnly="usuario.bloqueado"
            required
            type="text"
            v-model="usuario.cargo"
            autocomplete="cargo"
            placeholder="Cargo del usuario"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <campo-imagen
          :disabled="usuario.bloqueado"
          title="Avatar"
          placeholder="Elige una foto (máx. 200x200) y arrástrala aquí..."
          v-model="imagenes.rutaImagenAvatar"
        ></campo-imagen>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <campo-imagen :disabled="usuario.bloqueado" title="Firma" v-model="imagenes.rutaImagenFirma"></campo-imagen>
      </b-col>
    </b-row>

    <b-form-group label="Roles" class="mt-4">
      <b-card>
        <b-form-checkbox :disabled="usuario.bloqueado" v-model="usuario.esAdmin"> Admin </b-form-checkbox>
        <b-form-checkbox :disabled="usuario.bloqueado" v-model="usuario.esPartner" v-show="!usuario.esAdmin">
          Partner
        </b-form-checkbox>
        <b-form-checkbox :disabled="usuario.bloqueado" v-model="usuario.esRevisor" v-show="!usuario.esAdmin">
          Puede visar ofertas
        </b-form-checkbox>
      </b-card>
    </b-form-group>

    <b-form-group label="Paises Responsable" v-show="!usuario.esAdmin">
      <combo-multiple
        :disabled="usuario.bloqueado"
        :value="paisesResponsable"
        :options="opcionesPaisesResponsable"
        url="paises/autocompletar"
        @selected="seleccionadoResponsable"
      ></combo-multiple>
      <small><i>Muestra todas las ofertas de los paises indicados</i></small>
    </b-form-group>
    <b-form-group label="Paises Comercial" v-show="!usuario.esAdmin">
      <combo-multiple
        :disabled="usuario.bloqueado"
        :value="paisesComercial"
        :options="opcionesPaisesComercial"
        url="paises/autocompletar"
        @selected="seleccionadoComercial"
      ></combo-multiple>
      <small><i>Permite crear ofertas en los paises indicados</i></small>
    </b-form-group>
    <b-form-group label="Unidades de negocio" v-show="!usuario.esAdmin">
      <combo-multiple
        :disabled="usuario.bloqueado"
        :value="unidadesNegocio"
        :options="opcionesUnidadesNegocio"
        url="unidadNegocio/autocompletar"
        @selected="seleccionadoUnidadNegocio"
      ></combo-multiple>
    </b-form-group>
    <b-form-group label="Descuentos exclusivos" v-show="!usuario.esAdmin">
      <combo-multiple
        :disabled="usuario.bloqueado"
        :value="descuentos"
        :options="opcionesDescuentos"
        url="tiposDescuento/autocompletar"
        @selected="seleccionadoDescuento"
      ></combo-multiple>
    </b-form-group>

    <b-form-group label="Estado del Usuario">
      <b-form-checkbox id="checkbox-activo" name="checkbox-activo" v-model="usuario.bloqueado">
        Bloqueado
      </b-form-checkbox>
    </b-form-group>

    <botonera-edicion url-cancelar="/usuarios"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
import apiPaises from "@/services/paises.js";
export default {
  props: {
    usuario: Object,
    imagenes: Object,
    paisesResponsable: Array,
    paisesComercial: Array,
    unidadesNegocio: Array,
    descuentos: Array,
    opcionesPaisesResponsable: Array,
    opcionesPaisesComercial: Array,
    opcionesUnidadesNegocio: Array,
    opcionesDescuentos: Array,
  },
  data() {
    return {
      optionsRol: [{ text: "Admin", value: true }],
    };
  },
  methods: {
    seleccionadoResponsable(value) {
      //eliminar pais de lista comercial si existe
      var indexPaisEncontradoComercial = null;
      indexPaisEncontradoComercial = this.obtenerIndexPais(value, this.paisesComercial);
      if (indexPaisEncontradoComercial != null) {
        Vue.delete(this.paisesComercial, indexPaisEncontradoComercial);
      }

      //insertar pais en lista responsable si no está o eliminar si está
      var indexPaisEncontradoResponsable = null;
      indexPaisEncontradoResponsable = this.obtenerIndexPais(value, this.paisesResponsable);
      if (indexPaisEncontradoResponsable == null) {
        Vue.set(this.paisesResponsable, this.paisesResponsable.length, value.id);
      } else {
        Vue.delete(this.paisesResponsable, indexPaisEncontradoResponsable);
      }
    },
    seleccionadoComercial(value) {
      //eliminar pais de lista responsable si existe
      var indexPaisEncontradoResponsable = null;
      indexPaisEncontradoResponsable = this.obtenerIndexPais(value, this.paisesResponsable);
      if (indexPaisEncontradoResponsable != null) {
        Vue.delete(this.paisesResponsable, indexPaisEncontradoResponsable);
      }

      //insertar pais en lista comercial si no está
      var indexPaisEncontradoComercial = null;
      indexPaisEncontradoComercial = this.obtenerIndexPais(value, this.paisesComercial);
      if (indexPaisEncontradoComercial == null) {
        Vue.set(this.paisesComercial, this.paisesComercial.length, value.id);
      } else {
        Vue.delete(this.paisesComercial, indexPaisEncontradoComercial);
      }
    },
    seleccionadoUnidadNegocio(value) {
      //insertar unidad de negocio en lista si no está
      var indexUnidadNegocioEncontrado = null;
      indexUnidadNegocioEncontrado = this.obtenerIndexUnidadNegocio(value, this.unidadesNegocio);
      if (indexUnidadNegocioEncontrado == null) {
        Vue.set(this.unidadesNegocio, this.unidadesNegocio.length, value.id);
      } else {
        Vue.delete(this.unidadesNegocio, indexUnidadNegocioEncontrado);
      }
    },
    seleccionadoDescuento(value) {
      //insertar descuento en lista si no está
      var indexDescuento = null;
      indexDescuento = this.obtenerIndexDescuento(value, this.descuentos);
      if (indexDescuento == null) {
        Vue.set(this.descuentos, this.descuentos.length, value.id);
      } else {
        Vue.delete(this.descuentos, indexDescuento);
      }
    },
    obtenerIndexPais(value, arrayPaises) {
      for (var p in arrayPaises) {
        var pais = arrayPaises[p];
        if (pais == value.id) {
          return arrayPaises.indexOf(pais);
        }
      }
    },
    obtenerIndexUnidadNegocio(value, arrayUnidadesNegocio) {
      for (var u in arrayUnidadesNegocio) {
        var unidadNegocio = arrayUnidadesNegocio[u];
        if (unidadNegocio == value.id) {
          return arrayUnidadesNegocio.indexOf(unidadNegocio);
        }
      }
    },
    obtenerIndexDescuento(value, arrayDescuentos) {
      for (var dto in arrayDescuentos) {
        var descuento = arrayDescuentos[dto];
        if (descuento == value.id) {
          return arrayDescuentos.indexOf(descuento);
        }
      }
    },
    submit() {
      this.$emit("submit");
    },
  },
  watch: {},
};
</script>
