export const GENERAL = 1;
export const CUOTA = 2;
export const SUSCRIPCION = 3;
export const SERVICIOS = 4;
export const nombres = Object.freeze({
  [GENERAL]: "General",
  [CUOTA]: "Cuota",
  [SUSCRIPCION]: "Suscripción",
  [SERVICIOS]: "Implementacion",
});
export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default {
  GENERAL,
  CUOTA,
  SUSCRIPCION,
  SERVICIOS,
  nombres,
  combo,
};
