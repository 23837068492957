<template>
  <page-edicion title="Crear campaña">
    <formulario :campania="campania" :descuentos="descuentos" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiCampania from "@/services/campanias.js";

export default {
  components: { Formulario },
  data() {
    return {
      campania: apiCampania.crear(),
      descuentos: [],
    };
  },
  methods: {
    insertar() {
      apiCampania.insertar(this.campania, this.descuentos).then(() => {
        this.$emit("userMessage", "Se ha creado la campaña");
        this.$router.push({ path: "/campanias" });
      });
    },
    async cargarPlazosDescuento() {
      this.descuentos = await apiCampania.cargarPlazosDescuento();
    },
  },
  mounted() {
    this.cargarPlazosDescuento();
  },
};
</script>
