<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado titulo="País" campo="paisId"></th-ordenado>
      <th-ordenado titulo="Contacto" campo="contacto"></th-ordenado>
      <th-ordenado titulo="Creado el" campo="creadoEl"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!clientes" :colspan="5"></sin-registros>
      <tr v-for="(cliente, idx) in clientes" v-bind:key="idx" role="button">
        <td @click="editarCliente(cliente.id)">{{ cliente.nombre }}</td>
        <td @click="editarCliente(cliente.id)">{{ cliente.pais }}</td>
        <td @click="editarCliente(cliente.id)">{{ cliente.contacto }}</td>
        <td @click="editarCliente(cliente.id)">{{ cliente.creadoEl | fecha }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/clientes/editar/${cliente.id}`">
              <v-icon name="edit" width="16" />
              <span> Editar</span>
            </b-button>
            <b-button
              :disabled="cliente.numeroOfertas == 0"
              variant="success"
              class="actionButton"
              :to="`/ofertas/cliente/${cliente.id}`"
            >
              <v-icon name="check-square" width="16" />
              <span>
                Ofertas
                <small
                  ><b-badge pill variant="light">{{ cliente.numeroOfertas }}</b-badge></small
                ></span
              >
            </b-button>
            <b-button
              :disabled="cliente.numeroContratos == 0"
              variant="warning"
              class="actionButton"
              :to="`/contratos/cliente/${cliente.id}`"
            >
              <v-icon name="layers" width="16" />
              <span>
                Contratos
                <small
                  ><b-badge pill variant="light">{{ cliente.numeroContratos }}</b-badge></small
                ></span
              >
            </b-button>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    clientes: Array,
  },
  methods: {
    editarCliente(clienteId) {
      this.$router.push("/clientes/editar/" + clienteId);
    },
  },
};
</script>
