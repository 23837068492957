<template>
  <table id="TablaModulos" class="table table-bordered table-striped tablaModulos">
    <thead>
      <th width="100">Unidades</th>
      <th>Servicio</th>
      <th
        width="80"
        title="Define si el servicio cubre los costes de implementación de esta oferta"
        v-if="!calculada && !conTarifa"
      >
        Imple. Cubierta
      </th>
      <th width="120">Importe</th>
      <th width="80" v-if="!calculada && !conTarifa">Horas Consultoria</th>
      <th width="80" v-if="!calculada && !conTarifa">Horas Capacitación</th>
      <th width="120" class="acciones" v-if="borrador">Acciones</th>
    </thead>
    <draggable v-model="filasOrdenadas" tag="tbody" @change="soltarElemento">
      <tr v-for="(modulo, index) in filasOrdenadas" v-bind:key="index" :class="{ quitado: modulo.eliminado }">
        <!-- cantidad -->
        <td class="text-center">
          <b-form-spinbutton
            v-if="borrador"
            size="sm"
            required
            v-model="modulo.cantidad"
            min="1"
            max="99999"
            @input="actualizarServicios"
          ></b-form-spinbutton>
          <span v-else>{{ modulo.cantidad }}</span>
        </td>
        <!-- nombre del modulo -->
        <td>{{ modulo.nombre }}</td>
        <!-- Costes de implementación cubiertos -->
        <td class="text-center" v-if="!calculada && !conTarifa">
          <b-form-checkbox
            switch
            :value="true"
            :unchecked-value="false"
            v-model="modulo.cubreCosteDeImplementacion"
            @input="actualizarServicios"
            :disabled="!borrador"
          >
          </b-form-checkbox>
        </td>
        <!-- Importe del servicio -->
        <td class="text-center">
          <b-form-input
            required
            type="number"
            v-model="modulo.precioServicio"
            v-if="puedeEditarPrecioServicio(modulo)"
            @input="actualizarPrecioServicio(modulo)"
          ></b-form-input>
          <span v-else>{{ modulo.precioServicio }}</span>
        </td>
        <!-- Horas consultoria -->
        <td class="text-center" v-if="!calculada && !conTarifa">
          <b-form-input
            required
            type="number"
            v-model="modulo.numHorasConsultoria"
            v-if="puedeEditarHoras(modulo)"
            @input="actualizarServicios"
          ></b-form-input>
          <span v-else>{{ modulo.numHorasConsultoria }}</span>
        </td>
        <!-- Horas capacitacion -->
        <td class="text-center" v-if="!calculada && !conTarifa">
          <b-form-input
            required
            type="number"
            v-model="modulo.numHorasCapacitacion"
            v-if="puedeEditarHoras(modulo)"
            @input="actualizarServicios"
          ></b-form-input>
          <span v-else>{{ modulo.numHorasCapacitacion }}</span>
        </td>
        <!-- acciones -->
        <td class="acciones" v-if="borrador">
          <b-button
            size="sm"
            variant="danger"
            v-b-tooltip.hover
            class="bbutton-mini"
            v-if="!modulo.eliminado"
            @click="eliminarModulo(modulo)"
          >
            <v-icon variant="danger" name="trash-2" width="16" />
            Quitar
          </b-button>
          <b-button
            size="sm"
            variant="secondary"
            v-b-tooltip.hover
            class="bbutton-mini"
            v-if="modulo.eliminado"
            @click="restaurarModulo(modulo)"
          >
            <v-icon variant="secondary" name="corner-up-left" width="16" />
            Restaurar
          </b-button>
        </td>
      </tr>
    </draggable>
    <sin-registros :count="filasOrdenadas.length" :colspan="7"></sin-registros>
  </table>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  props: {
    borrador: {
      type: Boolean,
      required: true,
    },
    modulosOferta: {
      type: Array,
      required: true,
    },
    calculada: Boolean,
    conTarifa: Boolean,
  },
  components: {
    draggable,
  },
  data() {
    return {
      filasOrdenadas: this.modulosOferta,
      dragging: false,
    };
  },
  methods: {
    eliminarModulo(modulo) {
      modulo.eliminado = true;
      this.actualizarServicios();
    },
    restaurarModulo(modulo) {
      modulo.eliminado = false;
      this.actualizarServicios();
    },
    puedeEditarPrecioServicio(modulo) {
      return this.borrador && (modulo.permiteCambioPrecio || Boolean(this.esAdmin));
    },
    puedeEditarHoras(modulo) {
      return this.borrador && modulo.permiteCambioHoras;
    },
    soltarElemento() {
      for (let i = 0; i < this.filasOrdenadas.length; i++) {
        this.filasOrdenadas[i].ordenModulo = i;
      }
      this.$emit("actualizaModulos", this.filasOrdenadas);
    },
    actualizarServicios() {
      this.$emit("actualizaModulos", this.filasOrdenadas);
    },
    actualizarPrecioServicio(modulo) {
      modulo.precioModificado = true;
      this.$emit("actualizaModulos", this.filasOrdenadas);
    },
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  watch: {
    modulosOferta: function () {
      this.filasOrdenadas = this.modulosOferta;
    },
  },
};
</script>
