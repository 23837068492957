<template>
  <page-edicion title="Crear tarifa">
    <formulario :tarifa="tarifa" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/tarifas.js";

export default {
  components: { Formulario },
  data() {
    return {
      tarifa: api.crear(),
    };
  },
  methods: {
    insertar() {
      var tarifa = {
        tarifa: this.tarifa,
      };
      api.insertar(tarifa).then(() => {
        this.$router.push({ path: "/tarifas" });
      });
    },
  },
};
</script>
