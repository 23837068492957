import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: null,
    razonSocial: "",
    nombreComercial: "",
    identificadorFiscal: "",
    ciudad: "",
    provincia: "",
    codigoPostal: "",
    direccion: "",
    email: "",
    telefono: "",
    representanteLegal: "",
    identificadorRepresentanteLegal: "",
    estado: null,
    tarifaId: null,
    cartaBienvenida: "",
  };
}

function listar(params) {
  return listado("unidadNegocio/listar", params);
}

function listarPropios(params) {
  return listado("unidadNegocio/listarPropios", params);
}

function cargar(id) {
  return api.get("unidadNegocio/cargar&id=" + id).then((response) => {
    var data = response.data;

    data.unidadNegocio.estado = Boolean(data.unidadNegocio.estado);
    return data;
  });
}

function insertar(unidadNegocio) {
  return api.post("unidadNegocio/insertar", {
    unidadNegocio: unidadNegocio.unidadNegocio,
    paises: unidadNegocio.paises,
  });
}

function actualizar(unidadNegocio) {
  return api.post("unidadNegocio/actualizar", {
    unidadNegocio: unidadNegocio.unidadNegocio,
    paises: unidadNegocio.paises,
  });
}

function eliminar(id) {
  return api.post("unidadNegocio/eliminar", { id });
}

function obtenerPaises(id) {
  return api.get("unidadNegocio/obtenerPaises&id=" + id).then((r) => r.data);
}

export default { crear, listar, listarPropios, cargar, insertar, actualizar, eliminar, obtenerPaises };
