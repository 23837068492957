<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado class="text-center" titulo="Max. Usuarios" campo="maximoNumeroUsuarios"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!planes" :colspan="4"></sin-registros>
      <tr v-for="plan in planes" v-bind:key="plan.id" role="button">
        <td @click="editarPlan(plan.id)">{{ plan.nombre }}</td>
        <td @click="editarPlan(plan.id)" class="text-center">{{ plan.maximoNumeroUsuarios }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/planes/editar/${plan.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', plan.id)">
              ¿ Desea eliminar el plan <b>{{ plan.nombre }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    planes: Array,
  },
  methods: {
    editarPlan(planId) {
      this.$router.push("/planes/editar/" + planId);
    },
  },
};
</script>
