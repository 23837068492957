import { listado } from "./listado";
import api from "./backend";

export function crear() {
  return {
    modulo: crearModulo(),
    moduloContenido: crearModuloContenido(),
    imagenes: crearImagenes(),
    campanias: [],
  };
}

export function crearModulo() {
  return {
    id: null,
    tipo: 2,
    nombre: "",
    visibleEnTodasCampanias: true,
    permiteCambioPrecio: false,
    permiteCambioHoras: false,
    cubreCosteDeImplementacion: false,
    moduloRenovacion: false,
    precioModificado: false,
  };
}

export function crearModuloContenido() {
  return {
    descCorta: "",
    descLarga: "",
    logo: null,
    necesidad: "",
    solucion: "",
    rutaCaptura: null,
  };
}

export function crearImagenes() {
  return {
    logo: {},
    rutaCaptura: {},
  };
}

export function listar(params) {
  return listado("modulos/listar", params);
}

export async function cargar(id) {
  const response = await api.get("modulos/cargar&id=" + id);
  const modulo = response.data;

  normalizarModulo(modulo);

  return modulo;
}

export async function cargarCompleto(id) {
  const response = await api.get("modulos/cargarCompleto&id=" + id);
  const payload = response.data;

  normalizarModulo(payload.modulo);

  return payload;
}

export async function cargarContenido(id) {
  const response = await api.get("modulos/cargarContenido&id=" + id);
  return response.data;
}

export async function cargarPrecios(id) {
  const response = await api.get("modulos/cargarPrecios&id=" + id);
  const precios = response.data;
  return normalizarModuloPlanPrecios(precios);
}

export async function cargarPreciosTarifas(id) {
  const response = await api.get("modulos/cargarPreciosTarifas&id=" + id);
  const precios = response.data;
  return normalizarModuloTarifaPlanPrecios(precios);
}

export async function cargarHoras(id) {
  const response = await api.get("modulos/cargarHoras&id=" + id);
  const horas = response.data;
  return normalizarModuloPlanHoras(horas);
}

export function insertar(modulo) {
  return api.post("modulos/insertar", {
    modulo: modulo.modulo,
    imagenes: modulo.imagenes,
    moduloContenido: modulo.moduloContenido,
    campanias: modulo.campanias,
  });
}

export function actualizar(modulo) {
  return api.post("modulos/actualizar", {
    modulo: modulo.modulo,
    imagenes: modulo.imagenes,
    moduloContenido: modulo.moduloContenido,
    campanias: modulo.campanias,
  });
}

export function actualizarPrecios(id, moduloPlanPrecios) {
  return api.post("modulos/actualizarPrecios", {
    id: id,
    moduloPlanPrecios: denormalizarModuloPlanPrecios(moduloPlanPrecios),
  });
}

export function actualizarHoras(id, moduloPlanHoras) {
  return api.post("modulos/actualizarHoras", {
    id: id,
    moduloPlanHoras: denormalizarModuloPlanHoras(moduloPlanHoras),
  });
}

export function actualizarTarifaPrecios(id, moduloTarifaPlanPrecios) {
  return api.post("modulos/actualizarTarifaPrecios", {
    id: id,
    moduloTarifaPlanPrecios: denormalizarModuloTarifaPlanPrecios(moduloTarifaPlanPrecios),
  });
}

export function eliminar(id) {
  return api.post("modulos/eliminar", { id });
}

export function normalizarModulo(data) {
  data.visibleEnTodasCampanias = Boolean(data.visibleEnTodasCampanias);
  data.permiteCambioPrecio = Boolean(data.permiteCambioPrecio);
  data.permiteCambioHoras = Boolean(data.permiteCambioHoras);
  data.cubreCosteDeImplementacion = Boolean(data.cubreCosteDeImplementacion);
  data.moduloRenovacion = Boolean(data.moduloRenovacion);
  data.precioModificado = Boolean(data.precioModificado);
  return data;
}

function normalizarModuloPlanPrecios(moduloPlanPrecios) {
  return moduloPlanPrecios.reduce(
    (
      pd,
      {
        planId,
        divisaId,
        moduloId,
        precioCuota,
        precioSuscripcion,
        precioHoraCapacitacion,
        precioHoraConsultoria,
        precioServicio,
      }
    ) => {
      if (!pd.hasOwnProperty(planId)) {
        pd[planId] = {};
      }
      pd[planId][divisaId] = {
        moduloId: moduloId,
        precioCuota: precioCuota,
        precioSuscripcion: precioSuscripcion,
        precioHoraCapacitacion: precioHoraCapacitacion,
        precioHoraConsultoria: precioHoraConsultoria,
        precioServicio: precioServicio,
      };
      return pd;
    },
    {}
  );
}

function normalizarModuloPlanHoras(moduloPlanHoras) {
  return moduloPlanHoras.reduce((pd, { planId, moduloId, numHorasConsultoria, numHorasCapacitacion }) => {
    if (!pd.hasOwnProperty(planId)) {
      pd[planId] = {};
    }
    pd[planId] = {
      moduloId: moduloId,
      numHorasConsultoria: numHorasConsultoria,
      numHorasCapacitacion: numHorasCapacitacion,
    };
    return pd;
  }, {});
}

function denormalizarModuloPlanPrecios(moduloPlanPreciosNormalizados) {
  const moduloPlanPrecios = [];

  for (let planId in moduloPlanPreciosNormalizados) {
    for (let divisaId in moduloPlanPreciosNormalizados[planId]) {
      const mpp = moduloPlanPreciosNormalizados[planId][divisaId];
      const moduloId = mpp.moduloId;
      const precioCuota = mpp.precioCuota;
      const precioSuscripcion = mpp.precioSuscripcion;
      const precioHoraCapacitacion = mpp.precioHoraCapacitacion;
      const precioHoraConsultoria = mpp.precioHoraConsultoria;
      const precioServicio = mpp.precioServicio;

      moduloPlanPrecios.push({
        moduloId: moduloId,
        planId: planId,
        divisaId: divisaId,
        precioCuota: precioCuota,
        precioSuscripcion: precioSuscripcion,
        precioHoraCapacitacion: precioHoraCapacitacion,
        precioHoraConsultoria: precioHoraConsultoria,
        precioServicio: precioServicio,
      });
    }
  }

  return moduloPlanPrecios;
}
function denormalizarModuloPlanHoras(moduloPlanHorasNormalizados) {
  var moduloPlanHoras = [];

  for (let planId in moduloPlanHorasNormalizados) {
    const mph = moduloPlanHorasNormalizados[planId];

    const moduloId = mph.moduloId;
    const numHorasCapacitacion = mph.numHorasCapacitacion;
    const numHorasConsultoria = mph.numHorasConsultoria;

    moduloPlanHoras.push({
      moduloId: moduloId,
      planId: planId,
      numHorasCapacitacion: numHorasCapacitacion,
      numHorasConsultoria: numHorasConsultoria,
    });
  }

  return moduloPlanHoras;
}

function normalizarModuloTarifaPlanPrecios(moduloTarifaPlanPrecios) {
  return moduloTarifaPlanPrecios.reduce((pd, { planId, divisaId, moduloId, precio, tarifaId }) => {
    if (!pd.hasOwnProperty(tarifaId)) {
      pd[tarifaId] = {};
    }
    if (!pd[tarifaId].hasOwnProperty(planId)) {
      pd[tarifaId][planId] = {};
    }
    pd[tarifaId][planId][divisaId] = {
      moduloId: moduloId,
      precio: precio,
    };
    return pd;
  }, {});
}

function denormalizarModuloTarifaPlanPrecios(moduloTarifaPlanPreciosNormalizados) {
  const moduloTarifaPlanPrecios = [];

  for (let tarifaId in moduloTarifaPlanPreciosNormalizados) {
    for (let planId in moduloTarifaPlanPreciosNormalizados[tarifaId]) {
      for (let divisaId in moduloTarifaPlanPreciosNormalizados[tarifaId][planId]) {
        const mpp = moduloTarifaPlanPreciosNormalizados[tarifaId][planId][divisaId];
        const moduloId = mpp.moduloId;
        const precio = mpp.precio;

        moduloTarifaPlanPrecios.push({
          moduloId: moduloId,
          planId: planId,
          divisaId: divisaId,
          tarifaId: tarifaId,
          precio: precio,
        });
      }
    }
  }

  return moduloTarifaPlanPrecios;
}
