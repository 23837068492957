<template>
  <page-listado title="Repositorio Comercial" :fluid="true">
    <!-- <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/repositorioComercial/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear Repositorio Comercial
      </b-button>
    </template> -->
    <tabla :repositoriosComerciales="repositoriosComerciales" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import apiRepositoriosComerciales from "@/services/repositoriosComerciales.js";
import Tabla from "./TablaRepositoriosComerciales.vue";

export default {
  components: { Tabla },
  data() {
    return {
      repositoriosComerciales: [],
    };
  },
  methods: {
    async listar() {
      this.repositoriosComerciales = await apiRepositoriosComerciales.listar();
    },
    async eliminar(id) {
      await apiRepositoriosComerciales.eliminar(id);
      this.listar();
    },
  },
  mounted() {
    // this.listar();
  },
  watch: {
    "$route.params": {
      handler: function () {
        // this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
