import Listar from "@/views/duracionesContrato/Listar.vue";
import Crear from "@/views/duracionesContrato/Crear.vue";
import Editar from "@/views/duracionesContrato/Editar.vue";

export default [
  {
    path: "/duracionesContrato",
    component: Listar,
  },
  {
    path: "/duracionesContrato/crear",
    component: Crear,
  },
  {
    path: "/duracionesContrato/editar/:id",
    component: Editar,
  },
];
