<template>
  <page-edicion title="Crear plan">
    <formulario :plan="plan" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiPlanes from "@/services/planes.js";

export default {
  components: { Formulario },
  data() {
    return {
      plan: apiPlanes.crear(),
    };
  },
  methods: {
    insertar() {
      apiPlanes.insertar(this.plan).then(() => {
        this.$emit("userMessage", "Se ha creado el plan");
        this.$router.push({ path: "/planes" });
      });
    },
  },
};
</script>
