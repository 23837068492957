<template>
  <page>
    <formulario-oferta titulo="Crear Oferta" />
  </page>
</template>

<script>
import FormularioOferta from "./FormularioOferta/FormularioOferta.vue";

export default {
  components: { FormularioOferta },
};
</script>
