<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado width="360" titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado titulo="Campaña" campo="campaniaId"></th-ordenado>
      <th-ordenado titulo="Tipo" campo="tipo"></th-ordenado>
      <th title="Comportamientos" class="text-right">Comp.</th>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <tr v-for="modulo in modulos" v-bind:key="modulo.id" role="button">
        <td @click="editarModulo(modulo.id)">{{ modulo.nombre }}</td>
        <td @click="editarModulo(modulo.id)">
          <b-badge variant="success" v-if="modulo.visibleEnTodasCampanias"> Todas </b-badge>
          <span v-else-if="modulo.campanias">
            <b-badge variant="primary" class="mr-2" v-for="(c, idx) in campanias(modulo.campanias)" v-bind:key="idx">
              {{ c }}
            </b-badge>
          </span>
          <span v-else>
            <b-badge
              variant="danger"
              v-b-tooltip.hover.left
              title="Este módulo no será visible porque no está presente en ninguna campaña. Desactive el check de personalizar campañas en el módulo o bien elija una o más campañas"
            >
              Ninguna
            </b-badge>
          </span>
        </td>

        <td @click="editarModulo(modulo.id)">
          {{ nombreTipo(modulo.tipo) }}
        </td>

        <td @click="editarModulo(modulo.id)" class="text-right">
          <v-icon
            name="dollar-sign"
            width="24"
            class="mr-2"
            title="Precio editable"
            v-b-tooltip.hover
            v-if="modulo.permiteCambioPrecio"
          />
          <v-icon
            name="clock"
            width="24"
            title="Horas editables"
            v-b-tooltip.hover
            class="mr-2"
            v-if="modulo.permiteCambioHoras"
          />
          <v-icon
            name="box"
            width="24"
            class="mr-2"
            v-b-tooltip.hover
            title="Cubre costes de implantación"
            v-if="modulo.cubreCosteDeImplementacion"
          />
        </td>

        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/modulos/editar/${modulo.id}`">
              <v-icon variant="primary" name="edit" width="16" />
              <span>Editar</span>
            </b-button>
            <b-dropdown size="sm" right text="" variant="dark" class="actionButton">
              <template slot="button-content">
                <v-icon variant="primary" name="menu" width="16" />
              </template>

              <b-dropdown-item size="sm" :to="`/modulos/editarHoras/${modulo.id}`">
                <v-icon variant="primary" name="clock" width="16" />
                <span> Editar Horas</span>
              </b-dropdown-item>

              <b-dropdown-item size="sm" :to="`/modulos/editarPrecios/${modulo.id}`">
                <v-icon variant="primary" name="dollar-sign" width="16" />
                <span> Editar Precios</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <sin-registros v-if="!modulos.length" :colspan="5"></sin-registros>
    </tfoot>
  </table>
</template>
<script>
import { nombre as nombreTipo, color as colorTipo } from "@/enums/TipoModulo";

export default {
  props: {
    modulos: Array,
  },
  methods: {
    nombreTipo,
    colorTipo,
    campanias(value) {
      if (value == "") {
        return "-";
      } else {
        return value.toString().split(",");
      }
    },
    editarModulo(moduloId) {
      this.$router.push("/modulos/editar/" + moduloId);
    },
  },
};
</script>

<style>
.listado .badge {
  font-size: 0.9em;
  line-height: 1.1em;
}
</style>
