<template>
  <page-listado title="Módulos" con-busqueda :fluid="true">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/modulos/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear modulo
      </b-button>
    </template>
    <tabla :modulos="resultado.modulos"></tabla>
    <paginacion :page-count="resultado.numeroPaginas" :page="resultado.pagina"></paginacion>
  </page-listado>
</template>

<script>
import * as apiModulos from "@/services/modulos.js";
import Tabla from "./TablaModulos.vue";
export default {
  components: { Tabla },
  data() {
    return {
      resultado: {
        modulos: [],
        numeroPaginas: 0,
        pagina: 1,
      },
    };
  },
  methods: {
    listar() {
      return apiModulos.listar(this.$route.query).then((resultado) => {
        this.resultado = resultado;
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
