<template>
  <page-edicion title="Editar plantilla">
    <formulario :plantillaContenido="plantillaContenido" :paisesId="paisesId" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/plantillasContenido.js";

export default {
  components: { Formulario },
  data() {
    return {
      plantillaContenido: api.crear(),
      paisesId: [],
    };
  },
  methods: {
    insertar(paisesSeleccionados) {
      var plantilla = {
        plantillaContenido: this.plantillaContenido,
        paises: paisesSeleccionados,
      };
      api.insertar(plantilla).then(() => {
        this.$router.push({ path: "/plantillasContenido" });
      });
    },
  },
  mounted() {},
};
</script>
