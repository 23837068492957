<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado titulo="Meses" campo="meses"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!duracionesContrato" :colspan="4"></sin-registros>
      <tr v-for="(duracionContrato, idx) in duracionesContrato" v-bind:key="idx" role="button">
        <td @click="editarDuracionContrato(duracionContrato.id)">{{ duracionContrato.nombre }}</td>
        <td @click="editarDuracionContrato(duracionContrato.id)">{{ duracionContrato.meses }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/duracionesContrato/editar/${duracionContrato.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    duracionesContrato: Array,
  },
  methods: {
    editarDuracionContrato(duracionContratoId) {
      this.$router.push("/duracionesContrato/editar/" + duracionContratoId);
    },
  },
};
</script>
