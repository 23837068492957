export const VENCIDO = 0;
export const MENOSUNMES = 1;
export const ENTREUNOYDOSMESES = 2;
export const MASDOSMESES = 3;

export const nombres = Object.freeze({
  [VENCIDO]: "Vencido",
  [MENOSUNMES]: "Menos de un mes",
  [ENTREUNOYDOSMESES]: "Entre uno y dos meses",
  [MASDOSMESES]: "Más de dos meses",
});

export const colores = Object.freeze({
  [VENCIDO]: "#dc3545",
  [MENOSUNMES]: "#ffc107",
  [ENTREUNOYDOSMESES]: "#218838",
  [MASDOSMESES]: "#0095e0",
});

export const combo = Object.keys(nombres).map((k) => {
  return { id: k, text: nombres[k], color: colores[k] };
});

export default { VENCIDO, MENOSUNMES, ENTREUNOYDOSMESES, MASDOSMESES, nombres, colores, combo };
