<!--
TODO: Poner el estado y la fecha del estado dentro del formulario oferta
-->
<template>
  <page-listado title="Ofertas">
    <template slot="toolbar">
      <b-button-group>
        <b-button variant="primary" size="sm" to="/ofertas/crear">
          <v-icon name="plus" width="16"></v-icon>
          Crear oferta
        </b-button>
      </b-button-group>
    </template>

    <template slot="filters">
      <filtros-ofertas ref="filtros" v-show="mostrarFiltros" @filtrar="filtrar" :filtrosIniciales="filtrosIniciales" />
      <productividad-comercial
        :ganadas="Number(productividad.ganadas)"
        :perdidas="Number(productividad.perdidas)"
        :pendientes="Number(productividad.pendientes)"
        :ratioProductividad="Number(productividad.ratioProductividad)"
        :diasPromedio="Number(productividad.diasPromedio)"
      />
    </template>

    <div class="mt-4 text-right" style="user-select: none">
      <b-button size="sm" variant="white" @click="toggleColumnConfig">
        <b-icon icon="table" />
        Configurar columnas
      </b-button>
      <div class="alert alert-warning mt-2 mb-4" v-show="showColumnConfig">
        <b-form-checkbox-group switches v-model="columnasVisibles" :options="columnOptions" />
        <small><i>* esta configuración se guarda en tu navegador y no afecta a otros usuarios.</i></small>
      </div>
    </div>

    <tabla-ofertas :ofertas="resultado.ofertas" @ofertaCambiada="ofertaCambiada" :columnasVisibles="columnasVisibles" />

    <b-row class="mt-4">
      <b-col>
        <b-button to="/papelera" title="Papelera">
          <v-icon name="trash" width="16" />
          <span>Papelera</span>
        </b-button>
      </b-col>
      <b-col>
        <paginacion
          :page-count="parseInt(resultado.numeroPaginas)"
          :page="parseInt(resultado.pagina)"
          :totalItems="parseInt(resultado.totalItems)"
        />
      </b-col>
    </b-row>
  </page-listado>
</template>

<script>
import { mapState } from "vuex";
import apiOfertas from "@/services/ofertas.js";
import { productividadComercial } from "@/services/productividadComercial.js";
import TablaOfertas from "./TablaOfertas.vue";
import FiltrosOfertas from "./FiltrosOfertas.vue";
import ProductividadComercial from "./ProductividadComercial.vue";
import { procesarFiltros } from "@/services/listado.js";

export default {
  components: {
    TablaOfertas,
    FiltrosOfertas,
    ProductividadComercial,
  },
  data() {
    const filtros = Object.assign({}, this.$route.query);
    const filtrosIniciales = procesarFiltros(filtros);
    return {
      filtrosIniciales: filtrosIniciales,
      mostrarFiltros: true,
      showColumnConfig: false,
      columnOptions: [
        { value: "campania", text: "Campaña" },
        { value: "numDias", text: "Días" },
        { value: "estado", text: "Estado" },
        { value: "pipe", text: "Pipedrive" },
        { value: "version", text: "Versión" },
        { value: "fecha", text: "Fecha Oferta" },
        { value: "fechaPrevistaCierre", text: "FP/Cierre" },
        { value: "propietario", text: "Propietario" },
        { value: "plan", text: "Plan" },
        { value: "info", text: "Info" },
      ],
      columnasVisibles: ["campania", "numDias", "estado", "pipe", "version", "fecha", "propietario", "plan", "info"],
      resultado: {
        ofertas: [],
        numeroPaginas: 0,
        pagina: 1,
      },
      productividad: {
        ganadas: 0,
        perdidas: 0,
        pendientes: 0,
        ratioProductividad: 0,
        diasPromedio: 0,
      },
    };
  },
  computed: {
    ...mapState("session", ["usuarioId", "esAdmin", "esResponsable", "esPartner"]),
  },
  methods: {
    toggleColumnConfig() {
      this.showColumnConfig = !this.showColumnConfig;
    },
    toggleFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    async listar() {
      this.resultado = await apiOfertas.listar(this.$route.query);
      this.productividad = await productividadComercial(this.$route.query);
      await this.$refs.filtros.actualizarComboUsauriosTrasBusqueda();
    },
    async ofertaCambiada(id) {
      this.$store.dispatch("setUltimaOfertaModificada", id);
      await this.listar();
    },
    filtrar(data) {
      this.$router.push({ path: this.$route.path, query: data }).catch((err) => err);
    },
  },
  mounted() {
    if (localStorage.getItem("ofertas.columnas.visibles")) {
      this.columnasVisibles = JSON.parse(localStorage.getItem("ofertas.columnas.visibles"));
    }
  },
  watch: {
    columnasVisibles() {
      localStorage.setItem("ofertas.columnas.visibles", JSON.stringify(this.columnasVisibles));
    },
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
