<template>
  <b-form @submit.prevent="submit">
    <b-row class="mb-3">
      <b-col lg="6">
        <b-form-group label="Descripción">
          <b-form-input
            required
            type="text"
            v-model="descuento.descripcion"
            placeholder="Descripción del descuento"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Descuento">
          <b-form-input required type="number" v-model="descuento.descuento" placeholder="Descuento"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="4">
        <b-form-group label="Por usuario">
          <b-form-checkbox switch size="lg" v-model="descuento.dtoUsuario"> Descuento por usuario </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="descuento.estado"> Activo </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion url-cancelar="/descuentos" />
  </b-form>
</template>
<script>
export default {
  props: {
    descuento: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
  watch: {
    "$route.params": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },
};
</script>
