import Listar from "@/views/campanias/Listar.vue";
import Crear from "@/views/campanias/Crear.vue";
import Editar from "@/views/campanias/Editar.vue";

export default [
  {
    path: "/campanias",
    component: Listar,
  },
  {
    path: "/campanias/crear",
    component: Crear,
  },
  {
    path: "/campanias/editar/:id",
    component: Editar,
  },
];
