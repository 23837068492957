<template>
  <page-edicion title="Crear descuento">
    <formulario :descuento="descuento" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/tipoDescuento.js";

export default {
  components: { Formulario },
  data() {
    return {
      descuento: api.crear(),
    };
  },
  methods: {
    insertar() {
      var descuento = {
        descuento: this.descuento,
      };
      api.insertar(descuento).then(() => {
        this.$router.push({ path: "/descuentos" });
      });
    },
  },
};
</script>
