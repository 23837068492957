<template>
  <page>
    <div class="content-card" v-if="oferta.id">
      <h1>{{ oferta.descripcion }}: editar contenido de módulos</h1>
      <b-form @submit.prevent="guardar">
        <b-tabs small card pills vertical>
          <b-tab v-for="modulo in modulos" v-bind:key="modulo.id" :title="modulo.nombre">
            <b-form-group label="Descripción corta">
              <wysiwyg placeholder="Escriba aquí" v-model="modulo.contenido.descCorta" />
            </b-form-group>
            <b-form-group label="Descripción larga">
              <wysiwyg placeholder="Escriba aquí" v-model="modulo.contenido.descLarga" />
            </b-form-group>
            <b-form-group label="Necesidad">
              <wysiwyg placeholder="Escriba aquí" v-model="modulo.contenido.necesidad" />
            </b-form-group>
            <b-form-group label="Solución">
              <wysiwyg placeholder="Escriba aquí" v-model="modulo.contenido.solucion" />
            </b-form-group>

            <b-button @click="restablecer(modulo.moduloId)">
              Restablecer contenidos a partir del módulo original
            </b-button>
          </b-tab>
        </b-tabs>

        <botonera-edicion url-cancelar="/ofertas" />
      </b-form>
    </div>
  </page>
</template>

<script>
import apiOfertas from "@/services/ofertas.js";
import * as apiModulos from "@/services/modulos.js";

export default {
  components: {},
  data() {
    return {
      oferta: {
        descripcion: "",
      },
      modulos: [],
    };
  },
  methods: {
    async guardar() {
      var modulos = this.modulos.map((m) => m.contenido);
      await apiOfertas.guardarContenidoModulos(this.oferta.id, modulos);
      this.$router.push("/ofertas");
    },
    async restablecer(moduloId) {
      var contenido = await apiModulos.cargarContenido(moduloId);
      var modulo = this.modulos.find((m) => m.moduloId == moduloId);
      modulo.contenido.descCorta = contenido.descCorta;
      modulo.contenido.descLarga = contenido.descLarga;
      modulo.contenido.necesidad = contenido.necesidad;
      modulo.contenido.solucion = contenido.solucion;
    },
  },
  async mounted() {
    var data = await apiOfertas.cargar(this.$route.params.id);
    this.oferta = data.oferta;
    this.modulos = data.ofertaModulos.map((m) => {
      m.opened = false;
      m.contenido = data.ofertaModulosContenido.find((c) => c.ofertaModuloId == m.id);
      return m;
    });
  },
};
</script>
