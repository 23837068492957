<template>
  <b-form @submit.prevent="submit">
    <b-form-group label="Nombre">
      <b-form-input required type="text" v-model="divisa.nombre" placeholder="Nombre de la divisa"></b-form-input>
    </b-form-group>
    <b-form-group label="Abreviatura">
      <b-form-input
        required
        type="text"
        v-model="divisa.abreviatura"
        maxlength="4"
        placeholder="Abreviatura de la divisa"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Símbolo">
      <b-form-input
        required
        type="text"
        v-model="divisa.simbolo"
        maxlength="4"
        placeholder="Símbolo de la divisa"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Paises">
      <combo-multiple
        :value="paisesIdCombo"
        :options="opcionesPaises"
        url="paises/autocompletar"
        @input="selectPaises"
      ></combo-multiple>
    </b-form-group>
    <botonera-edicion url-cancelar="/divisas" />
  </b-form>
</template>
<script>
import apiPaises from "@/services/paises.js";
export default {
  props: {
    divisa: Object,
    paisesId: Array,
    opcionesPaises: Array,
  },
  data() {
    return {
      listaPaises: [],
      paisesIdCombo: this.paisesId ? this.paisesId : [],
      paisesSeleccionados: [],
    };
  },
  methods: {
    listarPaises() {
      return apiPaises.listar(this.$route.query).then((resultado) => {
        this.listaPaises = resultado.paises;
      });
    },
    selectPaises(paisesCombo) {
      this.paisesSeleccionados = [];
      var p = {};
      for (p in paisesCombo) {
        this.paisesSeleccionados.push({
          divisaId: this.divisa.id,
          paisId: paisesCombo[p],
        });
      }
    },
    submit() {
      this.$emit("submit", this.paisesSeleccionados);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listarPaises();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
