export const BASE = 1;
export const MODULO = 2;
export const SERVICIO = 3;

export function nombre(tipo) {
  switch (tipo) {
    case BASE:
      return "Base";
    case MODULO:
      return "Módulo";
    case SERVICIO:
      return "Servicio";
  }
}

export function color(tipo) {
  switch (tipo) {
    case BASE:
      return "info";
    case MODULO:
      return "success";
    case SERVICIO:
      return "primary";
  }
}
