<template>
  <b-button-group size="sm">
    <b-button
      variant="primary"
      class="actionButton"
      title="Recuperar"
      @click="recuperarVersionDePapelera(oferta.id, oferta.descripcion)"
    >
      Recuperar
    </b-button>
    <b-dropdown size="sm" right text="" variant="dark" class="actionButton">
      <template slot="button-content">
        <v-icon name="menu" width="16" />
      </template>

      <b-dropdown-item
        :disabled="noPuedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeConsultor&ofertaId=${oferta.id}`"
        ><v-icon name="printer" width="16" /> Informe Consultor</b-dropdown-item
      ><b-dropdown-item
        :disabled="noPuedeImprimir"
        size="sm"
        target="_blank"
        :href="`${url}/ofertas/informeComercial&ofertaId=${oferta.id}`"
        ><v-icon name="printer" width="16" /> Informe Comercial</b-dropdown-item
      >

      <b-dropdown-divider v-if="oferta.pipedrive"></b-dropdown-divider>
      <b-dropdown-item size="sm" target="_blank" v-if="oferta.pipedrive" :href="oferta.pipedrive"
        ><v-icon variant="primary" name="database" width="16" /> Abrir en Pipedrive</b-dropdown-item
      >
    </b-dropdown>
  </b-button-group>
</template>
<script>
import apiOfertas from "@/services/ofertas";

export default {
  props: {
    oferta: Object,
  },
  computed: {
    noPuedeImprimir() {
      //return this.oferta.visada == 0;
      return false;
    },
  },
  methods: {
    async recuperarVersionDePapelera(id, descripcion) {
      await apiOfertas.recuperarVersionDePapelera(id);
      this.$root.$emit("userMessage", `Oferta '${descripcion}' recuperada de la Papelera`);
      this.$router.push("/ofertas");
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_ENDPOINT,
    };
  },
};
</script>
