<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Descripción" campo="descripcion"></th-ordenado>
      <th-ordenado titulo="Factor" campo="factorCorreccion"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros :colspan="3" v-if="!complejidadesProyecto"> </sin-registros>
      <tr v-for="complejidadProyecto in complejidadesProyecto" v-bind:key="complejidadProyecto.id" role="button">
        <td @click="editarComplejidadesProyecto(complejidadProyecto.id)">{{ complejidadProyecto.descripcion }}</td>
        <td @click="editarComplejidadesProyecto(complejidadProyecto.id)" class="text-center">
          {{ complejidadProyecto.factorCorreccion }}
        </td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button
              variant="primary"
              class="actionButton"
              :to="`/complejidadesProyecto/editar/${complejidadProyecto.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    complejidadesProyecto: Array,
  },
  methods: {
    editarComplejidadesProyecto(complejidadProyectoId) {
      this.$router.push("/complejidadesProyecto/editar/" + complejidadProyectoId);
    },
  },
};
</script>
