<template>
  <div class="tabla-modulos">
    <table id="TablaModulos" class="table table-bordered table-striped tablaModulos">
      <thead>
        <th width="100">Unidades</th>
        <th>Módulo</th>
        <th width="120" v-if="!calculada || !conTarifa">Suscripción</th>
        <th width="120" v-if="!calculada || !conTarifa">Cuota Anual</th>
        <th width="120" v-if="calculada && conTarifa">Precio</th>
        <th width="80" v-if="!calculada || !conTarifa">Horas Consultoría</th>
        <th width="80" v-if="!calculada || !conTarifa">Horas Capacitación</th>
        <th width="120" class="acciones" v-if="borrador">Acciones</th>
      </thead>
      <draggable v-model="filasOrdenadas" tag="tbody" @change="soltarElemento">
        <tr v-for="(modulo, index) in filasOrdenadas" :key="index" :class="{ quitado: modulo.eliminado }">
          <td class="text-center">
            <b-form-spinbutton
              v-if="borrador"
              size="sm"
              required
              v-model="modulo.cantidad"
              min="1"
              max="99999"
              @input="actualizarModulos"
            ></b-form-spinbutton>
            <span v-else>{{ modulo.cantidad }}</span>
          </td>
          <td>{{ modulo.nombre }}</td>
          <td v-if="!calculada || !conTarifa">
            <b-form-input
              required
              type="number"
              v-model="modulo.precioSuscripcion"
              min="0"
              v-if="puedeEditarPrecioSuscripcion(modulo)"
              @input="actualizarPrecioModulo(modulo)"
            ></b-form-input>
            <span v-else>{{ modulo.precioSuscripcion }}</span>
          </td>
          <td v-if="!calculada || !conTarifa">
            <b-form-input
              required
              type="number"
              v-model="modulo.precioCuota"
              min="0"
              v-if="puedeEditarPrecioCuota(modulo)"
              @input="actualizarPrecioModulo(modulo)"
            ></b-form-input>
            <span v-else>{{ modulo.precioCuota }}</span>
          </td>
          <td class="text-center" v-if="calculada && conTarifa">
            <b-form-input
              required
              type="number"
              v-model="modulo.precioTarifa"
              min="0"
              v-if="puedeEditarPrecioCuota(modulo)"
              @input="actualizarModulosTarifa(modulo)"
            ></b-form-input>
            <span v-else>{{ modulo.precioTarifa }}</span>
          </td>
          <td class="text-center" v-if="!calculada || !conTarifa">
            <b-form-input
              required
              type="number"
              min="0"
              v-model="modulo.numHorasConsultoria"
              v-if="puedeEditarHoras(modulo)"
              @input="actualizarModulos"
            ></b-form-input>
            <span v-else>{{ modulo.numHorasConsultoria }}</span>
          </td>
          <td class="text-center" v-if="!calculada || !conTarifa">
            <b-form-input
              required
              type="number"
              min="0"
              v-model="modulo.numHorasCapacitacion"
              v-if="puedeEditarHoras(modulo)"
              @input="actualizarModulos"
            ></b-form-input>
            <span v-else>{{ modulo.numHorasCapacitacion }}</span>
          </td>
          <td class="acciones" v-if="borrador">
            <b-button
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              class="bbutton-mini"
              v-if="!modulo.eliminado"
              @click="eliminarModulo(modulo)"
            >
              <v-icon variant="danger" name="trash-2" width="16" />
              Quitar
            </b-button>
            <b-button
              size="sm"
              variant="secondary"
              v-b-tooltip.hover
              class="bbutton-mini"
              v-if="modulo.eliminado"
              @click="restaurarModulo(modulo)"
            >
              <v-icon variant="secondary" name="corner-up-left" width="16" />
              Restaurar
            </b-button>
          </td>
        </tr>
      </draggable>
      <sin-registros :count="filasOrdenadas.length" :colspan="7"></sin-registros>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  props: {
    borrador: {
      type: Boolean,
      required: true,
    },
    modulosOferta: {
      type: Array,
      required: true,
    },
    calculada: Boolean,
    conTarifa: Boolean,
  },
  components: {
    draggable,
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  data() {
    return {
      filasOrdenadas: this.modulosOferta,
      dragging: false,
    };
  },
  methods: {
    eliminarModulo(modulo) {
      modulo.eliminado = true;
      this.actualizarModulos();
    },
    restaurarModulo(modulo) {
      modulo.eliminado = false;
      this.actualizarModulos();
    },
    puedeEditarPrecioSuscripcion(modulo) {
      return this.borrador && modulo.permiteCambioPrecio > 0;
    },
    puedeEditarPrecioCuota(modulo) {
      return this.borrador && (modulo.permiteCambioPrecio > 0 || Boolean(this.esAdmin));
    },
    puedeEditarHoras(modulo) {
      return this.borrador && modulo.permiteCambioHoras > 0;
    },
    soltarElemento() {
      for (let i = 0; i < this.filasOrdenadas.length; i++) {
        this.filasOrdenadas[i].ordenModulo = i;
      }
      this.$emit("actualizaModulos", this.filasOrdenadas);
    },
    actualizarModulos() {
      this.comprobarImporteTarifa();
      this.$emit("actualizaModulos", this.filasOrdenadas);
    },
    comprobarImporteTarifa() {
      this.filasOrdenadas.forEach((fila) => {
        if (fila.precioTarifa == "") fila.precioTarifa = 0;
      });
    },
    actualizarModulosTarifa(modulo) {
      modulo.precioModificado = true;
      this.actualizarModulos();
    },
    actualizarPrecioModulo(modulo) {
      modulo.precioModificado = true;
      this.actualizarModulos();
    },
  },
  watch: {
    modulosOferta: function () {
      this.filasOrdenadas = this.modulosOferta;
    },
  },
};
</script>
