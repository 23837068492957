<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Razón Social" campo="razonSocial"></th-ordenado>
      <th-ordenado class="text-center" titulo="Nombre Comercial" campo="nombreComercial"></th-ordenado>
      <th class="th-acciones" width="260">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!unidadesNegocio" :colspan="4"></sin-registros>
      <tr v-for="unidadNegocio in unidadesNegocio" v-bind:key="unidadNegocio.id" role="button">
        <td @click="editarUnidadNegocio(unidadNegocio.id)">{{ unidadNegocio.razonSocial }}</td>
        <td @click="editarUnidadNegocio(unidadNegocio.id)" class="text-center">{{ unidadNegocio.nombreComercial }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/unidadesNegocio/editar/${unidadNegocio.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', unidadNegocio.id)">
              ¿ Desea eliminar la unidad de negocio
              <b>{{ unidadNegocio.nombreComercial }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    unidadesNegocio: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editarUnidadNegocio(unidadNegocioId) {
      this.$router.push("/unidadesNegocio/editar/" + unidadNegocioId);
    },
  },
};
</script>
