<template>
  <page-edicion title="Editar modulo">
    <formulario-datos @guardado="volver"></formulario-datos>
  </page-edicion>
</template>

<script>
import FormularioDatos from "./FormularioDatos.vue";

export default {
  components: { FormularioDatos },
  methods: {
    volver() {
      this.$router.push({ path: "/modulos" });
    },
  },
};
</script>
