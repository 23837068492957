<template>
  <page-listado :fluid="true" title="Planes">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/planes/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear plan
      </b-button>
    </template>
    <tabla :planes="planes" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import api from "@/services/planes.js";
import Tabla from "./TablaPlanes.vue";
export default {
  components: { Tabla },
  data() {
    return {
      planes: [],
    };
  },
  methods: {
    listar() {
      return api.listar(this.$route.query).then((planes) => {
        this.planes = planes;
      });
    },
    eliminar(id) {
      api.eliminar(id).then(() => {
        this.listar();
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
