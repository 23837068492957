<template>
  <page-edicion title="Editar descuento">
    <formulario :descuento="descuento" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/tipoDescuento.js";

export default {
  components: { Formulario },
  data() {
    return {
      descuento: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.descuento = response;
      });
    },
    actualizar() {
      var descuento = {
        descuento: this.descuento,
      };
      api.actualizar(descuento).then(() => {
        this.$router.push({ path: "/descuentos" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
