import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
  };
}

function listar(params) {
  return listado("complejidadesProyecto/listar", params);
}

function cargar(id) {
  return api.get("complejidadesProyecto/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(complejidadProyecto) {
  return api.post("complejidadesProyecto/insertar", { complejidadProyecto });
}

function actualizar(complejidadProyecto) {
  return api.post("complejidadesProyecto/actualizar", {
    complejidadProyecto,
  });
}

function eliminar(id) {
  return api.post("complejidadesProyecto/eliminar", { id });
}

export default { crear, listar, cargar, insertar, actualizar, eliminar };
