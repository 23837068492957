import Listar from "@/views/clientes/Listar.vue";
import Crear from "@/views/clientes/Crear.vue";
import Editar from "@/views/clientes/Editar.vue";

export default [
  {
    path: "/clientes",
    component: Listar,
  },
  {
    path: "/clientes/crear",
    component: Crear,
  },
  {
    path: "/clientes/editar/:id",
    component: Editar,
  },
];
