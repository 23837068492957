<template>
  <b-container fluid class="mt-4">
    <b-card>
      <h1>Estadísticas de módulos</h1>

      <div style="max-width: 720px">
        <b-card bg-variant="light" header="Selección de módulos" class="mt-4 shadow-sm">
          <b-form @submit.prevent="cargar">
            <b-form-group label="Módulos">
              <combo-multiple v-model="modulos" url="modulos/autocompletar"> </combo-multiple>
            </b-form-group>
            <b-button type="submit" variant="primary" @click="cargar"> Cargar estadísticas </b-button>
          </b-form>
        </b-card>
      </div>

      <b-card header="Tabla dinámica" class="mt-4 shadow-sm">
        <pivot
          :data="data"
          :fields="fields"
          :available-field-keys="availableFieldKeys"
          :row-field-keys="rowFieldKeys"
          :col-field-keys="colFieldKeys"
          :reducer="reducer"
          :default-show-settings="defaultShowSettings"
          available-fields-label-text="Campos disponibles"
          rows-label-text="Filas"
          cols-label-text="Columnas"
          hide-settings-text="Ocultar opciones"
          show-settings-text="Mostrar opciones"
          select-all-text="Seleccionar todos"
          unselect-all-text="Deseleccionar todos"
        >
          <template slot="value" slot-scope="{ value }">
            {{ value.toLocaleString() }}
          </template>
        </pivot>
      </b-card>
    </b-card>
  </b-container>
</template>

<script>
import * as apiEstadisticas from "@/services/estadisticas.js";

// PivotTable (https://github.com/Click2Buy/vue-pivot-table)
import Pivot from "@marketconnect/vue-pivot-table";

function makeField(name, label) {
  return {
    key: name,
    getter: (item) => item[name],
    label: label,
    valueFilter: true,
  };
}

export default {
  components: { Pivot },
  data: () => {
    return {
      data: [],
      modulos: [],
      // Pivot params
      fields: [
        makeField("modulo", "Módulo"),
        makeField("comercial", "Comercial"),
        makeField("pais", "País"),
        makeField("estado", "Estado"),
        makeField("anio", "Año"),
        makeField("mes", "Mes"),
        makeField("campania", "Campaña"),
        makeField("origen", "Origen"),
        makeField("tipoVenta", "TipoVenta"),
      ],
      availableFieldKeys: ["comercial", "pais", "campania", "origen", "tipoVenta", "mes"],
      rowFieldKeys: ["modulo", "estado"],
      colFieldKeys: ["anio"],
      reducer: (sum, item) => sum + Number(item.total),
      defaultShowSettings: true,
    };
  },
  methods: {
    async cargar() {
      this.data = await apiEstadisticas.modulos(this.modulos);
    },
  },
  mounted() {
    //this.cargar();
  },
};
</script>
