import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
  };
}

function listar(params) {
  return listado("plantillasContenido/listar", params);
}

function cargar(id) {
  return api.get("plantillasContenido/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(plantillaContenido) {
  return api.post("plantillasContenido/insertar", {
    plantillaContenido: plantillaContenido.plantillaContenido,
    paises: plantillaContenido.paises,
  });
}

function actualizar(plantillaContenido) {
  return api.post("plantillasContenido/actualizar", {
    plantillaContenido: plantillaContenido.plantillaContenido,
    paises: plantillaContenido.paises,
  });
}

function eliminar(id) {
  return api.post("plantillasContenido/eliminar", { id });
}

export default { crear, listar, cargar, insertar, actualizar, eliminar };
