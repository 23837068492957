export const ANUAL = 1;
export const MENSUAL = 2;
export const BIENAL = 3;
export const TRIENAL = 4;
export const CUATRIENAL = 5;

export const nombres = Object.freeze({
  [CUATRIENAL]: "Pago único 48 Meses",
  [TRIENAL]: "Pago único 36 Meses",
  [BIENAL]: "Pago único 24 Meses",
  [ANUAL]: "Pago único 12 Meses",
  [MENSUAL]: "Pago Mensual",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export const duracionesContrato = Object.freeze({
  [1]: [MENSUAL, ANUAL],
  [2]: [MENSUAL, ANUAL, BIENAL],
  [3]: [MENSUAL, ANUAL, BIENAL, TRIENAL],
  [4]: [MENSUAL, ANUAL, BIENAL, TRIENAL, CUATRIENAL],
});

export default { CUATRIENAL, TRIENAL, BIENAL, ANUAL, MENSUAL, nombres, combo, duracionesContrato };
