import Vue from "vue";
import VueRouter from "vue-router";

import inicio from "./inicio";
import ofertas from "./ofertas";
import plantillas from "./plantillas";
import papelera from "./papelera";
import clientes from "./clientes";
import modulos from "./modulos";
import paises from "./paises";
import divisas from "./divisas";
import formasPago from "./formasPago";
import duracionesContrato from "./duracionesContrato";
import complejidadesProyecto from "./complejidadesProyecto";
import planes from "./planes";
import preciosHora from "./preciosHora";
import plantillasContenido from "./plantillasContenido";
import usuarios from "./usuarios";
import campanias from "./campanias";
import origenOferta from "./origenOferta";
import estadisticas from "./estadisticas";
import unidadesNegocio from "./unidadesNegocio";
import plantillasContrato from "./plantillasContrato";
import tarifas from "./tarifas";
import tiposDescuento from "./tiposDescuentos";
import contratosClientes from "./contratosClientes";
import repositoriosComerciales from "./repositoriosComerciales";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  ...inicio,
  ...ofertas,
  ...plantillas,
  ...papelera,
  ...clientes,
  ...modulos,
  ...paises,
  ...divisas,
  ...formasPago,
  ...duracionesContrato,
  ...complejidadesProyecto,
  ...planes,
  ...preciosHora,
  ...plantillasContenido,
  ...usuarios,
  ...campanias,
  ...origenOferta,
  ...estadisticas,
  ...unidadesNegocio,
  ...plantillasContrato,
  ...tarifas,
  ...tiposDescuento,
  ...contratosClientes,
  ...repositoriosComerciales,
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  var isAuthenticated = store.getters["session/loggedIn"];

  if (to.path !== "/login" && !isAuthenticated) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
