<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input
        required
        type="text"
        v-model="duracionContrato.nombre"
        placeholder="Nombre de la duración del contrato"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Meses">
      <b-form-input
        required
        type="number"
        v-model="duracionContrato.meses"
        placeholder="Meses de duración del contrato"
      ></b-form-input>
    </b-form-group>
    <botonera-edicion url-cancelar="/duracionesContrato" />
  </b-form>
</template>
<script>
export default {
  props: {
    duracionContrato: Object,
  },
};
</script>
