<template>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand href="/" />
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-nav vertical class="p-0" v-if="loggedIn">
        <b-nav-item to="/ofertas" title="Propuestas">
          <v-icon name="check-square" width="16" />
          <span class="sm-hidden">Propuestas</span>
        </b-nav-item>
        <b-nav-item to="/clientes" title="Clientes">
          <v-icon name="users" width="16" />
          <span class="sm-hidden">Clientes</span>
        </b-nav-item>
        <b-nav-item to="/contratos" title="Contratos" v-if="esAdmin || esPartner || esResponsable">
          <v-icon name="layers" width="16" />
          <span class="sm-hidden">Contratos</span>
        </b-nav-item>
        <b-nav-item to="/repositorioComercial" title="Repositorio Comercial">
          <v-icon name="archive" width="16" />
          <span class="sm-hidden">Repositorio Comercial</span>
        </b-nav-item>
      </b-nav>

      <b-nav vertical class="p-0" v-if="esAdmin">
        <!-- OPCIONES EXCLUSIVAS MENÚ PERFIL ADMINISTRADOR -->
        <li><hr class="mb-0 mt-0" /></li>
        <b-nav-item to="/paises" title="Países">
          <v-icon name="flag" width="16" />
          <span class="sm-hidden">Países</span>
        </b-nav-item>
        <b-nav-item to="/modulos" title="Módulos">
          <v-icon name="database" width="16" />
          <span class="sm-hidden">Módulos</span>
        </b-nav-item>
        <b-nav-item to="/divisas" title="Divisas">
          <v-icon name="dollar-sign" width="16" />
          <span class="sm-hidden">Divisas</span>
        </b-nav-item>
        <b-nav-item to="/origenOferta" title="Origen de oferta">
          <v-icon name="package" width="16" />
          <span class="sm-hidden">Origen de oferta</span>
        </b-nav-item>
        <b-nav-item to="/preciosHora" title="Precios / hora">
          <v-icon name="clock" width="16" />
          <span class="sm-hidden">Precios / hora</span>
        </b-nav-item>
        <b-nav-item to="/duracionesContrato" title="Duraciones de contrato">
          <v-icon name="watch" width="16" />
          <span class="sm-hidden">Duraciones de contrato</span>
        </b-nav-item>
        <b-nav-item to="/plantillasContenido" title="Plantillas de contenido">
          <v-icon name="globe" width="16" />
          <span class="sm-hidden">Plantillas de contenido</span>
        </b-nav-item>
        <b-nav-item to="/formasPago" title="Formas de pago">
          <v-icon name="credit-card" width="16" />
          <span class="sm-hidden">Formas de pago</span>
        </b-nav-item>
        <b-nav-item to="/planes" title="Planes">
          <v-icon name="package" width="16" />
          <span class="sm-hidden">Planes</span>
        </b-nav-item>
        <b-nav-item to="/complejidadesProyecto" title="Complejidades de proyecto">
          <v-icon name="shuffle" width="16" />
          <span class="sm-hidden">Complejidad proyecto</span>
        </b-nav-item>
        <b-nav-item to="/usuarios" title="Usuarios">
          <v-icon name="users" width="16" />
          <span class="sm-hidden">Usuarios</span>
        </b-nav-item>
        <b-nav-item to="/campanias" title="Campañas">
          <v-icon name="at-sign" width="16" />
          <span class="sm-hidden">Campañas</span>
        </b-nav-item>
        <b-nav-item to="/unidadesNegocio" title="Unidades de Negocio">
          <v-icon name="server" width="16" />
          <span class="sm-hidden">Unidades de Negocio</span>
        </b-nav-item>
        <b-nav-item to="/tarifas" title="Tarifas">
          <v-icon name="dollar-sign" width="16" />
          <span class="sm-hidden">Tarifas</span>
        </b-nav-item>
        <b-nav-item to="/descuentos" title="Tarifas">
          <v-icon name="percent" width="16" />
          <span class="sm-hidden">Descuentos</span>
        </b-nav-item>
        <b-nav-item to="/plantillasContrato" title="Plantillas de contrato">
          <v-icon name="layers" width="16" />
          <span class="sm-hidden">Plantillas de contrato</span>
        </b-nav-item>
      </b-nav>

      <b-nav vertical class="p-0" v-if="esAdmin">
        <li><hr class="mb-0 mt-0" /></li>
        <b-nav-item to="/estadisticas/ofertas" title="Estadísticas de ofertas">
          <v-icon name="trending-up" width="16" />
          <span class="sm-hidden">Estadísticas de ofertas</span>
        </b-nav-item>
        <b-nav-item to="/estadisticas/modulos" title="Estadísticas de módulos">
          <v-icon name="trending-up" width="16" />
          <span class="sm-hidden">Estadísticas de módulos</span>
        </b-nav-item>
        <b-nav-item to="/estadisticas/ofertasEntregadas" title="Estadísticas de ofertas Entregadas">
          <v-icon name="inbox" width="16" />
          <span class="sm-hidden">Ofertas Entregadas</span>
        </b-nav-item>
      </b-nav>

      <b-nav vertical class="p-0 b-nav-logout" v-if="loggedIn">
        <!-- CERRAR SESSIÓN -->
        <li><hr class="mb-0 mt-0" /></li>
        <b-nav-item @click="logout" :title="tooltipCerrarSesion" v-b-tooltip.hover>
          <v-icon name="log-out" width="16" />
          <span class="sm-hidden">Cerrar Sesión</span>
        </b-nav-item>
      </b-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("session", ["usuarioId", "nombre", "esAdmin", "esResponsable", "esPartner"]),
    ...mapGetters("session", ["loggedIn"]),
    tooltipCerrarSesion() {
      return `${this.nombre} ~ v${process.env.VUE_APP_VERSION}`;
    },
  },
  methods: {
    async logout() {
      const ok = await this.$bvModal.msgBoxConfirm("¿Desea cerrar la sesión?", {
        title: "Atención",
        okTitle: "Cerrar sesión",
      });

      if (ok) {
        this.$emit("logout");
      }
    },
  },
};
</script>
