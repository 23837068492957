<template>
  <page-edicion title="Editar plan">
    <formulario :plan="plan" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/planes.js";

export default {
  components: { Formulario },
  data() {
    return {
      plan: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.plan = response.plan;
      });
    },
    actualizar() {
      api.actualizar(this.plan).then(() => {
        this.$emit("userMessage", "Se ha actualizado el plan");
        this.$router.push({ path: "/planes" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
