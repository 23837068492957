import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { formatDateEs } from "@/services/date";
import bootstrapVueDefaults from "@/config/bootstrapVueDefaults.js";

Vue.config.productionTip = false;

Vue.filter("fecha", formatDateEs);
Vue.filter("sino", (x) => (x ? "Sí" : "No"));

// vue-bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue, bootstrapVueDefaults);
Vue.use(IconsPlugin);

// Icons
import feather from "vue-icon";
Vue.use(feather, "v-icon");

//vue wysiwyg
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
Vue.use(wysiwyg, {
  locale: "es",
  image: {
    uploadURL: process.env.VUE_APP_ENDPOINT + "/imagen/subir",
  },
});

// Date Picker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
Vue.component("date-picker", DatePicker);

// Vue-loading
import PageLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("page-loading", PageLoading);

// DayJS
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

//paginate
import VuejsPaginate from "vuejs-paginate";
Vue.component("paginate", VuejsPaginate);

import Paginacion from "@/components/Paginacion";
Vue.component("paginacion", Paginacion);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// Select2
// para el menu de aplicaciones porque vue-select no soporta optgroup
import Select2 from "v-select2-component";
import "./styles/select2_bootstrap_theme.css";
// import "select2/dist/js/i18n/es.js";
Vue.component("v-select2", Select2);

//multiselect
import Select2MultipleControl from "v-select2-multiple-component";
Vue.component("Select2MultipleControl", Select2MultipleControl);

// Autocomplete
import Autocomplete from "vuejs-auto-complete";
Vue.component("Autocomplete", Autocomplete);

// Vue HEAD
import VueHead from "vue-head";
Vue.use(VueHead);

//layout
import Page from "@/components/Page.vue";
Vue.component("Page", Page);

import PageEdicion from "@/components/PageEdicion.vue";
Vue.component("PageEdicion", PageEdicion);

import PageListado from "@/components/PageListado.vue";
Vue.component("PageListado", PageListado);

import BotoneraEdicion from "@/components/BotoneraEdicion.vue";
Vue.component("BotoneraEdicion", BotoneraEdicion);

import SinRegistros from "@/components/SinRegistros.vue";
Vue.component("SinRegistros", SinRegistros);

import BusquedaRapida from "@/components/BusquedaRapida.vue";
Vue.component("BusquedaRapida", BusquedaRapida);

import ThOrdenado from "@/components/ThOrdenado.vue";
Vue.component("ThOrdenado", ThOrdenado);

import Combo from "@/components/Combo.vue";
Vue.component("Combo", Combo);

import ComboMultiple from "@/components/ComboMultiple.vue";
Vue.component("ComboMultiple", ComboMultiple);

import BotonEliminar from "@/components/BotonEliminar.vue";
Vue.component("BotonEliminar", BotonEliminar);

import MensajeAlerta from "@/components/MensajeAlerta.vue";
Vue.component("MensajeAlerta", MensajeAlerta);

import CampoImagen from "@/components/CampoImagen.vue";
Vue.component("CampoImagen", CampoImagen);

import CampoFicheroMultiple from "@/components/CampoFicheroMultiple.vue";
Vue.component("CampoFicheroMultiple", CampoFicheroMultiple);

import TreeItem from "@/components/TreeItem.vue";
Vue.component("TreeItem", TreeItem);

import "./styles/main.scss";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
