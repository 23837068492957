<template>
  <page-listado :fluid="true" title="Factores de complejidad de proyecto">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/complejidadesProyecto/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear factor de complejidad
      </b-button>
    </template>
    <tabla :complejidadesProyecto="complejidadesProyecto"></tabla>
  </page-listado>
</template>

<script>
import api from "@/services/complejidadesProyecto.js";
import Tabla from "./TablaComplejidadesProyecto.vue";
export default {
  components: { Tabla },
  data() {
    return {
      complejidadesProyecto: [],
    };
  },
  methods: {
    listar() {
      return api.listar(this.$route.query).then((complejidadesProyecto) => {
        this.complejidadesProyecto = complejidadesProyecto;
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
