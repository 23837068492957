<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado class="text-center" titulo="Abreviatura" campo="abreviatura"></th-ordenado>
      <th class="th-acciones" width="260">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!divisas" :colspan="4"></sin-registros>
      <tr v-for="divisa in divisas" v-bind:key="divisa.id" role="button">
        <td @click="editarDivisa(divisa.id)">{{ divisa.nombre }}</td>
        <td @click="editarDivisa(divisa.id)" class="text-center">{{ divisa.abreviatura }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/divisas/editar/${divisa.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', divisa.id)">
              ¿ Desea eliminar la divisa
              <b>{{ divisa.nombre }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    divisas: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editarDivisa(divisaId) {
      this.$router.push("/divisas/editar/" + divisaId);
    },
  },
};
</script>
