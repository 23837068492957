<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado class="text-center" titulo="Fecha Inicio" campo="fechaInicio"></th-ordenado>
      <th-ordenado class="text-center" titulo="Fecha Fin" campo="fechaFin"></th-ordenado>
      <th-ordenado class="text-center" titulo="Orden" campo="orden"></th-ordenado>
      <th class="th-acciones" width="260">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!tarifas" :colspan="4"></sin-registros>
      <tr v-for="tarifa in tarifas" v-bind:key="tarifa.id" role="button">
        <td @click="editarTarifa(tarifa.id)">{{ tarifa.nombre }}</td>
        <td @click="editarTarifa(tarifa.id)" class="text-center">{{ formatearFecha(tarifa.fechaInicio) }}</td>
        <td @click="editarTarifa(tarifa.id)" class="text-center">{{ formatearFecha(tarifa.fechaFin) }}</td>
        <td @click="editarTarifa(tarifa.id)" class="text-center">{{ tarifa.orden }}</td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/tarifas/editar/${tarifa.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', tarifa.id)">
              ¿ Desea eliminar la tarifa
              <b>{{ tarifa.nombre }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { formatDateEs } from "@/services/date";

export default {
  props: {
    tarifas: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatearFecha(fecha) {
      return formatDateEs(fecha);
    },
    editarTarifa(tarifaId) {
      this.$router.push("/tarifas/editar/" + tarifaId);
    },
  },
};
</script>
