<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Descripción" campo="descripcion"></th-ordenado>
      <th-ordenado titulo="Descuento" campo="descuento"></th-ordenado>
      <th-ordenado class="text-center" titulo="Dto. Usuario" campo="dtoUsuario"></th-ordenado>
      <th-ordenado class="text-center" titulo="Estado" campo="estado"></th-ordenado>
      <th class="th-acciones" width="260">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!descuentos" :colspan="4"></sin-registros>
      <tr v-for="descuento in descuentos" v-bind:key="descuento.id" role="button">
        <td @click="editarDescuento(descuento.id)">{{ descuento.descripcion }}</td>
        <td @click="editarDescuento(descuento.id)">{{ descuento.descuento }} %</td>
        <td @click="editarDescuento(descuento.id)" class="text-center">
          <v-icon v-if="descuento.dtoUsuario" name="user" width="16" />
        </td>
        <td @click="editarDescuento(descuento.id)" class="text-center">
          <v-icon v-if="descuento.estado" name="check" width="16" />
          <v-icon v-if="!descuento.estado" name="x" width="16" />
        </td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/descuentos/editar/${descuento.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', descuento.id)">
              ¿ Desea eliminar el descuento
              <b>{{ descuento.descripcion }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    descuentos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editarDescuento(descuentoId) {
      this.$router.push("/descuentos/editar/" + descuentoId);
    },
  },
};
</script>
