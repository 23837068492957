<template>
  <page-listado title="Formas de pago" :fluid="true">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/formasPago/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear forma de pago
      </b-button>
    </template>
    <tabla :formasPago="formasPago"></tabla>
  </page-listado>
</template>

<script>
import api from "@/services/formasPago.js";
import Tabla from "./TablaFormasPago.vue";
export default {
  components: { Tabla },
  data() {
    return {
      formasPago: [],
    };
  },
  methods: {
    async listar() {
      this.formasPago = await api.listar(this.$route.query);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
