import apiFormasDePago from "@/services/formasPago";

const state = {
  formasDePago: [],
};

const mutations = {
  cargar(state, formasDePago) {
    state.formasDePago = formasDePago;
  },
};

const actions = {
  cargar({ commit }) {
    return apiFormasDePago.listar().then((r) => commit("cargar", r));
  },
};

const getters = {
  combo: (state) => {
    return state.formasDePago.map((fp) => {
      return {
        value: fp.id,
        text: fp.nombre,
      };
    });
  },
  nombre: (state) => (id) => {
    var fp = state.formasDePago.find((x) => x.id == id);

    if (fp) {
      return fp.nombre;
    } else {
      return "N/D";
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
