import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    nombre: "",
  };
}

function listar(params) {
  return listado("paises/listar", params);
}

function paraCombo() {
  return api.get("paises/paraCombo").then((r) => r.data);
}

function cargar(id) {
  return api.get("paises/cargar&id=" + id).then((r) => r.data);
}

function obtenerDivisa(id) {
  return api.get("paises/obtenerDivisa&id=" + id).then((r) => r.data);
}

function insertar(pais) {
  return api.post("paises/insertar", { pais }).then((r) => r.data);
}

function actualizar(pais) {
  return api.post("paises/actualizar", { pais }).then((r) => r.data);
}

function eliminar(id) {
  return api.post("paises/eliminar", { id });
}

export default {
  crear,
  listar,
  paraCombo,
  cargar,
  insertar,
  actualizar,
  eliminar,
  obtenerDivisa,
};
