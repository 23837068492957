<template>
  <b-form @submit.prevent="submit">
    <b-row class="mb-3">
      <b-col lg="6">
        <b-form-group label="Razón social*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.razonSocial"
            maxlength="100"
            placeholder="Nombre de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Nombre comercial*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.nombreComercial"
            maxlength="30"
            placeholder="Nombre comercial de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="3">
        <b-form-group label="Identificador fiscal*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.identificadorFiscal"
            maxlength="20"
            placeholder="Identificador fiscal de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Tarifa*">
          <combo v-model="unidadNegocio.tarifaId" :options="opcionesTarifa" url="tarifas/autocompletar"></combo>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="unidadNegocio.estado"> Activa </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-0">
      <b-col lg="12">
        <h2 class="pb-1">Localización:</h2>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="12">
        <b-form-group label="Paises*">
          <combo-multiple
            :value="paisesIdCombo"
            :options="opcionesPaises"
            url="paises/autocompletar"
            @input="selectPaises"
          ></combo-multiple>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="4">
        <b-form-group label="Ciudad*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.ciudad"
            maxlength="50"
            placeholder="Ciudad de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Provincia">
          <b-form-input
            type="text"
            v-model="unidadNegocio.provincia"
            maxlength="50"
            placeholder="Provincia de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Código postal">
          <b-form-input
            type="text"
            v-model="unidadNegocio.codigoPostal"
            maxlength="8"
            placeholder="Código postal de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="12">
        <b-form-group label="Dirección">
          <b-form-input
            type="text"
            v-model="unidadNegocio.direccion"
            maxlength="100"
            placeholder="Dirección de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="6">
        <b-form-group label="Email">
          <b-form-input
            type="email"
            v-model="unidadNegocio.email"
            maxlength="90"
            placeholder="Email de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Teléfono">
          <b-form-input
            type="tel"
            v-model="unidadNegocio.telefono"
            maxlength="15"
            placeholder="Teléfono de la unidad de negocio"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-0">
      <b-col lg="12">
        <h2 class="pb-1">Representante legal:</h2>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="8">
        <b-form-group label="Nombre*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.representanteLegal"
            maxlength="100"
            placeholder="Nombre del representante legal"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Identificador fiscal*">
          <b-form-input
            required
            type="text"
            v-model="unidadNegocio.identificadorRepresentanteLegal"
            maxlength="20"
            placeholder="Identificador fiscal del representante legal"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-0">
      <b-col lg="12">
        <h2 class="pb-1">Carta de bienvenida:</h2>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="8">
        <b-form-group label="Formato de la carta a enviar por email">
          <wysiwyg
            id="editorTextoCartaBienvenida"
            placeholder="Escriba aquí"
            v-model="unidadNegocio.cartaBienvenida"
            :options="opcionesEditor"
          ></wysiwyg>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion url-cancelar="/unidadesNegocio" />
  </b-form>
</template>
<script>
import apiPaises from "@/services/paises.js";
import apiTarifas from "@/services/tarifas.js";
import EditorShortcodes from "@/components/editor/ModuloShortcodesContrato.vue";
export default {
  props: {
    unidadNegocio: Object,
    paisesId: Array,
    opcionesPaises: Array,
    tarifa: Object,
  },
  data() {
    return {
      listaPaises: [],
      paisesIdCombo: this.paisesId ? this.paisesId : [],
      paisesSeleccionados: [],
      listaTarifas: [],
      tarifasIdCombo: this.tarifasId ? this.tarifasId : [],
      opcionesEditor: {
        customModules: [EditorShortcodes],
      },
    };
  },
  computed: {
    opcionesTarifa() {
      if (!this.tarifa) {
        return [];
      }
      return [{ id: this.tarifa.id, text: this.tarifa.nombre }];
    },
  },
  methods: {
    listarPaises() {
      return apiPaises.listar(this.$route.query).then((resultado) => {
        this.listaPaises = resultado.paises;
      });
    },
    selectPaises(paisesCombo) {
      this.paisesSeleccionados = [];
      var p = {};
      for (p in paisesCombo) {
        this.paisesSeleccionados.push({
          unidadNegocioId: this.unidadNegocio.id,
          paisId: paisesCombo[p],
        });
      }
    },
    listarTarifas() {
      return apiTarifas.listarActivas(this.$route.query).then((resultado) => {
        this.listaTarifas = resultado.tarifas;
      });
    },
    submit() {
      this.$emit("submit", this.paisesSeleccionados);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listarPaises();
        this.listarTarifas();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
