import { listado } from "./listado";

function listar(params) {
  return listado("ofertas/listar", params);
}
function vaciar(params) {
  return listado("ofertas/vaciarPapelera", params);
}

export default { listar, vaciar };
