<template>
  <li
    v-if="
      item &&
      item.datos &&
      ((item.datos.tipo && (item.datos.estado || (!item.datos.estado && esAdmin && modoEdicion))) ||
        (!item.datos.tipo && ((item.datos.estado && tieneHijosVisibles(item)) || (esAdmin && modoEdicion))))
    "
  >
    <div :class="{ bold: esCarpeta(item), tachada: item && item.datos && !item.datos.estado }">
      <v-icon class="text-warning icon-bold mr-1" name="folder" width="20" v-if="esCarpeta(item)" />
      <v-icon class="mr-1" variant="primary" name="file-text" width="20" v-if="!esCarpeta(item)" />
      <span v-if="item && item.datos && !item.datos.tipo" @click="toggle">{{ item.datos.nombre }}</span>
      <a v-if="item && item.datos && item.datos.tipo" target="_blank" :href="item.datos.enlace">{{
        item.datos.nombre
      }}</a>
      <span v-if="esCarpeta(item)" @click="toggle">[{{ isOpen ? "-" : "+" }}]</span>
      <v-icon class="text-secondary ml-1" name="eye-off" width="20" v-if="item && item.datos && !item.datos.estado" />
      <a @click="editarCarpeta(item)" v-if="modoEdicion"
        ><v-icon class="text-success ml-1" name="edit" width="20" v-if="esCarpeta(item)"
      /></a>
      <b-modal
        v-if="item && item.datos"
        :id="`modalEditarCarpeta-${item.datos.id}`"
        ref="modal"
        :title="tituloModalEditarCarpeta"
        @ok="$emit('editarDocumento', item.datos)"
        :ok-disabled="carpetaCompleta(item.datos)"
      >
        <b-form-group label="Nombre de la carpeta*">
          <b-form-input
            required
            autofocus
            type="text"
            v-model="item.datos.nombre"
            placeholder="Nombre de la carpeta"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="item.datos.estado"> Activo </b-form-checkbox>
        </b-form-group>
      </b-modal>
      <a v-if="estaVacia(item) && modoEdicion" @click="confirmarBorrarCarpeta(item)"
        ><v-icon class="text-danger ml-1" name="trash-2" width="20" v-if="esCarpeta(item)"
      /></a>
      <b-modal
        v-if="item && item.datos"
        :id="`modalBorrarCarpeta-${item.datos.id}`"
        ref="modal"
        :title="tituloModalBorrarCarpeta"
        @ok="$emit('borrarDocumento', item.datos)"
      >
        <slot>¿Desea continuar?</slot>
      </b-modal>
      <a @click="editarDocumento(item)" v-if="modoEdicion"
        ><v-icon class="text-success ml-1" name="edit" width="20" v-if="!esCarpeta(item)"
      /></a>
      <b-modal
        v-if="item && item.datos"
        :id="`modalEditarDocumento-${item.datos.id}`"
        ref="modal"
        :title="tituloModalEditarDocumento"
        @ok="$emit('editarDocumento', item.datos)"
        :ok-disabled="documentoCompleto(item.datos)"
      >
        <b-form-group label="Nombre del documento*">
          <b-form-input
            required
            autofocus
            type="text"
            v-model="item.datos.nombre"
            placeholder="Nombre del documento"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Enlace*">
          <b-form-input required type="text" v-model="item.datos.enlace" placeholder="https://www....."></b-form-input>
        </b-form-group>
        <b-form-group label="Estado">
          <b-form-checkbox switch size="lg" v-model="item.datos.estado"> Activo </b-form-checkbox>
        </b-form-group>
      </b-modal>
      <a @click="confirmarBorrarDocumento(item)" v-if="modoEdicion"
        ><v-icon class="text-danger ml-1" name="trash-2" width="20" v-if="!esCarpeta(item)"
      /></a>
      <b-modal
        v-if="item && item.datos"
        :id="`modalBorrarDocumento-${item.datos.id}`"
        ref="modal"
        :title="tituloModalBorrarDocumento"
        @ok="$emit('borrarDocumento', item.datos)"
      >
        <slot>¿Desea continuar?</slot>
      </b-modal>
    </div>
    <ul v-show="isOpen" v-if="esCarpeta(item)">
      <tree-item
        class="item"
        v-for="(hijo, index) in item.hijos"
        :key="index"
        :item="hijo"
        @anadirDocumento="$emit('anadirDocumento', $event)"
        @editarDocumento="$emit('editarDocumento', $event)"
        @anadirCarpeta="$emit('anadirCarpeta', $event)"
        @borrarDocumento="$emit('borrarDocumento', $event)"
        :estaAbierto="estaAbierto"
        :modoEdicion="modoEdicion"
      ></tree-item>
      <li class="add" @click="anadirDocumento(item)" v-if="modoEdicion">
        <v-icon class="text-success" variant="primary" name="file-plus" width="20" v-if="esCarpeta(item)" /> Añadir
        documento
        <b-modal
          :id="`modalAnadirDocumento-${item.datos.id}`"
          ref="modal"
          :title="tituloModalAnadirDocumento"
          @ok="$emit('anadirDocumento', documento)"
          :ok-disabled="documentoCompleto(documento)"
        >
          <b-form-group label="Nombre del documento*">
            <b-form-input
              required
              autofocus
              type="text"
              v-model="documento.nombre"
              placeholder="Nombre del documento"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Enlace*">
            <b-form-input required type="text" v-model="documento.enlace" placeholder="https://www....."></b-form-input>
          </b-form-group>
          <b-form-group label="Estado">
            <b-form-checkbox switch size="lg" v-model="documento.estado"> Activo </b-form-checkbox>
          </b-form-group>
        </b-modal>
      </li>
      <li class="add" @click="anadirCarpeta(item)" v-if="modoEdicion">
        <v-icon class="text-info" variant="primary" name="folder-plus" width="20" v-if="esCarpeta(item)" /> Añadir
        carpeta
        <b-modal
          :id="`modalAnadirCarpeta-${item.datos.id}`"
          ref="modal"
          :title="tituloModalAnadirCarpeta"
          @ok="$emit('anadirCarpeta', documento)"
          :ok-disabled="carpetaCompleta(documento)"
        >
          <b-form-group label="Nombre de la carpeta*">
            <b-form-input
              required
              autofocus
              type="text"
              v-model="documento.nombre"
              placeholder="Nombre de la carpeta"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Estado">
            <b-form-checkbox switch size="lg" v-model="documento.estado"> Activa </b-form-checkbox>
          </b-form-group>
        </b-modal>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapState } from "vuex";
import apiRepositoriosComerciales from "@/services/repositoriosComerciales.js";
export default {
  props: {
    item: {
      datos: Object,
      hijos: [],
    },
    estaAbierto: Boolean,
    modoEdicion: Boolean,
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  data: function () {
    return {
      isOpen: this.estaAbierto,
      tituloModalAnadirDocumento: "Añadir documento",
      tituloModalEditarDocumento: "Editar documento",
      tituloModalBorrarDocumento: "Borrar documento",
      tituloModalAnadirCarpeta: "Añadir carpeta",
      tituloModalEditarCarpeta: "Editar carpeta",
      tituloModalBorrarCarpeta: "Borrar carpeta",
      documento: apiRepositoriosComerciales.crear(),
    };
  },
  mounted() {
    if (this.estaAbierto) this.isOpen = true;
  },
  methods: {
    toggle() {
      if (this.esCarpeta) {
        this.isOpen = !this.isOpen;
      }
    },
    esCarpeta: function (item) {
      return item && item.datos && !item.datos.tipo;
    },
    estaVacia: function (item) {
      return item && (!item.hijos || !item.hijos.length);
    },
    tieneHijosVisibles: function (item) {
      let resultado = Boolean(item && item.hijos && item.hijos.length);
      if (resultado) resultado = this.sonVisibles(item.hijos);
      return resultado;
    },
    sonVisibles(hijos) {
      let resultado = false;
      hijos.forEach((element) => {
        if (!resultado && element.datos.estado) {
          resultado = true;
          return resultado;
        }
        if (!resultado && !element.datos.tipo) {
          resultado = this.tieneHijosVisibles(element);
          return resultado;
        }
      });
      return resultado;
    },
    documentoCompleto(documento) {
      return documento.nombre == "" || documento.enlace == "";
    },
    carpetaCompleta(documento) {
      return documento.nombre == "";
    },
    anadirDocumento(item) {
      this.documento = apiRepositoriosComerciales.crear();
      this.documento.carpetaId = item.datos.id;
      this.$bvModal.show("modalAnadirDocumento-" + item.datos.id);
    },
    anadirCarpeta(item) {
      this.documento = apiRepositoriosComerciales.crear();
      this.documento.carpetaId = item.datos.id;
      this.$bvModal.show("modalAnadirCarpeta-" + item.datos.id);
    },
    editarCarpeta(item) {
      this.$bvModal.show("modalEditarCarpeta-" + item.datos.id);
    },
    editarDocumento(item) {
      this.$bvModal.show("modalEditarDocumento-" + item.datos.id);
    },
    confirmarBorrarDocumento(item) {
      this.$bvModal.show("modalBorrarDocumento-" + item.datos.id);
    },
    confirmarBorrarCarpeta(item) {
      this.$bvModal.show("modalBorrarCarpeta-" + item.datos.id);
    },
  },
  watch: {
    estaAbierto: {
      handler: function () {
        this.isOpen = this.estaAbierto;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
body {
  color: #444;
}
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.tachada {
  text-decoration: line-through;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: none;
}
.icon-bold {
  stroke-width: 3;
}
</style>
