<template>
  <div>
    <b-row class="mb-2">
      <b-col md="4"><span>Descuentos</span></b-col>
      <b-col>
        <b-badge variant="success" class="mr-2" v-if="value.descuentoSuscripcion > 0">
          <span>Suscripción: </span>
          <b>{{ value.descuentoSuscripcion }}%</b>
        </b-badge>
        <b-badge variant="primary" class="mr-2" v-if="value.descuentoImplementacion > 0">
          <span>Implementación: </span>
          <b>{{ value.descuentoImplementacion }}%</b>
        </b-badge>
        <b-badge variant="warning" class="mr-2" v-if="value.descuentoCuota > 0">
          <span>Cuota: </span>
          <b>{{ value.descuentoCuota }}%</b>
        </b-badge>
        <b-badge v-else>
          <span>Sin descuentos</span>
        </b-badge>
      </b-col>
      <b-col md="3">
        <b-button
          v-if="oferta.borrador && !oferta.caducada"
          size="sm"
          variant="primary"
          class="pull-right bbutton-mini"
          v-b-modal.modal-descuentos
        >
          <v-icon variant="primary" name="edit" width="16" /> Editar
        </b-button>
      </b-col>
    </b-row>
    <modal-form-descuentos
      :tiposDescuento="tiposDescuento"
      :ofertaTipoDescuento="value"
      @ok="aplicarDescuento"
      :esSaas="esSaas"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ModalFormDescuentos from "./ModalFormDescuentos.vue";

export default {
  props: {
    oferta: { type: Object, required: true },
    value: {
      type: Object,
      default: () => {},
    },
    tiposDescuento: {
      type: Array,
      default: () => [],
    },
    esSaas: Boolean,
  },
  components: {
    ModalFormDescuentos,
  },
  methods: {
    aplicarDescuento(dtos) {
      this.$emit("input", dtos);
    },
  },
};
</script>
