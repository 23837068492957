<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="descripcion"></th-ordenado>
      <th>País</th>
      <th align="center">Ver.</th>
      <th-ordenado titulo="Fecha" campo="fecha"></th-ordenado>
      <th>Propietario</th>
      <th>Plan</th>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros :count="ofertas.length" :colspan="8"></sin-registros>
      <tr v-for="oferta in ofertas" v-bind:key="oferta.id">
        <td>{{ oferta.descripcion }}</td>
        <td>{{ oferta.pais }}</td>
        <td align="center">{{ oferta.numeroVersion }}</td>
        <td>{{ formatFecha(oferta.fecha) }}</td>
        <td>{{ oferta.comercial }}</td>
        <td>{{ oferta.plan }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/ofertas/editar/${oferta.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span></b-button
            >
            <b-dropdown size="sm" right text="" variant="primary" class="actionButton">
              <template slot="button-content">
                <v-icon variant="primary" name="menu" width="16" />
              </template>
              <b-dropdown-item size="sm" @click="clonarOferta(oferta.id)"
                ><v-icon variant="primary" name="copy" width="16" /> Crear Oferta desde esta Plantilla</b-dropdown-item
              >
            </b-dropdown>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { formatDateTabla } from "@/services/date";
export default {
  props: {
    ofertas: Array,
    orden: String,
    filtros: Object,
    query: Object,
  },
  components: {},
  methods: {
    formatFecha(fecha) {
      return formatDateTabla(fecha);
    },
    clonarOferta() {
      return api.get("ofertas/clonarOferta&ofertaId=" + id).then((r) => {
        this.$router.push({
          path: "/ofertas/editar/" + r.data.ofertaActivaId,
        });
      });
    },
  },
  data() {
    return {
      searchOfertas: "",
      sortKey: "name",
      reverse: false,
    };
  },
};
</script>
