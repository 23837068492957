<template>
  <tr v-if="!count">
    <td :colspan="colspan">No se han encontrado {{ name }}</td>
  </tr>
</template>
<script>
export default {
  props: {
    count: Number,
    colspan: Number,
    name: {
      type: String,
      default: "registros",
    },
  },
};
</script>
