<template>
  <div>
    <!-- <button
      type="button"
      @click="blockquote"
      v-for="shortCode in shortCodes"
      :key="shortCode.id"
      :value="shortCode.shortCode"
      class="btn mr-1 mb-1 btn-"
      :class="shortCode.color"
    >
      {{ shortCode.titulo }}
    </button> -->
    <b-dropdown
      v-for="shortCode in shortCodesAgrupado"
      :key="shortCode['grupo']"
      :text="shortCode['grupo']"
      :variant="shortCode['color']"
      class="mr-1 mb-1"
    >
      <b-dropdown-item
        v-for="codigo in shortCode['opciones']"
        :key="codigo['id']"
        :value="codigo['shortCode']"
        href="#"
        @click="anadirShortcode"
        >{{ codigo["titulo"] }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import apiEditorShortcodes from "@/services/editorShortcode.js";
export default {
  title: "editor-shortcodes",
  icon: '<?xml version="1.0" ?><svg height="1792" viewBox="0 0 1792 1792" width="1792" xmlns="http://www.w3.org/2000/svg"><path d="M896 768q237 0 443-43t325-127v170q0 69-103 128t-280 93.5-385 34.5-385-34.5-280-93.5-103-128v-170q119 84 325 127t443 43zm0 768q237 0 443-43t325-127v170q0 69-103 128t-280 93.5-385 34.5-385-34.5-280-93.5-103-128v-170q119 84 325 127t443 43zm0-384q237 0 443-43t325-127v170q0 69-103 128t-280 93.5-385 34.5-385-34.5-280-93.5-103-128v-170q119 84 325 127t443 43zm0-1152q208 0 385 34.5t280 93.5 103 128v128q0 69-103 128t-280 93.5-385 34.5-385-34.5-280-93.5-103-128v-128q0-69 103-128t280-93.5 385-34.5z"/></svg>',
  description: "Shortcodes para generación de contratos",
  methods: {
    anadirShortcode(e) {
      this.$parent.closeDashboard();
      this.$emit("exec", "insertHTML", `{{ ${e.target.getAttribute("value")} }}`);
    },
    async cargarShortcodes() {
      //   this.shortCodes = await apiEditorShortcodes.listarEditorShortcode("contratos");
      this.shortCodesAgrupado = await apiEditorShortcodes.listarEditorShortcodeAgrupado("contratos");
    },
  },
  data() {
    return {
      shortCodes: [],
      shortCodesAgrupado: [],
    };
  },
  mounted() {
    this.cargarShortcodes();
  },
};
</script>
