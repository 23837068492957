<template>
  <b-form @submit.prevent="submit">
    <b-tabs small card>
      <b-tab title="General">
        <b-form-group label="Nombre">
          <b-form-input
            required
            type="text"
            v-model="plantillaContenido.nombre"
            placeholder="Nombre de la plantilla"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Paises">
          <combo-multiple
            :value="paisesIdCombo"
            :options="opcionesPaises"
            url="paises/autocompletar"
            @input="selectPaises"
          ></combo-multiple>
        </b-form-group>
      </b-tab>
      <b-tab title="Presentación">
        <b-form-group label="Carta de presentación">
          <wysiwyg id="editorTexto" placeholder="Escriba aquí" v-model="plantillaContenido.texto"></wysiwyg>
        </b-form-group>
      </b-tab>
      <b-tab title="Necesidades">
        <b-form-group label="Necesidades">
          <wysiwyg id="editorNecesidades" placeholder="Escriba aquí" v-model="plantillaContenido.necesidades"></wysiwyg>
        </b-form-group>
      </b-tab>
      <b-tab title="Resumen ejecutivo">
        <b-form-group label="Resumen Ejecutivo">
          <wysiwyg
            id="editorResumenEjecutivo"
            placeholder="Escriba aquí"
            v-model="plantillaContenido.resumenEjecutivo"
          ></wysiwyg>
        </b-form-group>
      </b-tab>

      <b-tab title="Aclaraciones">
        <b-form-group label="Aclaraciones">
          <wysiwyg
            id="editorAclaraciones"
            placeholder="Escriba aquí"
            v-model="plantillaContenido.aclaraciones"
          ></wysiwyg>
        </b-form-group>
      </b-tab>

      <b-tab title="Logos">
        <b-form-group label="Logos de Clientes">
          <wysiwyg id="editorLogosClientes" v-model="plantillaContenido.logosClientes"></wysiwyg>
        </b-form-group>
      </b-tab>

      <b-tab title="Anexo">
        <b-form-group label="Anexo">
          <wysiwyg id="editorAnexo" placeholder="Escriba aquí" v-model="plantillaContenido.anexo"></wysiwyg>
        </b-form-group>
      </b-tab>

      <b-tab title="Más (Extendido)">
        <b-form-group label="Beneficios">
          <wysiwyg placeholder="Escriba aquí" v-model="plantillaContenido.beneficios" />
        </b-form-group>
        <b-form-group label="Usuarios">
          <wysiwyg placeholder="Escriba aquí" v-model="plantillaContenido.usuarios" />
        </b-form-group>
        <b-form-group label="Plan de soporte">
          <wysiwyg placeholder="Escriba aquí" v-model="plantillaContenido.planDeSoporte" />
        </b-form-group>
        <b-form-group label="Requisitos mínimos">
          <wysiwyg placeholder="Escriba aquí" v-model="plantillaContenido.requisitosMinimos" />
        </b-form-group>
      </b-tab>
    </b-tabs>

    <botonera-edicion url-cancelar="/plantillasContenido" />
  </b-form>
</template>
<script>
import apiPaises from "@/services/paises.js";
export default {
  props: {
    plantillaContenido: Object,
    paisesId: Array,
    opcionesPaises: Array,
  },
  computed: {},
  data() {
    return {
      listaPaises: [],
      paisesIdCombo: this.paisesId ? this.paisesId : [],
      paisesSeleccionados: [],
    };
  },
  methods: {
    listarPaises() {
      return apiPaises.listar(this.$route.query).then((resultado) => {
        this.listaPaises = resultado.paises;
      });
    },
    selectPaises(paisesCombo) {
      this.paisesSeleccionados = [];
      var p = {};
      for (p in paisesCombo) {
        this.paisesSeleccionados.push({
          plantillaContenidoId: this.plantillaContenido.id,
          paisId: paisesCombo[p],
        });
      }
    },
    submit() {
      this.$emit("submit", this.paisesSeleccionados);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listarPaises();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
