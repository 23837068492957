<template>
  <page-listado title="Descuentos" :fluid="true">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/descuentos/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear descuento
      </b-button>
    </template>
    <tabla :descuentos="descuentos" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import apiDescuentos from "@/services/tipoDescuento.js";
import Tabla from "./TablaTiposDescuentos.vue";

export default {
  components: { Tabla },
  data() {
    return {
      descuentos: [],
    };
  },
  methods: {
    async listar() {
      this.descuentos = await apiDescuentos.listar(this.$route.query);
    },
    async eliminar(id) {
      await apiDescuentos.eliminar(id);
      this.listar();
    },
  },
  mounted() {
    this.listar();
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
