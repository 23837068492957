import Listar from "@/views/origenOferta/Listar.vue";
import Crear from "@/views/origenOferta/Crear.vue";
import Editar from "@/views/origenOferta/Editar.vue";

export default [
  {
    path: "/origenOferta",
    component: Listar,
  },
  {
    path: "/origenOferta/crear",
    component: Crear,
  },
  {
    path: "/origenOferta/editar/:id",
    component: Editar,
  },
];
