import Listar from "@/views/divisas/Listar.vue";
import Crear from "@/views/divisas/Crear.vue";
import Editar from "@/views/divisas/Editar.vue";

export default [
  {
    path: "/divisas",
    component: Listar,
  },
  {
    path: "/divisas/crear",
    component: Crear,
  },
  {
    path: "/divisas/editar/:id",
    component: Editar,
  },
];
