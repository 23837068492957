<template>
  <div>
    <!-- Tipo de venta / Propietario -->
    <b-row class="mb-3">
      <b-col lg="2"><label>Tipo de venta*</label></b-col>
      <b-col lg="5">
        <b-form-select required v-model="oferta.tipoVenta" :disabled="!puedeCambiarDatosOferta">
          <b-form-select-option disabled selected value=""> Seleccione el tipo de venta </b-form-select-option>
          <b-form-select-option v-for="tv in tiposDeVenta" :key="tv.id" :value="tv.id">
            {{ tv.nombre }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col lg="1" class="text-right"><label>Propietario*</label></b-col>
      <b-col lg="4">
        <combo
          id="comboPropietario"
          required
          :url="urlAutocompletarUsuarios"
          v-model="oferta.usuarioId"
          :options="opcionesUsuario"
          :disabled="!puedeCambiarDatosOferta"
        ></combo>
      </b-col>
    </b-row>

    <!-- Campos cliente -->
    <b-row class="mb-3" v-if="!mostrarCampoCliente">
      <b-col lg="2"><label>Cliente*</label></b-col>
      <b-col lg="8">
        <combo
          id="comboClienteOferta"
          required
          :url="urlAutocompletarClientes"
          v-model="ofertaBase.clienteId"
          @input="cambiarCliente"
          :options="opcionesCliente"
        ></combo>
      </b-col>
      <b-col lg="2">
        <b-button class="btn btn-success btn-block" v-b-modal.modal-crearCliente> Crear cliente </b-button>
      </b-col>
    </b-row>

    <!-- Unidad negocio / Tarifa -->
    <b-row class="mb-3" v-if="mostrarUnidadNegocio">
      <b-col lg="2"><label>Unidad de negocio</label></b-col>
      <b-col lg="4">
        <combo
          id="comboUnidadNegocio"
          :url="urlAutocompletarUnidadesNegocio"
          v-model="ofertaBase.unidadNegocioId"
          @input="cambiarUnidadNegocio"
          :options="opcionesUnidadNegocio"
          :disabled="unidadNegocioDesactivada"
        ></combo>
      </b-col>
      <b-col lg="2" v-if="esAdmin && ofertaBase.unidadNegocioId && oferta.calculada" class="text-right">
        <label>Tarifa</label>
      </b-col>
      <b-col lg="4" v-if="esAdmin && ofertaBase.unidadNegocioId && oferta.calculada">
        <combo
          id="comboTarifa"
          :url="urlAutocompletarTarifas"
          v-model="oferta.tarifaId"
          @input="cambiarTarifa"
          :options="opcionesTarifa"
          :disabled="unidadNegocioDesactivada"
        ></combo>
      </b-col>
    </b-row>

    <!-- Unidad negocio / origen -->
    <b-row class="mb-3" v-if="ofertaBase.unidadNegocioId && !mostrarUnidadNegocio">
      <b-col lg="2"><label>Unidad de negocio</label></b-col>
      <b-col lg="10">
        {{ unidadNegocio.nombreComercial }}
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="2"><label>Descripción*</label></b-col>
      <b-col lg="10">
        <b-form-input
          required
          :readonly="!puedeCambiarDatosOferta"
          type="text"
          v-model="oferta.descripcion"
          placeholder="Nombre de la oferta"
        ></b-form-input>
      </b-col>
    </b-row>

    <!-- Origen -->
    <b-row class="mb-3">
      <b-col lg="2"><label>Origen*</label></b-col>
      <b-col lg="4">
        <b-form-select required v-model="oferta.origenOfertaId" :disabled="!puedeCambiarDatosOferta">
          <b-form-select-option disabled selected value=""> Seleccione el origen de la oferta </b-form-select-option>
          <b-form-select-option v-for="origen in origenes" :key="origen.id" :value="origen.id">
            {{ origen.origen }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <!-- Campaña -->
    <b-row class="mb-3">
      <b-col lg="2"><label>Campaña*</label></b-col>
      <b-col lg="5"
        ><b-form-select
          required
          v-model="oferta.campaniaId"
          :disabled="!puedeCambiarDatosOferta"
          @change="cambiarDuracionContrato"
        >
          <b-form-select-option disabled selected value=""> Seleccione la campaña </b-form-select-option>
          <b-form-select-option v-for="campania in campanias" :key="campania.id" :value="campania.id">
            {{ campania.nombre }}
          </b-form-select-option>
        </b-form-select></b-col
      >
      <b-col lg="1" class="text-right"><label>Duración*</label></b-col>
      <b-col lg="4"
        ><b-form-select
          required
          v-model="oferta.duracionContratoId"
          :disabled="!puedeCambiarDatosOferta"
          @input="cambiarFormasDePago"
        >
          <b-form-select-option disabled selected value=""> Seleccione la duración del contrato </b-form-select-option>
          <b-form-select-option
            v-for="duracionContrato in duracionesContrato"
            :key="duracionContrato.id"
            :value="duracionContrato.id"
          >
            {{ duracionContrato.nombre }}
          </b-form-select-option>
        </b-form-select></b-col
      >
    </b-row>
    <b-row class="mb-3">
      <b-col lg="2"><label>Plan*</label></b-col>
      <b-col lg="6"
        ><b-form-select required v-model="oferta.planId" :disabled="!puedeCambiarDatosOferta">
          <b-form-select-option disabled selected value=""> Seleccione plan de precios </b-form-select-option>
          <b-form-select-option v-for="plan in planes" :key="plan.id" :value="plan.id">
            {{ plan.nombre }}
            ({{ plan.maximoNumeroUsuarios | numUsuarios }})
          </b-form-select-option>
        </b-form-select></b-col
      >
      <b-col lg="2" class="text-right"><label>Nº Empleados*</label></b-col>
      <b-col lg="2"
        ><b-form-input
          required
          type="number"
          v-model="oferta.numeroUsuarios"
          :max="usuariosPermitidos"
          placeholder="Nº usuarios"
          :disabled="!puedeCambiarDatosOferta"
        ></b-form-input
      ></b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="2"><label>Fecha Oferta*</label></b-col>
      <b-col lg="5"
        ><b-form-datepicker
          locale="es-ES"
          required
          :disabled="!puedeCambiarDatosOferta"
          @input="actualizarFechasFirma"
          v-model="fechaOferta"
          :start-weekday="1"
        ></b-form-datepicker></b-col
      ><b-col v-if="trazabilidad.fechaOfertaInicial" class="p-2 small"
        ><strong>Oferta Inicial:</strong> {{ trazabilidad.fechaOfertaInicial }}</b-col
      >
    </b-row>
    <!-- Fecha de firma -->
    <b-row class="mb-3">
      <b-col lg="2"><label>Fecha de firma*</label></b-col>
      <b-col lg="5">
        <b-form-datepicker
          locale="es-ES"
          required
          :disabled="editarFechaFirma"
          v-model="fechaFirma"
          :min="fechaOferta"
          :start-weekday="1"
        ></b-form-datepicker
      ></b-col>
      <b-col v-if="trazabilidad.fechaFirmaContratoInicial" class="p-2 small"
        ><strong>Contrato Inicial:</strong> {{ trazabilidad.fechaFirmaContratoInicial }}</b-col
      >
    </b-row>
    <!-- Fecha de cierre -->
    <b-row class="mb-3">
      <b-col lg="2"><label>Fecha prevista de cierre*</label></b-col>
      <b-col lg="5">
        <b-form-datepicker
          locale="es-ES"
          required
          :disabled="!puedeCambiarFechaPrevistaCierre"
          v-model="fechaPrevistaCierre"
          :min="fechaOferta"
          :start-weekday="1"
        ></b-form-datepicker
      ></b-col>
      <b-col class="p-2 small">
        <!-- espacio para informacion de pipe -->
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col lg="2"><label>URL Pipedrive*</label></b-col>
      <b-col lg="5">
        <b-form-input
          required
          :readonly="!puedeCambiarDatosOferta"
          type="text"
          v-model="oferta.pipedrive"
          placeholder="Introduzca la Url del negocio de Pipedrive"
        ></b-form-input>
      </b-col>
      <b-col>
        <b>Embudo</b>: {{ ofertaPipedrive.pipelineName }}<br />
        <b>Etapa</b>: {{ ofertaPipedrive.stageName }}
        <b-badge :variant="pipedriveStateColor" pill>{{ ofertaPipedrive.dealStatus }}</b-badge>
      </b-col>
    </b-row>
    <modal-crear-cliente @guardado="clienteCreado" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiClientes from "@/services/clientes";
import apiPlanes from "@/services/planes";
import apiOrigenes from "@/services/origenOferta";
import apiDuracionesContrato from "@/services/duracionesContrato";
import apiUnidadNegocio from "@/services/unidadesNegocio";
import apiTarifas from "@/services/tarifas";
import apiUsuarios from "@/services/usuarios";
import { variantePorEstado } from "@/services/pipedrive";

import * as TipoVenta from "@/enums/TipoVenta";
import * as Plazos from "@/enums/Plazos";

import { formatDateForm, formatDateAddDaysForm, formatDateSinZona, today } from "@/services/date";
import ModalCrearCliente from "./ModalCrearCliente";

export default {
  props: {
    ofertaBase: Object,
    oferta: Object,
    ofertaPipedrive: Object,
    cliente: Object,
    unidadNegocio: Object,
    fechaFirmaInicial: null,
    trazabilidad: Object,
    campanias: Array,
    ofertaDescuentosFirma: Array,
    tarifa: Object,
    usuario: Object,
    esPartner: Boolean,
  },
  components: {
    ModalCrearCliente,
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    puedeCambiarFechaPrevistaCierre() {
      return false;
    },
    mostrarCampoCliente() {
      return Boolean(this.oferta.id);
    },
    mostrarUnidadNegocio() {
      return Boolean(this.esAdmin) || (!Boolean(this.oferta.id) && !this.esPartner);
    },
    editarFechaFirma() {
      if (this.esAdmin && this.puedeCambiarDatosOferta) {
        return false;
      }
      return !this.esNuevaVersion || !this.puedeCambiarDatosOferta;
    },
    puedeCambiarDatosOferta() {
      return Boolean(this.oferta.borrador && !this.oferta.caducada);
    },
    opcionesCliente() {
      if (!this.cliente) {
        return [];
      }
      return [{ id: this.cliente.id, text: this.cliente.nombre }];
    },
    opcionesUnidadNegocio() {
      if (!this.unidadNegocio) {
        return [];
      }
      return [{ id: this.unidadNegocio.id, text: this.unidadNegocio.nombreComercial }];
    },
    opcionesTarifa() {
      if (!this.tarifa) {
        return [];
      }
      return [{ id: this.tarifa.id, text: this.tarifa.nombre }];
    },
    opcionesUsuario() {
      if (!this.usuario) {
        return [];
      }
      return [{ id: this.usuario.id, text: this.usuario.nombre }];
    },
    urlAutocompletarClientes() {
      if (this.esAdmin) {
        return "clientes/autocompletar";
      } else {
        return "clientes/autocompletarPropios";
      }
    },
    urlAutocompletarUsuarios() {
      if (this.esAdmin) {
        return "usuarios/autocompletar";
      } else {
        return "usuarios/autocompletarPropios";
      }
    },
    urlAutocompletarUnidadesNegocio() {
      if (this.esAdmin) {
        return "unidadNegocio/autocompletar";
      } else {
        return "unidadNegocio/autocompletarPropios";
      }
    },
    urlAutocompletarTarifas() {
      if (this.esAdmin) {
        return "tarifas/autocompletar";
      } else {
        return "tarifas/autocompletarPropios&unidadNegocioId=" + this.ofertaBase.unidadNegocioId;
      }
    },
    planActual() {
      return this.planes.filter((p) => p.id == this.oferta.planId);
    },
    campaniaActual() {
      return this.campanias.filter((c) => c.id == this.oferta.campaniaId);
    },
    usuariosPermitidos() {
      if (!this.oferta.planId) {
        return 0;
      }
      return this.planActual.maximoNumeroUsuarios;
    },
    fechaPrevistaCierre: {
      get() {
        let fechaLimpia = formatDateSinZona(this.oferta.fechaPrevistaCierre);
        return formatDateForm(fechaLimpia);
      },
      set(value) {
        this.oferta.fechaPrevistaCierre = value;
      },
    },
    fechaOferta: {
      get() {
        let fechaLimpia = formatDateSinZona(this.oferta.fecha);
        return formatDateForm(fechaLimpia);
      },
      set(value) {
        this.oferta.fecha = value;
      },
    },
    fechaFirma: {
      get() {
        if (!this.oferta.fechaFirmaContrato) {
          return formatDateForm(formatDateAddDaysForm(new Date(), Plazos.DIASPARAFIRMACONTRATO));
        }
        let fechaLimpia = formatDateSinZona(this.oferta.fechaFirmaContrato);
        return formatDateForm(fechaLimpia);
      },
      set(value) {
        this.oferta.fechaFirmaContrato = value;
      },
    },
    esNuevaVersion: {
      get() {
        if (!Boolean(this.oferta.id)) {
          return true;
        } else {
          if (!this.fechaFirmaInicial) {
            return true;
          } else {
            return false;
          }
        }
      },
      set(value) {
        this.esNuevaVersion = value;
      },
    },
    tiposDeVenta() {
      return TipoVenta.combo;
    },
    unidadNegocioDesactivada() {
      if ((Boolean(this.unidadNegocio.id) && !Boolean(this.esAdmin)) || !this.puedeCambiarDatosOferta) {
        return true;
      } else {
        return false;
      }
    },
    pipedriveStateColor() {
      return variantePorEstado(this.ofertaPipedrive.dealStatus);
    },
  },
  filters: {
    numUsuarios(value) {
      if (parseInt(value) > 0) {
        return value + " usuarios";
      } else {
        return "usuarios ilimitados";
      }
    },
  },
  methods: {
    async cargarPlanes() {
      this.planes = await apiPlanes.listar();
    },
    async cargarOrigenes() {
      this.origenes = await apiOrigenes.listar();
    },
    async cargarDuracionesContrato() {
      this.duracionesContrato = await apiDuracionesContrato.listar();
    },
    async cargarUsuarios() {
      var response = await apiUsuarios.listar();
      this.usuarios = response.usuarios;
    },
    cambiarCliente(id) {
      return apiClientes.cargar(id, false).then((cliente) => {
        this.$emit("clienteCambiado", cliente);
      });
    },
    cambiarUnidadNegocio(id) {
      return apiUnidadNegocio.cargar(id).then((unidadNegocio) => {
        this.$emit("unidadNegocioCambiada", unidadNegocio.unidadNegocio);
      });
    },
    cambiarTarifa(id) {
      return apiTarifas.cargar(id).then((tarifa) => {
        this.$emit("tarifaCambiada", tarifa);
      });
    },
    clienteCreado(cliente) {
      return this.cambiarCliente(cliente.id);
    },
    actualizarFechasFirma() {
      if (this.oferta.id) {
        if (!this.oferta.fechaFirmaContrato) {
          this.fechaFirma = formatDateAddDaysForm(this.fechaOferta, Plazos.DIASPARAFIRMACONTRATO);
        }
      } else {
        if (!this.oferta.fecha) {
          this.fechaFirma = formatDateAddDaysForm(new Date(), Plazos.DIASPARAFIRMACONTRATO);
        } else {
          this.fechaFirma = formatDateAddDaysForm(this.fechaOferta, Plazos.DIASPARAFIRMACONTRATO);
        }
      }
    },
    cambiarDuracionContrato() {
      this.oferta.duracionContratoId = this.campaniaActual[0].duracionContratoDefault;
      this.$emit("actualizarOfertaDescuentosFirma", this.campaniaActual[0].descuentos);
    },
    cambiarFormasDePago() {
      this.duracionFormaDePago = this.oferta.duracionContratoId;
      this.$emit("duracionFormaDePago", this.duracionFormaDePago);
    },
  },
  data() {
    return {
      planes: [],
      origenes: [],
      duracionesContrato: [],
      duracionFormaDePago: null,
      unidadesNegocio: [],
      // usuarios: [],
    };
  },
  mounted() {
    this.cargarPlanes();
    this.cargarOrigenes();
    this.actualizarFechasFirma();
    this.cargarDuracionesContrato();
    // this.cargarUsuarios();
  },
  watch: {
    oferta: function () {
      this.actualizarFechasFirma();
    },
  },
};
</script>
