<template>
  <page-listado :title="'Ofertas para ' + clientes.cliente.nombre" con-busqueda>
    <template slot="toolbar"> </template>
    <template slot="filters">
      <filtros-ofertas @filtrar="filtrar" :filtrosIniciales="{}"></filtros-ofertas>
    </template>
    <tabla-ofertas :ofertas="resultado.ofertas" />

    <paginacion :page-count="parseInt(resultado.numeroPaginas)" :page="parseInt(resultado.pagina)"></paginacion>
  </page-listado>
</template>

<script>
import apiOfertas from "@/services/ofertas.js";
import apiClientes from "@/services/clientes.js";
import TablaOfertas from "./TablaOfertas.vue";
import FiltrosOfertas from "./FiltrosOfertas.vue";

export default {
  components: {
    FiltrosOfertas,
    TablaOfertas,
  },
  data() {
    return {
      resultado: {
        ofertas: [],
        numeroPaginas: 0,
        pagina: 1,
      },
      reloadListar: false,
      clientes: { cliente: { nombre: "" } },
    };
  },
  methods: {
    listar() {
      return apiOfertas.listarOfertasDeCliente(this.$route.params.id, this.$route.query).then((resultado) => {
        this.resultado = resultado;
      });
    },
    mensaje(mensaje) {
      this.$emit("userMessage", mensaje);
      this.listar();
    },
    filtrar(data) {
      this.$route.query.filtros = data;
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
