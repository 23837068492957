<template>
  <b-modal id="modal-contactoCliente" title="Elegir Contacto" @ok="aceptar">
    <b-form-select v-model="selectedIndex" class="mb-2" ref="contactoCliente">
      <b-form-select-option v-for="(contacto, idx) in contactos" :value="idx" :key="idx">
        {{ contacto.contacto }} ({{ contacto.cargoContacto }})
      </b-form-select-option>
    </b-form-select>
  </b-modal>
</template>

<script>
export default {
  props: {
    contactos: Array,
  },
  data() {
    return {
      selectedIndex: null,
    };
  },
  methods: {
    aceptar(event) {
      if (this.selectedIndex !== null) {
        this.$emit("seleccionado", this.contactos[this.selectedIndex]);
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>
