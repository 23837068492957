<template>
  <page-edicion title="Crear forma de pago">
    <formulario :formaPago="formaPago" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/formasPago.js";

export default {
  components: { Formulario },
  data() {
    return {
      formaPago: api.crear(),
    };
  },
  methods: {
    insertar() {
      api.insertar(this.formaPago).then(() => {
        this.$emit("userMessage", "Se ha creado el registro");
        this.$router.push({ path: "/formasPago" });
      });
    },
  },
};
</script>
