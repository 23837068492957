<template>
  <page class="mt-4" title="Estadísticas de ofertas Entregadas">
    <pivot
      :data="data"
      :fields="fields"
      :available-field-keys="availableFieldKeys"
      :row-field-keys="rowFieldKeys"
      :col-field-keys="colFieldKeys"
      :reducer="reducer"
      :default-show-settings="defaultShowSettings"
      available-fields-label-text="Campos disponibles"
      rows-label-text="Filas"
      cols-label-text="Columnas"
      hide-settings-text="Ocultar opciones"
      show-settings-text="Mostrar opciones"
      select-all-text="Seleccionar todos"
      unselect-all-text="Deseleccionar todos"
    >
      <template slot="value" slot-scope="{ value }">
        {{ value }}
      </template>

      <template slot="mes" slot-scope="{ value }">
        {{ value | mes }}
      </template>
    </pivot>
  </page>
</template>

<script>
import * as apiEstadisticas from "@/services/estadisticas.js";

// PivotTable (https://github.com/Click2Buy/vue-pivot-table)
import Pivot from "@marketconnect/vue-pivot-table";

const meses = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function makeField(name, label) {
  return {
    key: name,
    getter: (item) => item[name],
    label: label,
    valueFilter: true,
  };
}

function makeMonthField(name, label) {
  return {
    key: name,
    getter: (item) => item[name],
    label: label,
    valueFilter: true,
    headerSlotName: `mes`,
  };
}

export default {
  components: { Pivot },
  data: () => {
    return {
      data: [],
      // Pivot params
      fields: [
        makeField("comercial", "Comercial"),
        makeField("pais", "País"),
        makeField("estado", "Estado"),
        makeField("anio", "Año"),
        makeMonthField("mes", "Mes"),
        makeField("campania", "Campaña"),
        makeField("origen", "Origen"),
        makeField("tipoVenta", "TipoVenta"),
        makeField("sector", "Sector"),
      ],
      availableFieldKeys: ["comercial", "pais", "campania", "origen", "tipoVenta", "sector"],
      rowFieldKeys: ["anio", "estado"],
      colFieldKeys: ["mes"],
      reducer: (sum, item) => sum + item.total,
      defaultShowSettings: true,

      // Pivot table standalone field params
      rowFields: [],
      colFields: [],
    };
  },
  methods: {
    async cargar() {
      const data = await apiEstadisticas.ofertasEntregadas();
      this.data = Object.freeze(data);
    },
  },
  filters: {
    mes: (v) => meses[v],
  },
  mounted() {
    this.cargar();
  },
};
</script>
