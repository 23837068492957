export function variantePorEstado(estado) {
  if (!estado) {
    return "secondary";
  }
  if (estado == "won") {
    return "success";
  }
  if (estado == "lost") {
    return "danger";
  }
  if (String(estado).includes("deleted")) {
    return "black";
  }
  return "warning";
}
