<template>
  <page>
    <b-form @submit.prevent="guardar" class="formularioContenido container">
      <div class="content-card">
        <h1>Editar contenido de la Oferta</h1>

        <b-tabs content-class="mt-3" fill>
          <b-tab title="Carta de presentación" active>
            <b-form-group label="Carta de presentación">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.texto" />
            </b-form-group>
          </b-tab>
          <b-tab title="Necesidades">
            <b-form-group label="Necesidades">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.necesidades" />
            </b-form-group>
          </b-tab>
          <b-tab title="Resumen Ejecutivo">
            <b-form-group label="Resumen Ejecutivo">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.resumenEjecutivo" />
            </b-form-group>
          </b-tab>
          <b-tab title="Aclaraciones">
            <b-form-group label="Aclaraciones">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.aclaraciones" />
            </b-form-group>
          </b-tab>

          <b-tab title="Logos">
            <b-form-group label="Logos de Clientes">
              <wysiwyg v-model="ofertaContenido.logosClientes" />
            </b-form-group>
          </b-tab>

          <b-tab title="Anexo">
            <b-form-group label="Anexo">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.anexo" />
            </b-form-group>
          </b-tab>

          <b-tab title="Más (Extendido)">
            <b-form-group label="Beneficios">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.beneficios" />
            </b-form-group>
            <b-form-group label="Usuarios">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.usuarios" />
            </b-form-group>
            <b-form-group label="Plan de soporte">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.planDeSoporte" />
            </b-form-group>
            <b-form-group label="Requisitos mínimos">
              <wysiwyg placeholder="Escriba aquí" v-model="ofertaContenido.requisitosMinimos" />
            </b-form-group>
          </b-tab>
        </b-tabs>

        <botonera-edicion url-cancelar="/ofertas" />
      </div>
    </b-form>
  </page>
</template>

<script>
import apiOfertas from "@/services/ofertas.js";

export default {
  data() {
    return {
      ofertaContenido: apiOfertas.crearOfertaContenido(),
    };
  },
  methods: {
    async guardar() {
      await apiOfertas.guardarContenidoVersion(this.ofertaContenido);
      this.$router.push("/ofertas");
    },
  },
  async mounted() {
    this.ofertaContenido = await apiOfertas.cargarContenidoVersion(this.$route.params.id);
  },
};
</script>
