<template>
  <table id="TablaContactos" class="table table-bordered table-striped tablaModulos">
    <thead>
      <th>Nombre*</th>
      <th>Cargo*</th>
      <th>Teléfono*</th>
      <th>Email*</th>
      <th style="padding: 0">
        <b-button
          size="sm"
          variant="primary"
          v-b-tooltip.hover
          title="Añadir Contacto"
          class="bbutton-mini"
          @click="anadirContacto()"
        >
          <v-icon name="plus" width="16" variant="primary" />
        </b-button>
      </th>
    </thead>
    <tbody>
      <sin-registros v-if="!contactos" :colspan="3"></sin-registros>
      <tr v-for="(contacto, idx) in contactos" v-bind:key="idx">
        <td class="td-flex-row">
          <b-form-checkbox
            :disabled="contacto.default"
            v-model="contacto.default"
            v-b-tooltip.hover.left
            title="Marcar como favorito"
            @change="checkFavorito(idx)"
          >
          </b-form-checkbox>
          <b-form-input
            required
            type="text"
            v-model="contacto.contacto"
            placeholder="Contacto del cliente"
          ></b-form-input>
        </td>
        <td>
          <b-form-input
            required
            type="text"
            v-model="contacto.cargoContacto"
            placeholder="Cargo del contacto"
          ></b-form-input>
        </td>
        <td>
          <b-form-input required type="text" v-model="contacto.telefono" placeholder="Teléfono"></b-form-input>
        </td>
        <td>
          <b-form-input required type="text" v-model="contacto.email" placeholder="Email"></b-form-input>
        </td>
        <td style="text-align: center">
          <b-button
            :disabled="contactos.length == 1 || contactos[idx].default"
            size="sm"
            variant="danger"
            class="bbutton-mini"
            @click="quitarContacto(idx)"
          >
            <v-icon variant="danger" name="trash-2" width="16" />
          </b-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import Vue from "vue";
export default {
  props: {
    contactos: Array,
    clienteId: Number,
  },
  methods: {
    anadirContacto() {
      var contacto = {
        id: 0,
        contacto: "",
        cargoContacto: "",
        email: "",
        telefono: "",
        default: false,
        clienteId: this.clienteId,
      };
      Vue.set(this.contactos, this.contactos.length, contacto);
    },
    quitarContacto(idx) {
      Vue.delete(this.contactos, idx);
    },
    checkFavorito(idx) {
      for (var c in this.contactos) {
        Vue.set(this.contactos[c], "default", false);
      }
      Vue.set(this.contactos[idx], "default", true);
    },
  },
  mounted() {
    if (this.contactos.length == 0) {
      this.anadirContacto();
      this.contactos[0].default = true;
    }
  },
};
</script>
