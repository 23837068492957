<template>
  <b-modal id="modal-crearCliente" title="Crear Cliente" ref="modalCliente" @hidden="resetForm" @ok="aceptarModal">
    <b-form ref="frmCliente" @submit.stop.prevent="insertar">
      <b-form-group label="Nombre*">
        <b-form-input
          required
          autofocus
          type="text"
          v-model="cliente.nombre"
          placeholder="Nombre del cliente"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Sector*">
        <b-form-select required v-model="cliente.clienteSectorId">
          <b-form-select-option disabled selected value=""> Selecciones el sector </b-form-select-option>
          <b-form-select-option v-for="sector in sectores" :key="sector.id" :value="sector.id">
            {{ sector.nombreSector }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="País*">
        <combo id="comboPaisCliente" required :url="urlAutocompletarPais" v-model="cliente.paisId"></combo>
      </b-form-group>
      <b-form-group label="Contacto*">
        <b-form-input
          required
          type="text"
          v-model="contacto.contacto"
          placeholder="Contacto del cliente"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Cargo*">
        <b-form-input
          required
          type="text"
          v-model="contacto.cargoContacto"
          placeholder="Cargo del contacto"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Teléfono*">
        <b-form-input required type="text" v-model="contacto.telefono" placeholder="Teléfono"></b-form-input>
      </b-form-group>
      <b-form-group label="Email*">
        <b-form-input required type="email" v-model="contacto.email" placeholder="Email"></b-form-input>
      </b-form-group>
      <input type="submit" class="hiddenSubmit" />
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import apiClientes from "@/services/clientes.js";
import apiSector from "@/services/sectores.js";
import $ from "jquery";

$(document).ready(function () {
  $(document).on("focus", "#modal-crearCliente .select2-selection.select2-selection--single", function (e) {
    $("#modal-crearCliente").find("div").removeAttr("tabindex");
  });
});

export default {
  data() {
    return {
      cliente: apiClientes.crear(),
      contacto: {},
      contactos: [],
      sectores: [],
    };
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    urlAutocompletarPais() {
      if (this.esAdmin) {
        return "paises/autocompletar";
      } else {
        return "paises/autocompletarPropios";
      }
    },
  },
  methods: {
    aceptarModal(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.insertar();
    },
    insertar() {
      if (!this.$refs.frmCliente.checkValidity()) {
        this.$refs.frmCliente.reportValidity();
        return;
      }
      this.contacto.default = true;
      Vue.set(this.contactos, this.contactos.length, this.contacto);
      var cliente = {
        cliente: this.cliente,
        contactos: this.contactos,
      };
      apiClientes.insertar(cliente).then((data) => {
        this.$emit("guardado", data);
        this.$refs.modalCliente.hide();
      });
    },
    resetForm() {
      this.cliente = apiClientes.crear();
    },
    async listarSectores() {
      this.sectores = await apiSector.listar();
    },
  },
  async mounted() {
    await Promise.all([this.listarSectores()]);
  },
};
</script>
