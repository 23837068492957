export const VN = 1;
export const VC = 2;
export const VR = 3;

export const tipos = [VN, VC, VR];
export const combo = tipos.map((x) => {
  return { id: x, nombre: nombre(x) };
});

export function nombre(tipo) {
  switch (tipo) {
    case VN:
      return "Venta nueva (VN)";
    case VC:
      return "Venta cruzada (VC)";
    case VR:
      return "Venta renovación (VR)";
  }
}

export function nombreCorto(tipo) {
  switch (tipo) {
    case VN:
      return "Venta nueva";
    case VC:
      return "Venta cruzada";
    case VR:
      return "Venta renovación";
  }
}

export function abreviatura(tipo) {
  switch (tipo) {
    case VN:
      return "VN";
    case VC:
      return "VC";
    case VR:
      return "VR";
  }
}
