<template>
  <div class="campo-imagen mt-1">
    <b-form-group :label="title">
      <b-form-file
        ref="file"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
        accept=""
        :drop-placeholder="dropText"
        :multiple="esMultiple"
        :browse-text="browseText"
        @input="cambiarAnexo"
      >
      </b-form-file>
      <table class="mt-4 listado table table-hover" v-if="tieneFicheros">
        <thead>
          <th class="text-left">Nombre del fichero</th>
          <th class="text-center">Subido por</th>
          <th class="text-center">Fecha subida</th>
          <th width="50" class="text-right">Acciones</th>
        </thead>
        <tbody>
          <tr v-for="fichero in listaFicheros" v-bind:key="fichero.id">
            <td col="4" v-on:click="$emit('descargarFichero', fichero.id)" role="button">
              {{ fichero.nombre }}
            </td>
            <td col="3" class="text-center" v-on:click="$emit('descargarFichero', fichero.id)" role="button">
              {{ fichero.usuario }}
            </td>
            <td col="3" class="text-center" v-on:click="$emit('descargarFichero', fichero.id)" role="button">
              {{ formatFecha(fichero.creadoEl) }}
            </td>
            <td lg="2" class="text-right">
              <boton-eliminar @ok="$emit('eliminar', fichero.id)" :textoBoton="textoBoton">
                ¿ Desea eliminar el archivo
                <b> {{ fichero.nombre }} </b> ?
              </boton-eliminar>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form-group>
  </div>
</template>
<script>
import { formatDateTabla } from "@/services/date";
export default {
  props: {
    title: String,
    value: [],
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Elija uno o varios documentos y arrástrelos aquí...",
    },
    browseText: {
      type: String,
      default: "Explorar",
    },
    dropText: {
      type: String,
      default: "Suelte aquí los documentos...",
    },
    esMultiple: {
      type: Boolean,
      default: true,
    },
    listaFicheros: Array,
  },
  computed: {
    tieneFicheros() {
      return this.listaFicheros.length > 0;
    },
  },
  methods: {
    formatFecha(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    cambiarAnexo(ficheros) {
      var name = "";

      for (var i = 0; i < ficheros.length; i++) {
        name = ficheros[i].name;
        this.enviarFichero(name, ficheros[i]);
      }
    },
    enviarFichero(nombre, fichero) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.$emit("input", {
          name: nombre,
          content: e.target.result,
        });
      };
      reader.readAsDataURL(fichero);
    },
  },
  data() {
    return {
      textoBoton: "",
    };
  },
};
</script>
