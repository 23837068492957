<template>
  <page-edicion title="Crear plantilla">
    <formulario
      :plantillaContrato="plantillaContrato"
      :unidadesNegocioId="unidadesNegocioId"
      @submit="insertar"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/plantillasContrato.js";

export default {
  components: { Formulario },
  data() {
    return {
      plantillaContrato: api.crear(),
      unidadesNegocioId: [],
    };
  },
  methods: {
    insertar(unidadesNegocioSeleccionadas) {
      var plantilla = {
        plantillaContrato: this.plantillaContrato,
        unidadesNegocio: unidadesNegocioSeleccionadas,
      };
      api.insertar(plantilla).then(() => {
        this.$router.push({ path: "/plantillasContrato" });
      });
    },
  },
  mounted() {},
};
</script>
