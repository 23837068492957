<template>
  <page-edicion title="Crear unidad de negocio">
    <formulario :unidadNegocio="unidadNegocio" :paisesId="paisesId" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/unidadesNegocio.js";

export default {
  components: { Formulario },
  data() {
    return {
      unidadNegocio: api.crear(),
      paisesId: [],
    };
  },
  methods: {
    insertar(paisesSeleccionados) {
      var unidadNegocio = {
        unidadNegocio: this.unidadNegocio,
        paises: paisesSeleccionados,
      };
      api.insertar(unidadNegocio).then(() => {
        this.$router.push({ path: "/unidadesNegocio" });
      });
    },
  },
};
</script>
