<template>
  <div>
    <b-row class="mb-2 border-bottom pb-1">
      <b-col md="4"><label>Complejidad del proyecto</label></b-col>
      <b-col>
        <span v-if="complejidad">
          {{ complejidad.descripcion }}
        </span>
      </b-col>
      <b-col md="3">
        <b-button
          variant="primary"
          size="sm"
          class="pull-right bbutton-mini"
          v-b-modal.modal-complejidadProyecto
          v-if="oferta.borrador && Boolean(complejidades) && !oferta.caducada"
        >
          <v-icon variant="primary" name="edit" width="16" /> Editar
        </b-button>
      </b-col>
    </b-row>
    <modal-complejidad-proyecto :complejidadProyectoId="value" :complejidades="complejidades" @ok="cambiar" />
  </div>
</template>

<script>
import ModalComplejidadProyecto from "./ModalComplejidadProyecto.vue";

export default {
  props: {
    oferta: { type: Object, required: true },
    value: Number,
    complejidades: Array,
  },
  components: { ModalComplejidadProyecto },
  computed: {
    complejidad() {
      return this.complejidades.find((d) => d.id == this.value);
    },
  },
  methods: {
    cambiar(complejidadProyectoId) {
      this.$emit("input", complejidadProyectoId);
    },
  },
};
</script>
