<template>
  <page-edicion title="Editar cliente">
    <p>Creado el {{ cliente.creadoEl | fecha }}</p>

    <formulario
      :cliente="cliente"
      :imagenes="imagenes"
      :pais="pais"
      :contactos="contactos"
      @submit="actualizar"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/clientes.js";

export default {
  components: { Formulario },
  data() {
    return {
      cliente: {},
      imagenes: {},
      pais: {},
      contactos: [],
    };
  },
  methods: {
    cargar(id) {
      api
        .cargar(id)
        .then((response) => {
          this.cliente = response.cliente;
          this.imagenes = response.imagenes;
          this.pais = response.pais;
          this.contactos = response.contactos;
          this.asignarFavoritoPorDefecto(this.cliente.clienteContactoPorDefectoId);
        })
        .catch((err) => {
          this.$router.replace("/clientes");
        });
    },
    actualizar() {
      var data = {
        cliente: this.cliente,
        imagenes: this.imagenes,
        contactos: this.contactos,
      };
      api.actualizar(data).then(() => {
        this.$emit("userMessage", "Se ha actualizado el cliente");
        this.$router.push({ path: "/clientes" });
      });
    },
    asignarFavoritoPorDefecto(idContacto) {
      for (var c in this.contactos) {
        var cont = this.contactos[c];
        if (cont.id == idContacto) {
          cont.default = true;
        }
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
