<template>
  <page-edicion title="Editar plantilla">
    <formulario
      :plantillaContenido="plantillaContenido"
      :paisesId="paisesId"
      :opcionesPaises="opcionesPaises"
      @submit="actualizar"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiPaises from "@/services/paises.js";
import api from "@/services/plantillasContenido.js";

export default {
  components: { Formulario },
  data() {
    return {
      plantillaContenido: {},
      paisesId: [],
      opcionesPaises: [],
      paises: [],
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.plantillaContenido = response.plantillaContenido;
        this.paises = response.paises;
        var p = {};
        for (p in this.paises) {
          apiPaises.cargar(this.paises[p].paisId).then((response) => {
            this.paisesId.push(response.pais.id); //array id paises para v-model
            //array opcines nombre pais para opciones selected combo multiple
            this.opcionesPaises.push({
              id: response.pais.id,
              text: response.pais.nombre,
            });
          });
        }
      });
    },
    actualizar(paisesSeleccionados) {
      if (paisesSeleccionados.length > 0) {
        this.paises = paisesSeleccionados;
      }
      var plantilla = {
        plantillaContenido: this.plantillaContenido,
        paises: this.paises,
      };
      api.actualizar(plantilla).then(() => {
        this.$router.push({ path: "/plantillasContenido" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
