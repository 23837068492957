<template>
  <page-edicion title="Crear Contrato">
    <formulario :contratoCliente="contratoCliente" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/contratosCliente.js";

export default {
  components: { Formulario },
  data() {
    return {
      contratoCliente: api.crear(),
    };
  },
  methods: {
    insertar() {
      var contratoCliente = {
        contratoCliente: this.contratoCliente,
      };
      api.insertar(contratoCliente).then(() => {
        this.$router.push({ path: "/contratos" });
      });
    },
  },
};
</script>
