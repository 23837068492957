import api from "./backend";
import { listado } from "./listado";

function crear() {
  return {
    id: 0,
    nombre: "",
    meses: 0,
    tipoDescuentoAsociado: "",
    defaultValue: 0,
  };
}

function listar(params) {
  return listado("duracionContrato/listar", params);
}

function combo() {
  return listar().then((duracionesContrato) => {
    return duracionesContrato.map((dc) => {
      return {
        text: dc.nombre,
        value: dc.id,
      };
    });
  });
}

function cargar(id) {
  return api.get("duracionContrato/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(duracionContrato) {
  return api.post("duracionContrato/insertar", { duracionContrato });
}

function actualizar(duracionContrato) {
  return api.post("duracionContrato/actualizar", { duracionContrato });
}

function eliminar(id) {
  return api.post("duracionContrato/eliminar", { id });
}

export default { crear, listar, combo, cargar, insertar, actualizar, eliminar };
