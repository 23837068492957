<template>
  <page-listado :fluid="true" title="Campañas">
    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/campanias/crear">
        <v-icon name="plus" width="16"></v-icon>
        Crear campaña
      </b-button>
    </template>
    <tabla :campanias="campanias" @eliminar="eliminar"></tabla>
  </page-listado>
</template>

<script>
import api from "@/services/campanias.js";
import Tabla from "./TablaCampanias.vue";
export default {
  components: { Tabla },
  data() {
    return {
      campanias: [],
    };
  },
  methods: {
    listar() {
      return api.listar(this.$route.query).then((campanias) => {
        this.campanias = campanias;
      });
    },
    eliminar(id) {
      api.eliminar(id).then(() => {
        this.listar();
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
