<template>
  <b-container fluid>
    <b-card>
      <h1 class="mb-4">{{ title }}</h1>

      <slot></slot>
    </b-card>
  </b-container>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
