export const ANUAL = 1;
export const MENSUAL = 2;
export const OTRAS = 3;

export const tiposCuotaFormaPago = Object.freeze({
  [ANUAL]: { value: ANUAL, text: "Anual" },
  [MENSUAL]: { value: MENSUAL, text: "Mensual" },
  [OTRAS]: { value: OTRAS, text: "Otras" },
});

export function nombreTipoCuotaFormaPago(tipo) {
  if (!tiposCuotaFormaPago.hasOwnProperty(tipo)) {
    return "N/A";
  }
  return tiposCuotaFormaPago[tipo].text;
}

export function comboTipoCuotaFormaPago() {
  return Object.values(tiposCuotaFormaPago);
}
