<template>
  <page-edicion title="Crear usuario">
    <formulario
      :usuario="usuario"
      :imagenes="imagenes"
      :paisesResponsable="paisesResponsable"
      :paisesComercial="paisesComercial"
      :unidadesNegocio="unidadesNegocio"
      :descuentos="descuentos"
      :opcionesPaisesResponsable="opcionesPaisesResponsable"
      :opcionesPaisesComercial="opcionesPaisesComercial"
      :opcionesUnidadesNegocio="opcionesUnidadesNegocio"
      :opcionesDescuentos="opcionesDescuentos"
      @submit="insertar"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/usuarios.js";

export default {
  components: { Formulario },
  data() {
    return {
      usuario: api.crear(),
      imagenes: {},
      paisesResponsable: [],
      paisesComercial: [],
      opcionesPaisesResponsable: [],
      opcionesPaisesComercial: [],
      opcionesUnidadesNegocio: [],
      opcionesDescuentos: [],
      unidadesNegocio: [],
      descuentos: [],
    };
  },
  methods: {
    insertar(params) {
      var usuarioCompleto = {
        usuario: this.usuario,
        imagenes: this.imagenes,
        paisesResponsable: this.paisesResponsable,
        paisesComercial: this.paisesComercial,
        unidadesNegocio: this.unidadesNegocio,
        descuentos: this.descuentos,
      };
      api.insertar(usuarioCompleto).then(() => {
        this.$emit("userMessage", "Se ha creado el usuario");
        this.$router.push({ path: "/usuarios" });
      });
    },
  },
};
</script>
