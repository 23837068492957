<template>
  <div class="mb-4">
    <b-input-group :prepend="concepto" class="grupoComboModuloOferta">
      <div class="combo-modulos">
        <combo :url="endpoint" v-model="value" :disabled="disabled"></combo>
      </div>
      <b-input-group-append>
        <b-button @click="moduloSeleccionado" variant="primary" title="Añadir Módulo" class="buttonAddModulo">
          <v-icon name="plus" width="17" style="margin-top: 2px" />
          <span class="align-middle ml-1">Agregar</span>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import Vue from "vue";
//import TablaModulosLibresOferta from "./TablaModulosLibresOferta.vue";
import apiOfertas from "@/services/ofertas";
export default {
  props: {
    concepto: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    moduloSeleccionado(event) {
      this.$emit("input", this.value);
      this.value = null;
    },
  },
  data() {
    return {
      value: null,
    };
  },
};
</script>
