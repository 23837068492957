<template>
  <page-listado :fluid="true" title="Precios Hora">
    <formulario-precios-hora :preciosHora="preciosHora" @submit="actualizar"></formulario-precios-hora>
  </page-listado>
</template>

<script>
import api from "@/services/preciosHora.js";
import FormularioPreciosHora from "./FormularioPreciosHora.vue";
export default {
  components: { FormularioPreciosHora },
  data() {
    return {
      preciosHora: [],
    };
  },
  methods: {
    listar() {
      return api.listar(this.$route.query).then((resultado) => {
        this.preciosHora = resultado.preciosHora;
      });
    },
    actualizar() {
      api.actualizar(this.preciosHora).then(() => {
        this.$emit("userMessage", "Precios Hora actualizados");
      });
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
