export const PENDIENTE = 0;
export const RENOVADO = 1;
export const CANCELADO = 2;
export const ENPROCESO = 3;
export const INICIAL = 4;

export const nombres = Object.freeze({
  [PENDIENTE]: "Pendiente",
  [RENOVADO]: "Renovado",
  [CANCELADO]: "Cancelado",
  [ENPROCESO]: "En proceso",
  [INICIAL]: "Inicial",
});

export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});

export default { PENDIENTE, RENOVADO, CANCELADO, ENPROCESO, INICIAL, nombres, combo };
