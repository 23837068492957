import api from "./backend";

/**
 * Estructura de ofertas:
 *
 *   [{comercial, cliente, pais, estado, anio}, ...]
 **/
export async function ofertas() {
  const response = await api.get("estadisticas/ofertas");
  return response.data;
}

export async function ofertasEntregadas() {
  const response = await api.get("estadisticas/ofertasEntregadas");
  return response.data;
}

export async function modulos(modulos) {
  const response = await api.get("estadisticas/modulos&ids=" + modulos.join(","));
  return response.data;
}
