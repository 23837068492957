<template>
  <page-edicion title="Editar Contrato">
    <formulario
      :contratoCliente="contratoCliente"
      @submit="actualizar"
      :pais="pais"
      :opcionesPaises="paises"
      :cliente="cliente"
      :unidadNegocio="unidadNegocio"
      :campania="campania"
      :plan="plan"
      :divisa="divisa"
      :oferta="oferta"
      :ofertaModulos="soloModulos"
      :ofertaServicios="soloServicios"
      :anexos="anexos"
      @eliminarAnexos="eliminarAnexos"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/contratosCliente.js";
import apiPaises from "@/services/paises.js";
import apiDivisas from "@/services/divisas.js";
import apiClientes from "@/services/clientes.js";
import apiCampanias from "@/services/campanias.js";
import apiPlanes from "@/services/planes.js";
import apiUnidadesNegocio from "@/services/unidadesNegocio.js";
import apiContratosCliente from "@/services/contratosCliente.js";
import apiOfertas from "@/services/ofertas";
import * as TipoModulo from "@/enums/TipoModulo";

export default {
  components: { Formulario },
  data() {
    return {
      contratoCliente: apiContratosCliente.crear(),
      paises: [],
      pais: apiPaises.crear(),
      cliente: apiClientes.crear(),
      unidadNegocio: apiUnidadesNegocio.crear(),
      campania: apiCampanias.crear(),
      plan: apiPlanes.crear(),
      paisesId: [],
      opcionesPaises: [],
      opcionesClientes: [],
      divisa: apiDivisas.crear(),
      oferta: apiOfertas.crearOferta(),
      ofertaModulos: [],
      modulos: [],
      anexos: [],
      anexosEliminar: [],
    };
  },
  computed: {
    soloModulos() {
      return this.ofertaModulos.filter((x) => this.modulos[x.moduloId].tipo != TipoModulo.SERVICIO);
    },
    soloServicios() {
      return this.ofertaModulos.filter((x) => this.modulos[x.moduloId].tipo == TipoModulo.SERVICIO);
    },
  },
  methods: {
    async cargar(id) {
      var data = await api.cargar(id);
      this.contratoCliente = data.contratoCliente;
      this.anexos = data.anexos;
      await this.cargarCliente(data.contratoCliente.clienteId);
      await this.cargarPais(data.contratoCliente.paisId);
      if (data.contratoCliente.unidadNegocioId) await this.cargarUnidadNegocio(data.contratoCliente.unidadNegocioId);
      await this.cargarCampania(data.contratoCliente.campaniaId);
      await this.cargarPlan(data.contratoCliente.planId);
      await this.cargarPaises(data.contratoCliente.unidadNegocioId);
      await this.cargarDivisas(data.contratoCliente.divisaId);
      if (data.contratoCliente.ofertaId) await this.cargarOferta(data.contratoCliente.ofertaId);
    },
    actualizar(anexosDatos) {
      if (this.contratoCliente.ofertaId == 0) {
        this.contratoCliente.ofertaId = null;
      }
      if (this.contratoCliente.ofertaRenovacionId == 0) {
        this.contratoCliente.ofertaRenovacionId = null;
      }
      if (this.contratoCliente.contratoOrigenId == 0) {
        this.contratoCliente.contratoOrigenId = null;
      }
      var contratoCliente = {
        contratoCliente: this.contratoCliente,
        eliminarAnexos: this.anexosEliminar,
        ficherosAnexos: anexosDatos,
      };
      api.actualizar(contratoCliente).then(() => {
        this.cargar(this.$route.params.id);
      });
    },
    async cargarCliente(id) {
      const data = await apiClientes.cargar(id, false);
      this.cliente = data.cliente;
    },
    async cargarPais(id) {
      const data = await apiPaises.cargar(id);
      this.pais = data.pais;
    },
    async cargarUnidadNegocio(id) {
      const data = await apiUnidadesNegocio.cargar(id);
      this.unidadNegocio = data.unidadNegocio;
    },
    async cargarCampania(id) {
      const data = await apiCampanias.cargar(id);
      this.campania = data.campania;
    },
    async cargarPlan(id) {
      const data = await apiPlanes.cargar(id);
      this.plan = data.plan;
    },
    async cargarPaises(unidadNegocioId) {
      this.paises = await apiUnidadesNegocio.obtenerPaises(unidadNegocioId);

      if (this.paises.length) {
        this.contratoCliente.paisId = this.paises[0].id;
      }
    },
    async cargarDivisas(id) {
      const data = await apiDivisas.cargar(id);
      this.divisa = data.divisa;
    },
    async cargarOferta(id) {
      const data = await apiOfertas.cargar(id);
      this.oferta = data.oferta;
      this.ofertaModulos = data.ofertaModulos;
      this.modulos = data.modulos;
    },
    eliminarAnexos(anexosActualizados, contratoClienteAnexoId) {
      this.anexos = anexosActualizados;
      this.anexosEliminar.push(contratoClienteAnexoId);
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
