import Listar from "@/views/modulos/Listar.vue";
import Crear from "@/views/modulos/Crear.vue";
import Editar from "@/views/modulos/Editar.vue";
import EditarHoras from "@/views/modulos/horas/EditarHoras.vue";
import EditarPrecios from "@/views/modulos/precios/EditarPrecios.vue";

export default [
  {
    path: "/modulos",
    component: Listar,
  },
  {
    path: "/modulos/crear",
    component: Crear,
  },
  {
    path: "/modulos/editar/:id",
    component: Editar,
  },
  {
    path: "/modulos/editarHoras/:id",
    component: EditarHoras,
  },
  {
    path: "/modulos/editarPrecios/:id",
    component: EditarPrecios,
  },
];
