export const INVERSION = 1;
export const SAAS = 2;
export const nombres = Object.freeze({
  [INVERSION]: "Inversión",
  [SAAS]: "SaaS",
});
export const combo = Object.keys(nombres).map((k) => {
  return { value: k, text: nombres[k] };
});
export default { INVERSION, SAAS, nombres, combo };
