import { listado } from "./listado";
import api from "./backend";

function crear() {
  return {
    id: 0,
    origen: "",
  };
}

function listar(params) {
  return listado("origenOferta/listar", params);
}

function paraCombo() {
  return api.get("origenOferta/paraCombo").then((r) => r.data);
}

function cargar(id) {
  return api.get("origenOferta/cargar&id=" + id).then((response) => {
    return response.data;
  });
}

function insertar(origenOferta) {
  return api.post("origenOferta/insertar", { origenOferta });
}

function actualizar(origenOferta) {
  return api.post("origenOferta/actualizar", { origenOferta });
}

function eliminar(id) {
  return api.post("origenOferta/eliminar", { id });
}

export default { crear, listar, paraCombo, cargar, insertar, actualizar, eliminar };
