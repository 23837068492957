<template>
  <b-form @submit.prevent="$emit('submit')">
    <b-form-group label="Nombre">
      <b-form-input required type="text" v-model="campania.nombre" placeholder="Nombre de la campaña"></b-form-input>
    </b-form-group>
    <b-form-group label="Duracion de contrato por defecto">
      <b-form-select required v-model="campania.duracionContratoDefault">
        <b-form-select-option disabled selected value="">
          Seleccione duracion del contrato por defecto
        </b-form-select-option>
        <b-form-select-option
          v-for="duracionContrato in duracionesContrato"
          :key="duracionContrato.id"
          :value="duracionContrato.id"
        >
          {{ duracionContrato.nombre }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-checkbox switch size="lg" v-model="campania.forzarProyectoSaas">Forzar proyecto SaaS</b-form-checkbox>

    <b-row class="mt-4">
      <b-col md="3" v-for="(descuento, index) in descuentos" :key="index">
        <b-form-group :label="'Descuento ' + descuento.nombre">
          <b-form-input
            type="number"
            v-model="descuento.descuento"
            min="0"
            placeholder="Porcentaje de descuento"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <botonera-edicion url-cancelar="/campanias" />
  </b-form>
</template>
<script>
import apiDuracionesContrato from "@/services/duracionesContrato";

export default {
  props: {
    campania: Object,
    descuentos: Array,
  },
  methods: {
    async cargarDuracionesContrato() {
      this.duracionesContrato = await apiDuracionesContrato.listar();
    },
  },
  data() {
    return {
      duracionesContrato: [],
    };
  },
  mounted() {
    this.cargarDuracionesContrato();
  },
};
</script>
