<template>
  <b-form @submit.prevent="$emit('submit')" enctype="multipart/form-data">
    <h2 class="pb-1">Horas de Consultoría y Capacitación</h2>

    <table class="table TablaModulosEdicionPrecios">
      <thead valign="middle">
        <th>&nbsp;</th>
        <th v-for="plan in planes" v-bind:key="plan.id">{{ plan.id }}. {{ plan.nombre }}</th>
      </thead>
      <tbody>
        <tr>
          <td>Consultoría</td>
          <td v-for="plan in planes" v-bind:key="plan.id">
            <b-form-input
              v-if="moduloPlanHorasNormalizados[plan.id]"
              type="number"
              v-model="moduloPlanHorasNormalizados[plan.id].numHorasConsultoria"
              placeholder="Número de horas Consultoría"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>Capacitación</td>
          <td v-for="plan in planes" v-bind:key="plan.id">
            <b-form-input
              v-if="moduloPlanHorasNormalizados[plan.id]"
              type="number"
              v-model="moduloPlanHorasNormalizados[plan.id].numHorasCapacitacion"
              placeholder="Número de horas Capacitación"
            ></b-form-input>
          </td>
        </tr>
      </tbody>
    </table>
    <botonera-edicion url-cancelar="/modulos" />
  </b-form>
</template>

<script>
export default {
  props: {
    moduloPlanHorasNormalizados: Object,
    planes: Array,
  },
};
</script>
