<template>
  <page-edicion title="Editar divisa">
    <formulario
      :divisa="divisa"
      @submit="actualizar"
      :paisesId="paisesId"
      :opcionesPaises="opcionesPaises"
    ></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/divisas.js";
import apiPaises from "@/services/paises.js";

export default {
  components: { Formulario },
  data() {
    return {
      divisa: {},
      paises: [],
      paisesId: [],
      opcionesPaises: [],
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.divisa = response.divisa;
        this.paises = response.paises;
        var p = {};
        for (p in this.paises) {
          apiPaises.cargar(this.paises[p].paisId).then((response) => {
            this.paisesId.push(response.pais.id); //array id paises para v-model
            //array opcines nombre pais para opciones selected combo multiple
            this.opcionesPaises.push({
              id: response.pais.id,
              text: response.pais.nombre,
            });
          });
        }
      });
    },
    actualizar(paisesSeleccionados) {
      if (paisesSeleccionados.length > 0) {
        this.paises = paisesSeleccionados;
      }

      var divisa = {
        divisa: this.divisa,
        paises: this.paises,
      };
      api.actualizar(divisa).then(() => {
        this.$router.push({ path: "/divisas" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
