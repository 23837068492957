<template>
  <page-edicion title="Editar el origen de oferta">
    <formulario :origenOferta="origenOferta" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import apiOrigenOferta from "@/services/origenOferta.js";

export default {
  components: { Formulario },
  data() {
    return {
      origenOferta: {},
    };
  },
  methods: {
    cargar(id) {
      apiOrigenOferta.cargar(id).then((response) => {
        this.origenOferta = response.origenOferta;
      });
    },
    actualizar() {
      apiOrigenOferta.actualizar(this.origenOferta).then(() => {
        this.$emit("userMessage", "Se ha actualizado el origen de oferta");
        this.$router.push({ path: "/origenOferta" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
