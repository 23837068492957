<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Cliente" campo="nombreCliente"></th-ordenado>
      <th-ordenado class="text-center" titulo="Oferta" campo="codigoOferta"></th-ordenado>
      <th-ordenado class="text-center" titulo="Oferta Renovación" campo="codigoOfertaRenovacion"></th-ordenado>
      <th-ordenado class="text-center" titulo="Fecha de Renovación" campo="fechaRenovacion"></th-ordenado>
      <th-ordenado class="text-center" titulo="Importe" campo="importeRenovacion"></th-ordenado>
      <th-ordenado class="text-center" titulo="P. Soporte" campo="nombrePlan"></th-ordenado>
      <th-ordenado class="text-center" titulo="Alta" campo="fechaAlta"></th-ordenado>
      <th-ordenado class="text-center" titulo="País" campo="nombrePais"></th-ordenado>
      <th-ordenado class="text-center" titulo="Campaña" campo="nombreCampania"></th-ordenado>
      <th-ordenado class="text-center" titulo="Estado" campo="estado"></th-ordenado>
      <th class="th-acciones" width="160">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!contratosClientes" :colspan="4"></sin-registros>
      <tr v-for="contratoCliente in contratosClientes" v-bind:key="contratoCliente.id" role="button">
        <td @click="editarContrato(contratoCliente.id)">
          <v-icon
            v-if="!contratoCliente.contratoOrigenId"
            class="text-success"
            name="award"
            width="16"
            v-b-tooltip.hover
            title="Contrato inicial"
          ></v-icon>
          <v-icon
            v-if="contratoCliente.contratoOrigenId"
            class="text-info"
            name="bookmark"
            width="16"
            v-b-tooltip.hover
            title="Renovación de Contrato"
          ></v-icon>
          {{ contratoCliente.nombreCliente }}
        </td>
        <td
          @click="editarOferta(contratoCliente.ofertaId)"
          class="text-center text-info font-weight-bold"
          v-b-tooltip.hover
          title="Ver oferta"
        >
          {{ contratoCliente.codigoOferta }}
        </td>
        <td
          @click="editarOferta(contratoCliente.ofertaRenovacionId)"
          class="text-center text-success font-weight-bold"
          v-if="!contratoRenovado(contratoCliente)"
          v-b-tooltip.hover
          title="Ver oferta de renovación"
        >
          {{ contratoCliente.codigoOfertaRenovacion }}
        </td>
        <td nowrap="nowrap" class="text-center text-success font-weight-bold" v-if="contratoRenovado(contratoCliente)">
          <b-button-group size="sm">
            <b-button variant="success" class="actionButton btnRenovar" @click="confirmarRenovar(contratoCliente.id)"
              ><v-icon variant="success" name="refresh-cw" width="16" /><span> Renovar</span>
              <b-modal
                :id="`modalRenovar-${contratoCliente.id}`"
                ref="modal"
                :title="tituloModalRenovar"
                @ok="$emit('renovar', contratoCliente.id)"
              >
                <slot>¿Desea continuar?</slot>
              </b-modal>
            </b-button>
          </b-button-group>
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          <span class="p-2" :class="coloresRenovacion(contratoCliente)">{{
            formatFecha(contratoCliente.fechaRenovacion)
          }}</span>
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          {{ contratoCliente.importeRenovacion }} {{ contratoCliente.divisa }}
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          {{ contratoCliente.nombrePlan }}
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          {{ formatFechaYear(contratoCliente.fechaAlta) }}
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          {{ contratoCliente.nombrePais }}
        </td>
        <td @click="editarContrato(contratoCliente.id)" class="text-center">
          {{ contratoCliente.nombreCampania }}
        </td>
        <td
          @click="editarContrato(contratoCliente.id)"
          class="text-center font-weight-bold"
          :class="colorEstadoContrato(contratoCliente.estado)"
        >
          {{ estadoContrato(contratoCliente.estado) }}
        </td>
        <td nowrap="nowrap" class="td-acciones-auto">
          <b-button-group size="sm">
            <b-button
              variant="light"
              class="p-0"
              @click="editarContrato(contratoCliente.contratoOrigenId)"
              v-if="contratoCliente.contratoOrigenId"
            >
              <v-icon
                class="text-info"
                name="arrow-down-circle"
                width="20"
                v-if="contratoCliente.contratoOrigenId"
                v-b-tooltip.hover
                title="Ver contrato origen"
            /></b-button>
            <b-button
              variant="light"
              class="p-0"
              @click="editarContrato(contratoCliente.contratoRenovadoId)"
              v-if="contratoCliente.contratoRenovadoId"
            >
              <v-icon
                class="text-success"
                name="arrow-up-circle"
                width="20"
                v-if="contratoCliente.contratoRenovadoId"
                v-b-tooltip.hover
                title="Ver contrato de renovación"
            /></b-button>
            <b-dropdown
              size="sm"
              right
              text=""
              variant="dark"
              class="actionButton ml-1 radiusLeft-2"
              :disabled="!estadoContratoRenovado(contratoCliente)"
            >
              <template slot="button-content">
                <v-icon variant="primary" name="menu" width="16" />
              </template>
              <b-dropdown-item
                v-if="puedeVerContrato(contratoCliente)"
                size="sm"
                :to="`/ofertas/editarContrato/${contratoCliente.ofertaRenovacionId}`"
                ><v-icon variant="primary" name="file-text" width="16" /> Editar Contrato Renovación</b-dropdown-item
              >
              <b-dropdown-item
                v-if="puedeVerContrato(contratoCliente)"
                :disabled="false"
                size="sm"
                target="_blank"
                :href="`${url}/ofertas/informeContrato&ofertaId=${contratoCliente.ofertaRenovacionId}&borrador=true`"
                ><v-icon variant="primary" name="printer" width="16" /> Imprimir Borrador Contrato
                Renovación</b-dropdown-item
              >
              <b-dropdown-item
                v-if="puedeVerContratoAdmin(contratoCliente)"
                :disabled="false"
                size="sm"
                target="_blank"
                :href="`${url}/ofertas/informeContrato&ofertaId=${contratoCliente.ofertaRenovacionId}`"
                ><v-icon variant="primary" name="printer" width="16" /> Imprimir Contrato Renovación</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                v-if="puedeVerContratoAdmin(contratoCliente)"
                :disabled="false"
                size="sm"
                target="_blank"
                :href="`${url}/ofertas/informeContrato&ofertaId=${contratoCliente.ofertaId}`"
                ><v-icon variant="primary" name="printer" width="16" /> Imprimir Contrato Original</b-dropdown-item
              >
            </b-dropdown>
            <b-button variant="primary" class="actionButton" :to="`/contratos/editar/${contratoCliente.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span> Editar</span>
            </b-button>
            <boton-eliminar @ok="$emit('eliminar', contratoCliente.id)" :textoBoton="textoBoton" v-if="esAdmin">
              ¿ Desea eliminar el contrato
              <b>{{ contratoCliente.nombreCliente }}</b> ?
            </boton-eliminar>
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapState } from "vuex";
import { formatDateTabla, formatDateYear } from "@/services/date";
import EstadoContrato from "@/enums/EstadoContrato";
export default {
  props: {
    contratosClientes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      textoBoton: "",
      tituloModalRenovar: "Va a crear una oferta de renovación...",
      url: process.env.VUE_APP_ENDPOINT,
    };
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
  },
  methods: {
    editarContrato(contratoClienteId) {
      this.$router.push("/contratos/editar/" + contratoClienteId);
    },
    editarOferta(ofertaId) {
      this.$router.push("/ofertas/editar/" + ofertaId);
    },
    formatFecha(fecha) {
      if (!fecha) return "-";
      return formatDateTabla(fecha);
    },
    formatFechaYear(fecha) {
      if (!fecha) return "-";
      return formatDateYear(fecha);
    },
    coloresRenovacion(contrato) {
      if (contrato.estado == EstadoContrato.RENOVADO || contrato.estado == EstadoContrato.CANCELADO) {
        return [];
      }
      var fechaRenovacion = formatDateTabla(contrato.fechaRenovacion).split("/");
      var fechaHoy = formatDateTabla(new Date()).split("/");
      var diferenciaFechas =
        new Date(fechaRenovacion[2], fechaRenovacion[1] - 1, fechaRenovacion[0]).getTime() -
        new Date(fechaHoy[2], fechaHoy[1] - 1, fechaHoy[0]).getTime();
      var diferenciaEnDias = Math.ceil(diferenciaFechas / (1000 * 3600 * 24));
      switch (true) {
        case diferenciaEnDias < 1:
          return ["bg-danger", "text-white"];
        case diferenciaEnDias < 31:
          return ["bg-warning", "text-black"];
        case diferenciaEnDias > 30 && diferenciaEnDias < 61:
          return ["bg-success", "text-white"];
        case diferenciaEnDias > 60:
          return ["bg-info", "text-white"];
        default:
          return [];
      }
    },
    estadoContrato(estado) {
      switch (estado) {
        case EstadoContrato.PENDIENTE:
          return EstadoContrato.nombres[EstadoContrato.PENDIENTE];
        case EstadoContrato.RENOVADO:
          return EstadoContrato.nombres[EstadoContrato.RENOVADO];
        case EstadoContrato.CANCELADO:
          return EstadoContrato.nombres[EstadoContrato.CANCELADO];
        case EstadoContrato.ENPROCESO:
          return EstadoContrato.nombres[EstadoContrato.ENPROCESO];
        case EstadoContrato.INICIAL:
          return EstadoContrato.nombres[EstadoContrato.INICIAL];
        default:
          break;
      }
    },
    colorEstadoContrato(estado) {
      switch (estado) {
        case EstadoContrato.PENDIENTE:
          return "text-danger";
        case EstadoContrato.RENOVADO:
          return "text-success";
        case EstadoContrato.CANCELADO:
          return "text-gray";
        case EstadoContrato.ENPROCESO:
          return "text-warning";
        case EstadoContrato.INICIAL:
          return "text-success";
        default:
          break;
      }
    },
    contratoRenovado(contratoCliente) {
      if (
        contratoCliente.estado == EstadoContrato.RENOVADO ||
        contratoCliente.estado == EstadoContrato.ENPROCESO ||
        contratoCliente.ofertaRenovacionId ||
        !contratoCliente.ofertaId
      )
        return false;
      return true;
    },
    estadoContratoRenovado(contratoCliente) {
      if (
        (contratoCliente.estado == EstadoContrato.INICIAL ||
          contratoCliente.estado == EstadoContrato.RENOVADO ||
          contratoCliente.estado == EstadoContrato.ENPROCESO) &&
        contratoCliente.ofertaId
      )
        return true;
      return false;
    },
    confirmarRenovar(contratoId) {
      this.$bvModal.show("modalRenovar-" + contratoId);
    },
    puedeVerContrato(contrato) {
      return contrato.ofertaGanada;
    },
    puedeVerContratoAdmin(contrato) {
      return this.esAdmin && contrato.ofertaGanada;
    },
  },
};
</script>
