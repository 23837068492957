import Listar from "@/views/formasPago/Listar.vue";
import Crear from "@/views/formasPago/Crear.vue";
import Editar from "@/views/formasPago/Editar.vue";

export default [
  {
    path: "/formasPago",
    component: Listar,
  },
  {
    path: "/formasPago/crear",
    component: Crear,
  },
  {
    path: "/formasPago/editar/:id",
    component: Editar,
  },
];
