<template>
  <page-edicion title="Crear divisa">
    <formulario :divisa="divisa" :paisesId="paisesId" @submit="insertar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/divisas.js";

export default {
  components: { Formulario },
  data() {
    return {
      divisa: api.crear(),
      paisesId: [],
    };
  },
  methods: {
    insertar(paisesSeleccionados) {
      var divisa = {
        divisa: this.divisa,
        paises: paisesSeleccionados,
      };
      api.insertar(divisa).then(() => {
        this.$router.push({ path: "/divisas" });
      });
    },
  },
};
</script>
