<template>
  <b-form @submit.prevent="submit">
    <b-row class="mb-3">
      <b-col lg="6">
        <b-form-group label="Cliente*">
          <combo v-model="contratoCliente.clienteId" :options="opcionesCliente" :url="urlAutocompletarClientes"></combo>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group label="Número de serie">
          <b-form-input
            type="text"
            v-model="contratoCliente.numeroSerie"
            min="0"
            placeholder="Número de serie"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="pl-5" lg="3" v-if="contratoCliente.ofertaId">
        <h5>Número Oferta</h5>
        <span>
          <span class="vam mr-2">{{ oferta.codigo }}</span>
          <b-badge variant="info" small> versión {{ oferta.numeroVersion }} </b-badge>
        </span>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="3">
        <b-form-group label="Año de Alta">
          <date-picker
            class="filter"
            v-model="fechaAlta"
            format="YYYY"
            type="year"
            placeholder="Elija año de alta"
          ></date-picker>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Fecha de Renovación">
          <b-form-datepicker locale="es-ES" v-model="fechaRenovacion" :start-weekday="1"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group label="Estado*">
          <b-form-select required v-model="contratoCliente.estado" @input="cambiarEstado">
            <b-form-select-option disabled value=""> Seleccione un estado </b-form-select-option>
            <b-form-select-option v-for="estado in opcionesEstado" :key="estado.id" :value="estado.id">
              {{ estado.nombre }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="3">
        <b-form-group label="Unidad de Negocio*">
          <combo
            v-model="contratoCliente.unidadNegocioId"
            :options="opcionesUnidadNegocio"
            :url="urlAutocompletarUnidadesNegocio"
            @input="cambiarUnidadNegocio"
          ></combo>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="País*">
          <b-form-select
            required
            v-model="contratoCliente.paisId"
            :disabled="!contratoCliente.unidadNegocioId"
            @input="cambiarPais"
            class="mb-2"
          >
            <b-form-select-option v-for="pais in opcionesDePaises" :key="pais.id" :value="pais.id">
              {{ pais.nombre }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Divisa*">
          <b-form-select v-model="contratoCliente.divisaId" :disabled="!contratoCliente.paisId" class="mb-2">
            <b-form-select-option v-for="divisa in divisas" :key="divisa.id" :value="divisa.id">
              {{ divisa.nombre }} ({{ divisa.abreviatura }})
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col lg="3">
        <b-form-group label="Campaña*">
          <combo v-model="contratoCliente.campaniaId" :options="opcionesCampania" url="campanias/autocompletar"></combo>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group label="Plan de Soporte*">
          <combo v-model="contratoCliente.planId" :options="opcionesPlan" url="planes/autocompletar"></combo>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group label="Importe de la Renovación*">
          <b-form-input
            required
            type="number"
            v-model="contratoCliente.importeRenovacion"
            min="0"
            placeholder="Importe de la Renovación"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="2" v-if="tieneRecargo">
        <span class="vam mt-4 pt-3"><b>Inicial: </b>{{ contratoCliente.importeRenovacionInicial }}</span>
      </b-col>
    </b-row>

    <div v-if="ofertaModulos.length || ofertaServicios.length">
      <h2>Configuración de la oferta</h2>
      <b-tabs content-class="mt-3">
        <b-tab title="Módulos" active v-if="ofertaModulos.length">
          <table id="TablaModulos" class="table table-bordered table-striped tablaModulos">
            <thead>
              <th width="100">Unidades</th>
              <th>Módulo</th>
              <th width="120" v-if="!calculada || !conTarifa">Suscripción</th>
              <th width="120" v-if="!calculada || !conTarifa">Cuota Anual</th>
              <th width="120" v-if="calculada && conTarifa">Precio</th>
            </thead>
            <tr v-for="(modulo, index) in ofertaModulos" :key="index">
              <td class="text-center">
                {{ modulo.cantidad }}
              </td>
              <td>{{ modulo.nombre }}</td>
              <td v-if="!calculada || !conTarifa">
                {{ modulo.precioSuscripcion }}
              </td>
              <td v-if="!calculada || !conTarifa">
                {{ modulo.precioCuota }}
              </td>
              <td class="text-center" v-if="calculada && conTarifa">
                {{ modulo.precioTarifa }}
              </td>
            </tr>
          </table>
        </b-tab>

        <b-tab title="Servicios" v-if="ofertaServicios.length">
          <table id="TablaModulos" class="table table-bordered table-striped tablaModulos">
            <thead>
              <th width="100">Unidades</th>
              <th>Servicio</th>
              <th
                width="80"
                title="Define si el servicio cubre los costes de implementación de esta oferta"
                v-if="!calculada && !conTarifa"
              >
                Imple. Cubierta
              </th>
              <th width="120">Importe</th>
            </thead>
            <tr v-for="(modulo, index) in ofertaServicios" v-bind:key="index">
              <!-- cantidad -->
              <td class="text-center">
                {{ modulo.cantidad }}
              </td>
              <!-- nombre del modulo -->
              <td>{{ modulo.nombre }}</td>
              <!-- Costes de implementación cubiertos -->
              <td class="text-center" v-if="!calculada && !conTarifa">
                <b-form-checkbox
                  switch
                  :value="true"
                  :unchecked-value="false"
                  v-model="modulo.cubreCosteDeImplementacion"
                  :disabled="true"
                >
                </b-form-checkbox>
              </td>
              <!-- Importe del servicio -->
              <td class="text-center">
                {{ modulo.precioServicio }}
              </td>
            </tr>
          </table>
        </b-tab>
      </b-tabs>
    </div>
    <div class="mb-4 mt-4">
      <h2>Anexos</h2>
      <b-row class="mb-3">
        <b-col lg="12">
          <b-form-group label="Añadir ficheros*">
            <campo-fichero-multiple
              :listaFicheros="anexos"
              @eliminar="eliminarAnexo"
              @input="anadirAnexos"
              @descargarFichero="descargarFichero"
            >
            </campo-fichero-multiple>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <botonera-edicion url-cancelar="/contratos" />
  </b-form>
</template>
<script>
import { mapState } from "vuex";
import apiPaises from "@/services/paises.js";
import apiUnidadesNegocio from "@/services/unidadesNegocio.js";
import { formatDateForm } from "@/services/date";
import EstadoContrato from "@/enums/EstadoContrato";
import CampoFicheroMultiple from "../../components/CampoFicheroMultiple.vue";
export default {
  components: { CampoFicheroMultiple },
  props: {
    contratoCliente: Object,
    pais: Object,
    divisa: Object,
    plan: Object,
    cliente: Object,
    campania: Object,
    unidadNegocio: Object,
    opcionesPaises: Array,
    oferta: Object,
    ofertaModulos: Array,
    ofertaServicios: Array,
    anexos: Array,
  },
  data() {
    return {
      divisas: [],
      paises: [],
      anexosDatos: [],
      url: process.env.VUE_APP_ENDPOINT,
    };
  },
  computed: {
    ...mapState("session", ["esAdmin"]),
    opcionesPais() {
      if (!this.pais) {
        return [];
      }
      return [{ id: this.pais.id, text: this.pais.nombre }];
    },
    opcionesDivisa() {
      if (!this.divisa) {
        return [];
      }
      return [{ id: this.divisa.id, text: this.divisa.nombre }];
    },
    opcionesCliente() {
      if (!this.cliente) {
        return [];
      }
      return [{ id: this.cliente.id, text: this.cliente.nombre }];
    },
    opcionesPlan() {
      if (!this.plan) {
        return [];
      }
      return [{ id: this.plan.id, text: this.plan.nombre }];
    },
    opcionesCampania() {
      if (!this.campania) {
        return [];
      }
      return [{ id: this.campania.id, text: this.campania.nombre }];
    },
    opcionesUnidadNegocio() {
      if (!this.unidadNegocio) {
        return [];
      }
      return [{ id: this.unidadNegocio.id, text: this.unidadNegocio.nombreComercial }];
    },
    urlAutocompletarClientes() {
      if (this.esAdmin) {
        return "clientes/autocompletarConGanadas";
      } else {
        return "clientes/autocompletarConGanadasPropios";
      }
    },
    urlAutocompletarUnidadesNegocio() {
      if (this.esAdmin) {
        return "unidadNegocio/autocompletar";
      } else {
        return "unidadNegocio/autocompletarPropios";
      }
    },
    urlAutocompletarPaises() {
      if (this.esAdmin) {
        return "paises/autocompletar";
      } else {
        return "paises/autocompletarPropios";
      }
    },
    urlAutocompletarDivisas() {
      if (this.esAdmin) {
        return "divisas/autocompletar";
      } else {
        return "divisas/autocompletarPropios";
      }
    },
    opcionesEstado() {
      return [
        { id: EstadoContrato.PENDIENTE, nombre: EstadoContrato.nombres[EstadoContrato.PENDIENTE] },
        { id: EstadoContrato.RENOVADO, nombre: EstadoContrato.nombres[EstadoContrato.RENOVADO] },
        { id: EstadoContrato.CANCELADO, nombre: EstadoContrato.nombres[EstadoContrato.CANCELADO] },
        { id: EstadoContrato.ENPROCESO, nombre: EstadoContrato.nombres[EstadoContrato.ENPROCESO] },
        { id: EstadoContrato.INICIAL, nombre: EstadoContrato.nombres[EstadoContrato.INICIAL] },
      ];
    },
    fechaRenovacion: {
      get() {
        return formatDateForm(this.contratoCliente.fechaRenovacion);
      },
      set(value) {
        this.contratoCliente.fechaRenovacion = value;
      },
    },
    fechaAlta: {
      get() {
        return new Date(this.contratoCliente.fechaAlta);
      },
      set(value) {
        this.contratoCliente.fechaAlta = value;
      },
    },
    opcionesDePaises() {
      if (this.paises.length) {
        return this.paises;
      }
      return this.opcionesPaises;
    },
    tieneRecargo() {
      if (this.contratoCliente.importeRenovacion != this.contratoCliente.importeRenovacionInicial) return true;
      return false;
    },
    calculada() {
      return Boolean(this.oferta.calculada);
    },
    conTarifa() {
      return Boolean(this.oferta.tarifaId);
    },
  },
  methods: {
    cambiarPais() {
      this.cargarDivisas(this.contratoCliente.paisId);
    },
    cambiarUnidadNegocio() {
      this.cargarPaises(this.contratoCliente.unidadNegocioId);
    },
    cambiarEstado() {
      if (this.contratoCliente.estado == EstadoContrato.RENOVADO) {
        this.contratoCliente.fechaRenovacion = new Date();
      }
    },
    async cargarDivisas(paisId) {
      this.divisas = await apiPaises.obtenerDivisa(paisId);

      if (this.divisas.length == 1) {
        this.contratoCliente.divisaId = this.divisas[0].id;
      }
    },
    async cargarPaises(unidadNegocioId) {
      this.paises = await apiUnidadesNegocio.obtenerPaises(unidadNegocioId);

      if (this.paises.length == 1) {
        this.contratoCliente.paisId = this.paises[0].id;
        this.cargarDivisas(this.paises[0].id);
      }
    },
    eliminarAnexo(contratoClienteAnexoId) {
      var anexosActualizados = this.anexos.filter((item) => item.id !== contratoClienteAnexoId);
      this.$emit("eliminarAnexos", anexosActualizados, contratoClienteAnexoId);
    },
    anadirAnexos(anexo) {
      if (!Array.isArray(anexo)) {
        this.anexosDatos.push(anexo);
      }
    },
    descargarFichero(contratoClienteAnexoId) {
      window.open(
        this.url +
          "/contratosClientes/descargarAnexo&contratoClienteId=" +
          this.contratoCliente.id +
          "&contratoClienteAnexoId=" +
          contratoClienteAnexoId,
        "_blank"
      );
    },
    submit() {
      this.$emit("submit", this.anexosDatos);
    },
  },
  watch: {
    "$route.params": {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },
};
</script>
