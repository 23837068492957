<template>
  <page-edicion title="Editar factor de complejidad de proyecto">
    <formulario :complejidadProyecto="complejidadProyecto" @submit="actualizar"></formulario>
  </page-edicion>
</template>

<script>
import Formulario from "./Formulario.vue";
import api from "@/services/complejidadesProyecto.js";

export default {
  components: { Formulario },
  data() {
    return {
      complejidadProyecto: {},
    };
  },
  methods: {
    cargar(id) {
      api.cargar(id).then((response) => {
        this.complejidadProyecto = response.complejidadProyecto;
      });
    },
    actualizar() {
      api.actualizar(this.complejidadProyecto).then(() => {
        this.$emit("userMessage", "Se ha actualizado el registro");
        this.$router.push({ path: "/complejidadesProyecto" });
      });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
