<template>
  <table class="listado table table-hover">
    <thead class="bg-primary text-white">
      <th-ordenado titulo="Nombre" campo="nombre"></th-ordenado>
      <th-ordenado titulo="Email" campo="email"></th-ordenado>
      <th-ordenado titulo="Móvil" campo="movil"></th-ordenado>
      <th class="th-acciones">Acciones</th>
    </thead>
    <tbody>
      <sin-registros v-if="!usuarios" :colspan="4"></sin-registros>
      <tr v-for="usuario in usuarios" v-bind:key="usuario.id" role="button">
        <td @click="editarUsuario(usuario.id)">{{ usuario.nombre }}</td>
        <td @click="editarUsuario(usuario.id)">{{ usuario.email }}</td>
        <td @click="editarUsuario(usuario.id)">{{ usuario.movil }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <b-button-group size="sm">
            <b-button variant="primary" class="actionButton" :to="`/usuarios/editar/${usuario.id}`"
              ><v-icon variant="primary" name="edit" width="16" /><span>Editar</span></b-button
            >
          </b-button-group>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    usuarios: Array,
  },
  methods: {
    editarUsuario(usuarioId) {
      this.$router.push("/usuarios/editar/" + usuarioId);
    },
  },
};
</script>
